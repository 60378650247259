<template>
  <div
    class="card-style black--text d-flex flex-column pl-5 pt-5 pr-5"
    :style="
      isUserAdmin && isDarkModeToggleEnabled
        ? getColors.lightPrimaryColor
        : getColors.darkBlackColor
    "
    v-if="visibility && totalAlarmQuotaPerMonth.isQuotaEnabled"
  >
    <v-alert
      dense
      outlined
      :color="'#ffd42a'"
      :type="'info'"
      width="100%"
      style="text-align: center"
    >
      <p style="margin: 0px; font-size: medium; font-weight: bold">
        {{ quotaTopic }}
      </p>
    </v-alert>
    <p
      :style="{
        fontSize: 'small',
        color:
          isUserAdmin && isDarkModeToggleEnabled
            ? getColors.lightPrimaryColor
            : getColors.darkBlackColor
      }"
      class="text-left"
    >
      {{ quotaBody }}
    </p>
    <div class="pb-2">
      <!-- Container for progress bar and icon -->
      <v-progress-linear
        height="10"
        color="yellow"
        :value="calculateProgress()"
        rounded
      ></v-progress-linear>
    </div>
    <p
      :style="{
        fontSize: 'small',
        fontWeight: 'bold',
        color:
          isUserAdmin && isDarkModeToggleEnabled
            ? getColors.lightPrimaryColor
            : getColors.darkBlackColor
      }"
      class="text-left"
    >
      {{ totalAlarmCountPerMonth }} /
      {{ totalAlarmQuotaPerMonth.alarmQuota }} incidents
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const namespaceStatistics = { namespace: 'statistics' }
const namespaceConfig = { namespace: 'config' }
const namespaceUser = { namespace: 'user' }
const namespaceClient = { namespace: 'client' }

@Component
export default class QuotaNotification extends Vue {
  @Action('fetchQuotaData', namespaceStatistics) private fetchQuotaData!: any
  @Action('getUserKeys', namespaceUser) public getUserKeys: (
    userId?: string
  ) => Promise<any[]>
  @Action('getClientDefaultKey', namespaceClient)
  public getClientDefaultKey: (payload: {
    clientId?: string
    userId?: string
  }) => Promise<any>
  @Action('setAllocatedQuota', namespaceStatistics) setAllocatedQuota!: any

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getUsedAlarmQuotaPerMonth', namespaceStatistics)
  public totalAlarmCountPerMonth: number
  @Getter('getTotalAlarmQuotaPerMonth', namespaceStatistics)
  public totalAlarmQuotaPerMonth: {
    isQuotaEnabled: boolean
    alarmQuota: number
    quotaAlertPercentage: number
  }
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser) public getColors!: any

  @Prop({ default: false, type: Boolean }) public changeQuota: boolean

  public visibility: boolean = false
  public isQuotaExceeded: boolean = false
  public userId: string = ''
  public clientId: string = ''
  public siteId: string = ''
  public cameraId: string = ''
  public subscriptionKeys: any[] = []
  public quotaTopic: string = ''
  public quotaBody: string = ''

  private async mounted() {
    await this.setAllocatedQuota(this.clientId)
    this.watchParams()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public closeAlert() {
    this.visibility = false
  }

  @Watch('changeQuota')
  private async watchChangeQuota() {
    await this.watchParams()
  }

  // Watch the route parameter client id is changed
  @Watch('$route.params.clientId')
  private async watchParams() {
    this.userId = this.$route.params.userId ?? ''
    this.clientId = this.$route.params.clientId ?? ''
    this.siteId = this.$route.params.siteId ?? ''
    this.cameraId = this.$route.params.cameraId ?? ''
    // Reset the subscription keys
    this.subscriptionKeys = []
    // Get the allocated quota from firestore database
    await this.setAllocatedQuota(this.clientId)
    // Assign false to the visibility to remove the alert when the client id is changed
    this.visibility = false
    this.isQuotaExceeded = false

    if (this.userId && this.clientId) {
      // Get the subscription keys for the client and push them to the subscriptionKeys array
      this.subscriptionKeys.push(
        await this.getClientDefaultKey({
          clientId: this.clientId,
          userId: this.userId
        })
      )

      // Fetch the used quota data from the elastic search
      await this.fetchQuotaData({
        startTime: new Date(),
        endTime: new Date(),
        userId: this.userId,
        clientId: this.clientId,
        siteId: this.siteId,
        cameraId: this.cameraId,
        subscriptionKeys: this.subscriptionKeys
      })

      // Calculate the progress of the quota
      const percentage = this.calculateProgress()
      if (
        percentage >= this.totalAlarmQuotaPerMonth.quotaAlertPercentage &&
        percentage < 100
      ) {
        // If the progress is greater than given percentage by admin, show the alert
        this.visibility = true
        this.quotaTopic = `The client has reached ${this.totalAlarmQuotaPerMonth.quotaAlertPercentage}% of the quota.`
        this.quotaBody =
          'A large number of incidents have been received from the client. Please note that the limit for this client will be reached soon.'
      } else if (percentage === 100) {
        // If the progress is equal to 100%, show the alert with quota limit reached
        this.visibility = true
        this.quotaTopic = 'The client has reached 100% of the quota'
        this.quotaBody =
          'A large number of incidents have been received from the client. Please note that the limit for this client is over.'
      } else if (percentage > 100) {
        // If the progress is greater than 100%, show the alert with quota exceeded
        this.visibility = true
        this.quotaTopic = 'The client has exceeded 100% of the quota'
        this.quotaBody =
          'A large number of incidents have been received from the client. Please note that the limit for this client has been exceeded.'
      } else {
        // If not, hide the alert
        this.visibility = false
      }
    }
  }

  // Calculate the progress of the quota
  public calculateProgress(): number {
    if (this.totalAlarmQuotaPerMonth.alarmQuota !== 0) {
      return (
        (this.totalAlarmCountPerMonth /
          this.totalAlarmQuotaPerMonth.alarmQuota) *
        100
      )
    } else {
      return 0 // if the quota is 0, the progress bar will be 0
    }
  }
}
</script>
