<template>
  <div class="camera">
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="amber"
    ></v-progress-circular>
    <div v-if="state.previewImageSrc">
      <img
        :src="state.previewImageSrc"
        v-bind:class="{
          hidden: isLoading,
          'camera-video': !isLoading
        }"
      />
    </div>
    <div v-show="!state.previewImageSrc">
      <video
        :id="videoElementId"
        controls
        autoplay
        playsinline
        muted
        v-bind:class="{
          hidden: isLoading,
          'camera-video': !isLoading
        }"
      ></video>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Watch, Prop, Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import LivePlayer from '@een/live-video-web-sdk'
import { reactive } from 'vue'

const namespaceCamera = { namespace: 'camera' }

@Component({
  components: {}
})
export default class EagleEyePlayer extends Vue {
  @Prop({ required: true }) public cameraId: string
  @Prop({ required: true }) public cameraConfig: any

  @Action('bindRtspStream', namespaceCamera) private bindRtspStream
  @Action('getEagleEyeConfig', namespaceCamera)
  private getEagleEyeConfig
  public state = reactive({ previewImageSrc: undefined })
  public isLoading = true
  public player: LivePlayer = undefined
  private previewUrl: any = null
  private mjpegAbortController: AbortController = null
  public eagleEyeConfig: {
    accessToken: string
    baseUrl: string
  } = null
  private previewInterval: any = null
  public livePlayerStarted: boolean = false

  get videoElementId() {
    return `eagle-eye-video-${this.cameraId}`
  }
  public mounted() {
    this.watchCameraConfig()
  }

  public beforeDestroy() {
    if (this.player) {
      this.stopPlayer()
      this.player = undefined
      this.stopPreviewPlayer()
    }
  }

  @Watch('cameraConfig')
  @Watch('cameraId')
  public async watchCameraConfig() {
    this.isLoading = true

    if (this.cameraConfig && this.cameraId) {
      await this.startPlayer()
    }
  }

  private stopPlayer(): void {
    if (this.player) {
      try {
        return this.player.stop()
      } catch (e) {
        this.player = undefined
        console.warn(e)
      }
    }
    this.stopPreviewPlayer()
  }

  private stopPreviewPlayer() {
    if (this.previewInterval) {
      clearTimeout(this.previewInterval)
      this.previewInterval = null
      this.state.previewImageSrc = undefined
      this.mjpegAbortController?.abort()
    }
  }
  private async startPlayer() {
    this.isLoading = true
    if (this.player || this.previewInterval) {
      this.stopPlayer()
    }
    this.eagleEyeConfig = await this.getEagleEyeConfig({
      cameraDocId: this.cameraId
    })
    if (this.eagleEyeConfig) {
      this.previewInterval = setTimeout(() => {
        this.pollPreview()
      })
      this.livePlayerStarted = false
      await this.initEagleEyeStream()
    }
  }
  public async pollPreview() {
    if (this.mjpegAbortController) {
      return
    }
    this.mjpegAbortController = new AbortController()
    const fetchMJPEGStream = async (
      url: string,
      onFrame: (url: string) => void
    ) => {
      const response = await fetch(url, {
        headers: {
          Authorization: 'bearer ' + this.eagleEyeConfig.accessToken
        },
        signal: this.mjpegAbortController.signal
      })
      const contentType = response.headers.get('Content-Type')
      const match = contentType.match(/boundary=(.*)$/)
      if (!match) {
        console.error('No boundary found in Content-Type')
        return
      }

      const boundary = '--' + match[1]
      let buffer = new Uint8Array()

      const reader = response.body.getReader()

      function indexOfSequence(buffer: Uint8Array, sequence: Uint8Array) {
        const maxStart = buffer.length - sequence.length
        outer: for (let i = 0; i <= maxStart; i++) {
          for (let j = 0; j < sequence.length; j++) {
            if (buffer[i + j] !== sequence[j]) {
              continue outer
            }
          }
          return i
        }
        return -1
      }

      const processStream = async () => {
        let readBytes: Uint8Array = null
        try {
          const { done, value } = await reader.read()
          readBytes = value
          if (done) {
            this.mjpegAbortController = null
            return
          }
        } catch (e) {
          this.mjpegAbortController = null
          return
        }

        // Concatenate the new data with the previous buffer
        buffer = new Uint8Array([...buffer, ...readBytes])

        let boundaryIndex: number
        const boundaryBytes = new TextEncoder().encode(boundary)

        // Search for the boundary in the buffer
        while (
          (boundaryIndex = indexOfSequence(buffer, boundaryBytes)) !== -1
        ) {
          const frameData = buffer.slice(0, boundaryIndex)

          // Remove headers and get the JPEG data
          const doubleNewline = new TextEncoder().encode('\r\n\r\n')
          const headerEnd = indexOfSequence(frameData, doubleNewline)
          if (headerEnd !== -1) {
            const jpegData = frameData.slice(headerEnd + 4)
            const blob = new Blob([jpegData], { type: 'image/jpeg' })
            const imageUrl = URL.createObjectURL(blob)

            onFrame(imageUrl)
          }

          // Remove the processed frame and boundary from the buffer
          buffer = buffer.slice(boundaryIndex + boundaryBytes.length)
        }

        processStream()
      }

      processStream()
    }
    try {
      await fetchMJPEGStream(
        `${this.eagleEyeConfig.baseUrl.replace(
          'api',
          'media'
        )}/media/streams/preview/multipart?esn=${this.cameraConfig.cameraId}`,
        (previewUrl) => {
          URL.revokeObjectURL(this.previewUrl)
          this.previewUrl = previewUrl
          this.state.previewImageSrc = this.previewUrl
          this.isLoading = false
        }
      )
    } catch (e) {
      this.mjpegAbortController = null
    } finally {
      if (this.previewInterval) {
        this.previewInterval = setTimeout(() => {
          this.pollPreview()
        }, 5_000)
      }
    }
  }

  public async initEagleEyeStream() {
    const isChromium = !!(window as any).chrome
    const videoTech: any = isChromium ? 'WebCodecs' : 'FLV'
    const config = {
      videoElement: document.getElementById(
        this.videoElementId
      ) as HTMLVideoElement,
      cameraId: this.cameraConfig.cameraId,
      baseUrl: this.eagleEyeConfig.baseUrl,
      jwt: this.eagleEyeConfig.accessToken,
      videoTech,
      onFrame: (frame: number) => {
        if (this.isLoading) {
          this.isLoading = false
        }
        // Stop preview images to since live player has started playing
        this.livePlayerStarted = true
        this.stopPreviewPlayer()
      }
    }

    this.player = new LivePlayer()
    this.player.start(config)
  }
}
</script>

<style scoped>
.camera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.camera-video {
  width: 100%;
  height: 100%;
}
.hidden {
  display: none;
}
</style>
