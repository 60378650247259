import { getDefaultState } from './state'

const mutations = {
  setGlobalTimeRange(state: any, payload: any) {
    state.globalTimeRange = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  },
  setIsAlarmRefreshEnabled(state: any, payload: boolean) {
    state.isAlarmRefreshEnabled = payload
  },
  setAutoRefreshSettings(state: any, payload: any) {
    if (payload) {
      state.autoRefreshSettings = payload
    }
  },
  setBreadcrumbList(state: any, payload: any) {
    if (payload) {
      state.breadcrumbList = payload
    }
  },
  setClientType(state: any, payload: any): void {
    state.clientType = payload
  },
  setServiceStatus(
    state: any,
    payload: {
      firestore: boolean
      storage: boolean
      auth: boolean
      functions: boolean
    }
  ) {
    state.serviceStatus = {
      functions: payload.functions
    }
  },
  setSelectedTreeNode(state: any, payload: any) {
    if (payload) {
      state.selectedTreeNode = payload
    }
  },
  setIsTreeViewLoading(state: any, payload: boolean) {
    state.isTreeViewLoading = payload
  },
  setShowPendingIncidents(state: any, showPendingIncidents: boolean) {
    state.showPendingIncidents = showPendingIncidents
  },
  setTreeClients(state: any, payload: { clients: any }) {
    state.treeClients = payload.clients
  },
  setTreeSites(state: { treeSites: any }, payload: any) {
    state.treeSites = payload.sites
  },

  setTreeCameras(state: { treeCameras: any }, payload: any) {
    state.treeCameras = payload.cameras
  },

  setTreeCustomers(state: { treeCustomers: any }, payload: any) {
    state.treeCustomers = payload.customers
  },

  setIsDarkModeEnabled(state: any, payload: boolean) {
    state.isDarkModeToggleEnabled = payload
  },
  setIsNotFound(state: any, payload: boolean) {
    state.isNotFound = payload
  },
  setConfig(state: any, payload: any) {
    state.config = payload
  },
  setIsToggleMenuEnabled(state: any, payload: boolean) {
    state.isToggleMenuEnabled = payload
  },
  setVersionInfo(state: any, version: string | null) {
    state.webAppVersion = version
  },
  setPromiseQubeVersion(state: any, version: string | null) {
    state.promiseQubeVersion = version
  }
}

export default {
  ...mutations
}
