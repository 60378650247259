var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"ma-0",attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-on-click":false,"open-on-focus":false,"nudge-right":"12","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","data-test-id":"additional-info-modal-button"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary","dark":""}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',{staticClass:"mx-2 white--text"},[_vm._v("More information")])]),_c('v-dialog',{attrs:{"content-class":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? 'additional-info--modal-dark'
        : 'additional-info--modal-light',"scrollable":"","max-width":"650px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-container',[_c('v-row',{staticClass:"d-flex flex-column",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex flex-row justify-start align-center pa-2 mt-2"},[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" mdi-information-outline ")]),_c('p',{staticClass:"ml-2 my-0 text-h6 font-weight-black",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Incident information ")])],1),_c('Information',{attrs:{"displayName":"Incident Id","item":_vm.incident.id,"allowCopy":true}}),_c('Information',{attrs:{"displayName":"Endpoint","item":_vm.incident.alarmtype}}),_c('Information',{attrs:{"displayName":"Incident definition","item":_vm.incident.alarmdefinition}}),(_vm.incident.inputtype && _vm.incident.inputtype.raw)?_c('Information',{attrs:{"displayName":"Input Type","item":_vm.incident.inputtype}}):_vm._e(),_c('Information',{attrs:{"displayName":"Total time","item":_vm.calculateDuration(_vm.incident.createdat, _vm.incident.completedat)}})],1),_c('v-row',{staticClass:"d-flex flex-column",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex flex-row justify-start align-center pa-2 mt-2"},[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" mdi-brain ")]),_c('p',{staticClass:"ml-2 my-0 text-h6 font-weight-black",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" AI ")])],1),_c('Information',{attrs:{"displayName":"Person assessment","item":_vm.incident.airesult__personassessment}}),_c('Information',{attrs:{"displayName":"Vehicle movement","item":_vm.incident.airesult__vehiclemovement}}),_c('Information',{attrs:{"displayName":"Response time","item":_vm.calculateDuration(
              _vm.incident?.airesult__createdat ?? _vm.incident.createdat,
              _vm.incident.airesult__updatedat
            )}})],1),(_vm.incident.worker__workerid)?_c('v-row',{staticClass:"d-flex flex-column",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex flex-row justify-start align-center pa-2 mt-2"},[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" mdi-account-hard-hat-outline ")]),(_vm.incident.missed)?_c('div',[(_vm.incident.missed.raw === 'true')?_c('span',{staticClass:"ml-2 my-0 text-h6 font-weight-black",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkPrimaryColor
              })},[_vm._v("Missed")]):_vm._e()]):_vm._e(),_c('p',{staticClass:"ml-2 my-0 text-h6 font-weight-black",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Worker ")])],1),_c('Information',{attrs:{"displayName":"Person assessment","item":_vm.incident.worker__personassessment}}),_c('Information',{attrs:{"displayName":"Vehicle Movement","item":_vm.incident.worker__vehiclemovement}}),_c('Information',{attrs:{"displayName":"Worker Id","item":_vm.incident.worker__workerid,"allowCopy":true}}),_c('Information',{attrs:{"displayName":"Worker name","item":_vm.incident.worker__workerid,"allowCopy":true,"isUserDetailsRequired":true}}),_c('Information',{attrs:{"displayName":"Response time","item":_vm.calculateDuration(
              _vm.incident.airesult__updatedat,
              _vm.incident.worker__updatedat
            )}}),(_vm.incident.missed && _vm.incident.missed.raw === 'true')?_c('Information',{attrs:{"displayName":"Missed ","item":_vm.incident.missed}}):_vm._e(),(_vm.incident.worker__isdisplayed)?_c('Information',{attrs:{"displayName":"Displayed ","item":{
            raw:
              _vm.incident.worker__isdisplayed &&
              _vm.incident.worker__isdisplayed.raw === 'true'
                ? true
                : false
          }}}):_vm._e(),(_vm.incident.worker__assignedat)?_c('Information',{attrs:{"displayName":"Assigned At","item":_vm.formatDateTime(_vm.incident.worker__assignedat)}}):_vm._e(),(_vm.incident.worker__displayedat)?_c('Information',{attrs:{"displayName":"Displayed At","item":_vm.formatDateTime(_vm.incident.worker__displayedat)}}):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"d-flex flex-column",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex flex-row justify-start align-center pa-2 mt-2"},[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" mdi-account-outline ")]),_c('p',{staticClass:"ml-2 my-0 text-h6 font-weight-black",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Client ")])],1),_c('Information',{attrs:{"displayName":"Subscription key","item":_vm.incident.subscriptionkey,"allowCopy":true}}),_c('Information',{attrs:{"displayName":"Camera Id","item":_vm.incident.cameraid,"allowCopy":true}}),_c('Information',{attrs:{"displayName":"Id","item":_vm.incident.user__id,"allowCopy":true}}),_c('Information',{attrs:{"displayName":"User","item":_vm.incident.user__id,"isUserDetailsRequired":true}})],1),_c('v-row',{staticClass:"d-flex flex-row justify-end align-center mt-8",attrs:{"no-gutters":""}},[_c('Button',{attrs:{"btnText":"Close"},on:{"onButtonClick":function($event){_vm.dialog = false}}})],1),_c('v-row',[_c('div',{staticClass:"mb-4"})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }