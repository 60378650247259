import axios from 'axios'

interface Address {
  streetAddress: string
  city: string
  country: string
  zipcode: string
}

async function convertAddressToLocation(address: Address) {
  try {
    const apiKey = process.env.VUE_APP_GEOCODING_API_KEY
    const encodedAddress = encodeURIComponent(
      address.streetAddress +
        ', ' +
        address.city +
        ', ' +
        address.country +
        ', ' +
        address.zipcode
    )
    const url = `https://api.tomtom.com/search/2/geocode/${encodedAddress}.json?storeResult=false&limit=1&view=Unified&key=${apiKey}`
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export { convertAddressToLocation }
