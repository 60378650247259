var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"camera"},[(!_vm.isErrorFound && _vm.isAuthenticated === true)?_c('div',{staticStyle:{"width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center"}},[_c('video',{style:({
        width: '100%',
        height: '100%',
        display: _vm.isLoading ? 'none' : 'block'
      }),attrs:{"id":_vm.videoElementId,"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}}),_c('div',[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}}):_vm._e(),(_vm.isLoading)?_c('div',{style:({
          color: _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'white' : 'black'
        })},[_c('div',[_vm._v("Loading...")]),_c('div',[_vm._v(_vm._s(_vm.statusMessage))])]):_vm._e()],1)]):_vm._e(),(_vm.isErrorFound)?_c('div',{style:({
      color: _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'white' : 'black'
    })},[_c('v-icon',{style:({
        color: _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'white' : 'black'
      })},[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.statusMessage)+" ")],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }