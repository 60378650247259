import { DocumentReference } from '@firebase/firestore-types'
import { HttpsCallableResult } from '@firebase/functions-types'
import {
  cameraCollection,
  clientCollection,
  siteCollection,
  siteProvider,
  userCollection
} from '@/provider/firebase'
import { firestoreAction } from 'vuexfire'
import { store } from '@/store'
import { SiteModifiedConfiguration } from 'configuration'
import { DISARMED_TIME_RANGE } from '@/utils/Constants'

const actions = {
  bindSiteUsers: async (
    { commit, state }: any,
    payload: { userId: string; userIds: string[] }
  ) => {
    commit('user/setIsLoadingUsers', true, { root: true })
    const readUsers = state.siteConfig.readUsers ?? []
    const writeUsers = state.siteConfig.writeUsers ?? []
    const overrideUsers = state.siteConfig.overrideUsers ?? []
    const filteredUserIds = payload.userIds.filter(
      (userId) => userId !== payload.userId && !readUsers.includes(userId)
    )
    const users = await Promise.all(
      filteredUserIds?.map(async (userId) => {
        const userSnap = await userCollection.doc(userId).get()
        return {
          ...userSnap.data(),
          isWriteEnabled: writeUsers.includes(userId),
          isOverrideEnabled: overrideUsers.includes(userId)
        }
      })
    )
    commit('setSiteUsers', users)
    commit('user/setIsLoadingUsers', false, { root: true })
  },
  bindSite: firestoreAction(
    async (
      { state, bindFirestoreRef, commit }: any,
      payload: {
        clientId: string
        siteId: string
        userId: string
      }
    ) => {
      store.commit('config/setIsNotFound', false)
      const clientSnap = await clientCollection
        .where('clientId', '==', payload.clientId)
        .get()

      if (clientSnap.empty) {
        store.commit('config/setIsNotFound', true)
        return
      }

      const siteSnap = await siteCollection
        .where('siteId', '==', payload.siteId)
        .where('client', '==', clientSnap.docs.at(0).ref)
        .get()

      if (siteSnap.empty) {
        console.log("Something went wrong or site doesn't exists")
        store.commit('config/setIsNotFound', true)
        return
      }

      const healthCheckNotificationDataRef = siteSnap.docs
        .at(0)
        .ref.collection('notificationData')
        .doc('healthCheck')
      const healthCheckNotificationDataSnap =
        await healthCheckNotificationDataRef.get()

      let healthCheckNotificationData = {}
      if (healthCheckNotificationDataSnap.exists) {
        healthCheckNotificationData = healthCheckNotificationDataSnap.data()
      }

      return bindFirestoreRef('siteConfig', siteSnap.docs.at(0).ref, {
        maxRefDepth: 0,
        wait: true,
        serialize: (doc: { data: () => any; id: any }) => {
          const users = doc.data()?.users ?? []
          const writeUsers = doc.data()?.writeUsers ?? []
          const readUsers = doc.data()?.readUsers ?? []
          const isDefault =
            clientSnap.docs.at(0).data()?.type === 'Default' ||
            doc.data().siteId === 'default-site'
          return {
            ...doc.data(),
            id: doc.id,
            isDefault: isDefault,
            isWriteEnabled: writeUsers.includes(payload.userId),
            isAccessEnabled:
              !readUsers.includes(payload.userId) &&
              users.includes(payload.userId),
            notificationData: {
              healthCheck: healthCheckNotificationData
            }
          }
        }
      })
    }
  ),
  bindHcNotificationData: firestoreAction(
    async (
      { state, bindFirestoreRef, commit }: any,
      payload: {
        siteId: string
      }
    ) => {
      const ref = siteCollection
        .doc(payload.siteId)
        .collection('notificationData')
        .doc('healthCheck')
      return bindFirestoreRef(`siteConfig.notificationData.healthCheck`, ref, {
        wait: true
      })
    }
  ),
  async updateSiteConfig(
    { commit, dispatch, state }: any,
    payload: {
      docId: string
      updatedSiteData: SiteModifiedConfiguration
      updatedFields: string[]
    }
  ) {
    if (payload?.updatedFields.includes('Site Details')) {
      if (payload.updatedSiteData.siteInformation) {
        const siteInformationPayload = {
          name: payload.updatedSiteData.siteInformation.siteName,
          address: payload.updatedSiteData.siteInformation.siteAddress,
          accountNumber:
            payload.updatedSiteData.siteInformation.siaDC09AccountNumber,
          docId: payload.docId
        }
        await siteProvider.updateSiteConfig(siteInformationPayload)
      }
    }
    if (payload?.updatedFields.includes('Alarm Definition')) {
      await dispatch('updateAlarmDefinitionConfig', {
        siteId: payload.docId,
        alarmDefinitionConfig:
          payload.updatedSiteData.alarmSettings.alarmDefinition
      })
    }
    if (payload?.updatedFields.includes('Contact')) {
      const contact = {
        contactName: payload.updatedSiteData.contact.contactPerson,
        countryCallingCode:
          payload.updatedSiteData.contact.contactNumber.countryCallingCode,
        countryCode: payload.updatedSiteData.contact.contactNumber.countryCode,
        isCallEnabled: payload.updatedSiteData.contact.receiveCallNotification,
        isSmsEnabled: payload.updatedSiteData.contact.receiveSmsNotification,
        message: payload.updatedSiteData.contact.contactMessage,
        phoneNumber: payload.updatedSiteData.contact.contactNumber.phoneNumber
      }

      await dispatch('updateContact', {
        siteId: payload.docId,
        contact
      })
    }
    if (payload?.updatedFields.includes('Recordings')) {
      if (
        payload?.updatedSiteData?.edgeDevice?.isEdgeDeviceRecordingEnabled !==
        undefined
      ) {
        await dispatch('updateEdgeDeviceRecordingStatus', {
          siteId: payload.docId,
          isEdgeDeviceRecordingEnabled:
            payload.updatedSiteData.edgeDevice.isEdgeDeviceRecordingEnabled
        })
      }
    }
    if (payload?.updatedFields.includes('Update scheduling')) {
      await dispatch('updateScheduleUpdate', {
        siteId: payload.docId,
        updateSchedulingConfig: {
          selectedTimeZone:
            payload.updatedSiteData.updateSchedule.selectedTimeZone,
          applicableDays: payload.updatedSiteData.updateSchedule.applicableDays,
          updateFrequency:
            payload.updatedSiteData.updateSchedule.updateFrequency,
          updateNumber: payload.updatedSiteData.updateSchedule.updateNumber,
          scheduledTime: payload.updatedSiteData.updateSchedule.scheduledTime,
          isAutoUpdate: payload.updatedSiteData.updateSchedule.isAutoUpdate,
          isScheduledUpdate:
            payload.updatedSiteData.updateSchedule.isScheduledUpdate
        }
      })
    }

    if (payload?.updatedFields.includes('Sia Integration')) {
      try {
        if (
          payload.updatedSiteData.siaIntegration.siaAddedReceivers.length > 0
        ) {
          for (const receiverData of payload.updatedSiteData.siaIntegration
            .siaAddedReceivers) {
            await dispatch('updateSiaIntegration', {
              siteId: payload.docId,
              data: receiverData
            })
          }
        }

        if (
          payload.updatedSiteData.siaIntegration.siaDeletedReceivers.length > 0
        ) {
          for (const receiverData of payload.updatedSiteData.siaIntegration
            .siaDeletedReceivers) {
            dispatch('deleteSiaIntegration', {
              siteId: payload.docId,
              data: receiverData
            })
          }
        }
      } catch (e: any) {
        console.error('Error in Sia Receiver: ', e.message)
      }
    }
    if (payload?.updatedFields.includes('FTP Integration')) {
      await dispatch('updateFtpEnabledStatus', {
        siteId: payload.docId,
        isFtpEnabled: payload.updatedSiteData.isFtpEnabled
      })
    }
    if (payload?.updatedFields.includes('Human Review')) {
      await dispatch('updateHumanReviewStatus', {
        siteId: payload.docId,
        isHumanReviewEnabled: payload.updatedSiteData.alarmSettings.humanReview
      })
    }
    if (payload?.updatedFields.includes('Health Check')) {
      if (payload.updatedSiteData.healthCheck.notifications != null) {
        await dispatch('updateIsHealthCheckNotificationEnabledStatus', {
          siteId: payload.docId,
          isHealthCheckNotificationEnabled:
            payload.updatedSiteData.healthCheck.notifications
        })
      }
      if (payload.updatedSiteData.healthCheck?.latestHCNotifications != null) {
        await dispatch('updateIsLatestHealthCheckNotificationEnabledStatus', {
          siteId: payload.docId,
          isLatestHealthCheckNotificationEnabled:
            payload.updatedSiteData.healthCheck.latestHCNotifications
        })
      }
      // Todo: fix this
      if (payload.updatedSiteData.healthCheck.frequency) {
        await dispatch('updateHcDuration', {
          siteId: payload.docId,
          healthCheckDuration: payload.updatedSiteData.healthCheck.frequency
        })
      }
      if (payload.updatedSiteData.healthCheck.type) {
        await dispatch('updateHcType', {
          siteId: payload.docId,
          healthCheckType: payload.updatedSiteData.healthCheck.type
        })
      }
    }

    if (
      payload?.updatedFields.includes('Email Notifications') ||
      payload?.updatedFields.includes('Email Notifications Toggle')
    ) {
      if (
        typeof payload.updatedSiteData.notifications.alarm.email.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsEmailNotificationEnabledStatus', {
          siteId: payload.docId,
          isEmailNotificationEnabled:
            payload.updatedSiteData.notifications.alarm.email.isOn
        })
      }
      if (payload.updatedSiteData.notifications.alarm.email.receivingEmails) {
        await dispatch('updateNotificationEmails', {
          siteId: payload.docId,
          notificationEmails:
            payload.updatedSiteData.notifications.alarm.email.receivingEmails
        })
      }
    }
    if (
      payload?.updatedFields.includes('Sia Notifications') ||
      payload?.updatedFields.includes('Sia Notifications Toggle')
    ) {
      if (
        typeof payload.updatedSiteData.notifications.alarm.siaDc09.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsSiaNotificationEnabledStatus', {
          siteId: payload.docId,
          isSiaEnabled: payload.updatedSiteData.notifications.alarm.siaDc09.isOn
        })
      }
      if (
        payload.updatedSiteData.notifications.alarm.siaDc09
          .notifySubFolderAccountNumber != null
      ) {
        await dispatch('updateIsRelatedSiaAccountNotifiedStatus', {
          siteId: payload.docId,
          isRelatedSiaAccountNotified:
            payload.updatedSiteData.notifications.alarm.siaDc09
              .notifySubFolderAccountNumber
        })
      }
      if (
        payload.updatedSiteData.notifications.alarm.siaDc09
          .sendSiaHeartbeatMessage != null
      ) {
        await dispatch('updateIsSiaHeartbeatEnabledStatus', {
          siteId: payload.docId,
          isSiaHeartbeatEnabled:
            payload.updatedSiteData.notifications.alarm.siaDc09
              .sendSiaHeartbeatMessage
        })
      }
      if (
        payload.updatedSiteData.notifications.alarm.siaDc09
          .sendSiaRoutineMessage != null
      ) {
        await dispatch('updateIsSiaRoutineMessageEnabledStatus', {
          siteId: payload.docId,
          isSiaRoutineMessageEnabled:
            payload.updatedSiteData.notifications.alarm.siaDc09
              .sendSiaRoutineMessage
        })
      }
      const siaData = {
        siaIp: payload.updatedSiteData.notifications.alarm.siaDc09.ipAddress,
        siaPort: payload.updatedSiteData.notifications.alarm.siaDc09.port
      }
      await dispatch('updateSiaConfig', {
        siteId: payload.docId,
        siaData
      })
    }
    if (payload?.updatedFields.includes('Webhook Notifications')) {
      const webhookTriggerData = {
        method:
          payload.updatedSiteData.notifications.alarm.webhook.webhookArray
            .method,
        name: payload.updatedSiteData.notifications.alarm.webhook.webhookArray
          .name,
        url: payload.updatedSiteData.notifications.alarm.webhook.webhookArray
          .url
      }
      if (
        payload.updatedSiteData.notifications.alarm.webhook.webhookArray
          .urlParams
      ) {
        webhookTriggerData['urlParams'] =
          payload.updatedSiteData.notifications.alarm.webhook.webhookArray.urlParams
      }

      if (
        payload.updatedSiteData.notifications.alarm.webhook.webhookArray
          .headerParams
      ) {
        webhookTriggerData['headerParams'] =
          payload.updatedSiteData.notifications.alarm.webhook.webhookArray.headerParams
      }

      // Add content to webhookTriggerData if it exists
      if (
        payload.updatedSiteData.notifications.alarm.webhook.webhookArray.content
      ) {
        webhookTriggerData['content'] =
          payload.updatedSiteData.notifications.alarm.webhook.webhookArray.content
      }
      await dispatch('addWebhookTriggerConfig', {
        siteId: payload.docId,
        webhookTriggerData
      })

      const docRef = siteCollection.doc(payload.docId)
      const doc = await docRef.get()

      if (doc.exists) {
        const data = doc.data()

        // Check if webhookTriggerData and urlParams exist
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.urlParams &&
          webhookTriggerData['urlParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            siteId: payload.docId,
            webhookTriggerData: { urlParams: webhookTriggerData['urlParams'] }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            siteId: payload.docId,
            webhookTriggerData: { urlParams: {} }
          })
        }
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.headerParams &&
          webhookTriggerData['headerParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            siteId: payload.docId,
            webhookTriggerData: {
              headerParams: webhookTriggerData['headerParams']
            }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            siteId: payload.docId,
            webhookTriggerData: { headerParams: {} }
          })
        }
      }

      if (
        typeof payload.updatedSiteData.notifications.alarm.webhook.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsWebhookTriggerEnabledStatus', {
          siteId: payload.docId,
          isWebhookTriggerEnabled:
            payload.updatedSiteData.notifications.alarm.webhook.isOn
        })
      }
    }
    if (payload?.updatedFields.includes('FTP Notifications')) {
      if (
        typeof payload.updatedSiteData.notifications.alarm.ftp.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsFTPNotificationEnabledStatus', {
          siteId: payload.docId,
          isFTPEnabled: payload.updatedSiteData.notifications.alarm.ftp.isOn
        })
      }
      const ftpData = {
        filePath: payload.updatedSiteData.notifications.alarm.ftp.filePath,
        host: payload.updatedSiteData.notifications.alarm.ftp.host,
        password: payload.updatedSiteData.notifications.alarm.ftp.password,
        port: payload.updatedSiteData.notifications.alarm.ftp.port,
        user: payload.updatedSiteData.notifications.alarm.ftp.username,
        isUploadImageSequence:
          payload.updatedSiteData.notifications.alarm.ftp.isUploadImageSequence,
        isFtpPathEnabled:
          payload.updatedSiteData.notifications.alarm.ftp.isFtpPathEnabled
      }
      await dispatch('updateFTPConfig', {
        siteId: payload.docId,
        ftpData
      })
    }
    if (payload?.updatedFields.includes('SMTP Notifications')) {
      if (
        typeof payload.updatedSiteData.notifications.alarm.smtp.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsSMTPNotificationEnabledStatus', {
          siteId: payload.docId,
          isSMTPNotificationEnabled:
            payload.updatedSiteData.notifications.alarm.smtp.isOn
        })
      }
      const smtpNotificationData = {
        receiverAddress:
          payload.updatedSiteData.notifications.alarm.smtp.receiverEmailAddress,
        host: payload.updatedSiteData.notifications.alarm.smtp.host,
        password: payload.updatedSiteData.notifications.alarm.smtp.password,
        port: payload.updatedSiteData.notifications.alarm.smtp.port,
        user: payload.updatedSiteData.notifications.alarm.smtp.username
      }
      await dispatch('updateSMTPConfig', {
        siteId: payload.docId,
        smtpNotificationData
      })
    }
    if (
      payload?.updatedFields.includes('Evalink Notifications') ||
      payload?.updatedFields.includes('Evalink Notifications Toggle')
    ) {
      if (
        typeof payload.updatedSiteData.notifications.alarm.evalink.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsEvalinkNotificationEnabledStatus', {
          siteId: payload.docId,
          isEvalinkEnabled:
            payload.updatedSiteData.notifications.alarm.evalink.isOn
        })
      }
      const evalinkData = {
        alarmZoneName:
          payload.updatedSiteData.notifications.alarm.evalink.alarmZoneName,
        Authorization:
          payload.updatedSiteData.notifications.alarm.evalink.authorizationKey,
        companyId:
          payload.updatedSiteData.notifications.alarm.evalink.companyId,
        deviceId: payload.updatedSiteData.notifications.alarm.evalink.deviceId,
        isDeviceOverrideEnabled:
          payload.updatedSiteData.notifications.alarm.evalink.deviceOverride ||
          false,
        partition:
          payload.updatedSiteData.notifications.alarm.evalink.partition,
        partitionName:
          payload.updatedSiteData.notifications.alarm.evalink.partitionName
      }
      await dispatch('updateEvalinkConfig', {
        siteId: payload.docId,
        evalinkData
      })
    }

    if (
      payload?.updatedFields.includes('Email Notifications (HC)') ||
      payload?.updatedFields.includes('Email Notifications Toggle (HC)')
    ) {
      if (
        typeof payload.updatedSiteData.notifications.healthCheck.email.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsEmailNotificationEnabledStatus', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          isEmailNotificationEnabled:
            payload.updatedSiteData.notifications.healthCheck.email.isOn
        })
      }
      if (
        payload.updatedSiteData.notifications.healthCheck.email.receivingEmails
      ) {
        await dispatch('updateNotificationEmails', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          notificationEmails:
            payload.updatedSiteData.notifications.healthCheck.email
              .receivingEmails
        })
      }
    }
    if (
      payload?.updatedFields.includes('Sia Notifications (HC)') ||
      payload?.updatedFields.includes('Sia Notifications Toggle (HC)')
    ) {
      if (
        typeof payload.updatedSiteData.notifications.healthCheck.siaDc09
          .isOn === 'boolean'
      ) {
        await dispatch('updateIsSiaNotificationEnabledStatus', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          isSiaEnabled:
            payload.updatedSiteData.notifications.healthCheck.siaDc09.isOn
        })
      }
      if (
        payload.updatedSiteData.notifications.healthCheck.siaDc09
          .notifySubFolderAccountNumber != null
      ) {
        await dispatch('updateIsRelatedSiaAccountNotifiedStatus', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          isRelatedSiaAccountNotified:
            payload.updatedSiteData.notifications.healthCheck.siaDc09
              .notifySubFolderAccountNumber
        })
      }
      if (
        payload.updatedSiteData.notifications.healthCheck.siaDc09
          .sendSiaHeartbeatMessage != null
      ) {
        await dispatch('updateIsSiaHeartbeatEnabledStatus', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          isSiaHeartbeatEnabled:
            payload.updatedSiteData.notifications.healthCheck.siaDc09
              .sendSiaHeartbeatMessage
        })
      }
      if (
        payload.updatedSiteData.notifications.healthCheck.siaDc09
          .sendSiaRoutineMessage != null
      ) {
        await dispatch('updateIsSiaRoutineMessageEnabledStatus', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          isSiaRoutineMessageEnabled:
            payload.updatedSiteData.notifications.healthCheck.siaDc09
              .sendSiaRoutineMessage
        })
      }
      const siaData = {
        siaIp:
          payload.updatedSiteData.notifications.healthCheck.siaDc09.ipAddress,
        siaPort: payload.updatedSiteData.notifications.healthCheck.siaDc09.port
      }
      await dispatch('updateSiaConfig', {
        siteId: `${payload.docId}/notificationData/healthCheck`,
        siaData
      })
    }
    if (payload?.updatedFields.includes('Webhook Notifications (HC)')) {
      const webhookTriggerData = {
        method:
          payload.updatedSiteData.notifications.healthCheck.webhook.webhookArray
            .method,
        name: payload.updatedSiteData.notifications.healthCheck.webhook
          .webhookArray.name,
        url: payload.updatedSiteData.notifications.healthCheck.webhook
          .webhookArray.url
      }
      if (
        payload.updatedSiteData.notifications.healthCheck.webhook.webhookArray
          .urlParams
      ) {
        webhookTriggerData['urlParams'] =
          payload.updatedSiteData.notifications.healthCheck.webhook.webhookArray.urlParams
      }

      if (
        payload.updatedSiteData.notifications.healthCheck.webhook.webhookArray
          .headerParams
      ) {
        webhookTriggerData['headerParams'] =
          payload.updatedSiteData.notifications.healthCheck.webhook.webhookArray.headerParams
      }

      // Add content to webhookTriggerData if it exists
      if (
        payload.updatedSiteData.notifications.healthCheck.webhook.webhookArray
          .content
      ) {
        webhookTriggerData['content'] =
          payload.updatedSiteData.notifications.healthCheck.webhook.webhookArray.content
      }
      await dispatch('addWebhookTriggerConfig', {
        siteId: `${payload.docId}/notificationData/healthCheck`,
        webhookTriggerData
      })

      const docRef = siteCollection.doc(
        `${payload.docId}/notificationData/healthCheck`
      )
      const doc = await docRef.get()

      if (doc.exists) {
        const data = doc.data()

        // Check if webhookTriggerData and urlParams exist
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.urlParams &&
          webhookTriggerData['urlParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            siteId: `${payload.docId}/notificationData/healthCheck`,
            webhookTriggerData: { urlParams: webhookTriggerData['urlParams'] }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            siteId: `${payload.docId}/notificationData/healthCheck`,
            webhookTriggerData: { urlParams: {} }
          })
        }
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.headerParams &&
          webhookTriggerData['headerParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            siteId: `${payload.docId}/notificationData/healthCheck`,
            webhookTriggerData: {
              headerParams: webhookTriggerData['headerParams']
            }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            siteId: `${payload.docId}/notificationData/healthCheck`,
            webhookTriggerData: { headerParams: {} }
          })
        }
      }

      if (
        typeof payload.updatedSiteData.notifications.healthCheck.webhook
          .isOn === 'boolean'
      ) {
        await dispatch('updateIsWebhookTriggerEnabledStatus', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          isWebhookTriggerEnabled:
            payload.updatedSiteData.notifications.healthCheck.webhook.isOn
        })
      }
    }
    if (payload?.updatedFields.includes('SMTP Notifications (HC)')) {
      if (
        typeof payload.updatedSiteData.notifications.healthCheck.smtp.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsSMTPNotificationEnabledStatus', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          isSMTPNotificationEnabled:
            payload.updatedSiteData.notifications.healthCheck.smtp.isOn
        })
      }
      const smtpNotificationData = {
        receiverAddress:
          payload.updatedSiteData.notifications.healthCheck.smtp
            .receiverEmailAddress,
        host: payload.updatedSiteData.notifications.healthCheck.smtp.host,
        password:
          payload.updatedSiteData.notifications.healthCheck.smtp.password,
        port: payload.updatedSiteData.notifications.healthCheck.smtp.port,
        user: payload.updatedSiteData.notifications.healthCheck.smtp.username
      }
      await dispatch('updateSMTPConfig', {
        siteId: `${payload.docId}/notificationData/healthCheck`,
        smtpNotificationData
      })
    }
    if (
      payload?.updatedFields.includes('Evalink Notifications (HC)') ||
      payload?.updatedFields.includes('Evalink Notifications Toggle (HC)')
    ) {
      if (
        payload.updatedSiteData.notifications.healthCheck.evalink.isOn !==
        undefined
      ) {
        await dispatch('updateIsEvalinkNotificationEnabledStatus', {
          siteId: `${payload.docId}/notificationData/healthCheck`,
          isEvalinkEnabled:
            payload.updatedSiteData.notifications.healthCheck.evalink.isOn
        })
      }
      const evalinkData = {
        alarmZoneName:
          payload.updatedSiteData.notifications.healthCheck.evalink
            .alarmZoneName,
        Authorization:
          payload.updatedSiteData.notifications.healthCheck.evalink
            .authorizationKey,
        companyId:
          payload.updatedSiteData.notifications.healthCheck.evalink.companyId,
        deviceId:
          payload.updatedSiteData.notifications.healthCheck.evalink.deviceId,
        isDeviceOverrideEnabled:
          payload.updatedSiteData.notifications.healthCheck.evalink
            .deviceOverride || false,
        partition:
          payload.updatedSiteData.notifications.healthCheck.evalink.partition,
        partitionName:
          payload.updatedSiteData.notifications.healthCheck.evalink
            .partitionName
      }
      await dispatch('updateEvalinkConfig', {
        siteId: `${payload.docId}/notificationData/healthCheck`,
        evalinkData
      })
    }

    if (payload?.updatedFields.includes(DISARMED_TIME_RANGE)) {
      const unarmedTimeRange = {
        applicableDays:
          payload.updatedSiteData.unarmedTimeRange.applicationDays,
        endTime: payload.updatedSiteData.unarmedTimeRange.endTime,
        isAlwaysUnarmed:
          payload.updatedSiteData.unarmedTimeRange.alwaysUnarmed || false,
        selectedTimeZone: payload.updatedSiteData.unarmedTimeRange.timeZone,
        startTime: payload.updatedSiteData.unarmedTimeRange.startTime
      }
      await dispatch('updateUnarmedTimeRange', {
        siteId: payload.docId,
        unarmedTimeRange
      })
    }

    await dispatch('bindHcNotificationData', { siteId: payload.docId })

    if (!payload.docId) {
      throw new Error('Missing payload')
    }
  },
  updateAlarmDefinitionConfig: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        alarmDefinitionConfig: string
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        alarmDefinitionConfig: payload.alarmDefinitionConfig
      })
    }
  ),
  updateUnarmedTimeRange: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        unarmedTimeRange: object
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        unarmedTimeRange: payload.unarmedTimeRange
      })
    }
  ),
  updateContact: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        contact: object
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        contact: payload.contact
      })
    }
  ),
  updateEdgeDeviceRecordingStatus: firestoreAction(
    (
      context,
      payload: { siteId: string; isEdgeDeviceRecordingEnabled: boolean }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        isEdgeDeviceRecordingEnabled: payload.isEdgeDeviceRecordingEnabled
      })
    }
  ),
  updateScheduleUpdate: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        updateSchedulingConfig: {
          selectedTimeZone: string
          applicableDays: string[]
          updateFrequency: 'month' | 'week'
          updateNumber: number
          scheduledTime: string
          isAutoUpdate: boolean
          isScheduledUpdate: boolean
        }
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        promiseQube: {
          selectedTimeZone: payload.updateSchedulingConfig.selectedTimeZone,
          applicableDays: payload.updateSchedulingConfig.applicableDays,
          updateFrequency: payload.updateSchedulingConfig.updateFrequency,
          updateNumber: payload.updateSchedulingConfig.updateNumber,
          scheduledTime: payload.updateSchedulingConfig.scheduledTime,
          isAutoUpdate: payload.updateSchedulingConfig.isAutoUpdate,
          isScheduledUpdate: payload.updateSchedulingConfig.isScheduledUpdate
        }
      })
    }
  ),
  updateHumanReviewStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isHumanReviewEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        isAskCrowdWorkers: payload.isHumanReviewEnabled
      })
    }
  ),
  updateSiaIntegration(
    context,
    payload: {
      siteId: string
      data: any
    }
  ): Promise<HttpsCallableResult> {
    return siteProvider.generateSiaReceiver(payload.siteId, payload.data)
  },
  deleteSiaIntegration(
    context,
    payload: {
      siteId: string
      data: any
    }
  ): Promise<void> {
    return siteProvider.deleteSiaIntegration(payload.siteId, payload.data)
  },
  updateFtpEnabledStatus: firestoreAction(
    (
      { state }: any,
      payload: {
        siteId: string
        isFtpEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        isFtpEnabled: payload.isFtpEnabled
      })
    }
  ),
  updateIsFTPNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isFTPEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isFTPNotificationsEnabled: payload.isFTPEnabled
        },
        { merge: true }
      )
    }
  ),
  updateFTPConfig: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        ftpData: any
      }
    ) => {
      // Remove undefined fields from ftpData object
      const filteredFTPData = Object.fromEntries(
        Object.entries(payload.ftpData).map(([key, value]) => [
          key,
          value !== undefined ? value : ''
        ])
      )
      return siteCollection.doc(payload.siteId).set(
        {
          ftpData: filteredFTPData
        },
        { merge: true }
      )
    }
  ),
  updateSiaConfig: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        siaData: any
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          siaData: payload.siaData
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isSiaEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isSiaEnabled: payload.isSiaEnabled
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaHeartbeatEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isSiaHeartbeatEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isSiaHeartbeatEnabled: payload.isSiaHeartbeatEnabled
        },
        { merge: true }
      )
    }
  ),
  updateIsRelatedSiaAccountNotifiedStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isRelatedSiaAccountNotified: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isRelatedSiaAccountNotified: payload.isRelatedSiaAccountNotified
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaRoutineMessageEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isSiaRoutineMessageEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isSiaRoutineMessageEnabled: payload.isSiaRoutineMessageEnabled
        },
        { merge: true }
      )
    }
  ),
  updateIsEmailNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isEmailNotificationEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isEmailNotificationEnabled: payload.isEmailNotificationEnabled
        },
        { merge: true }
      )
    }
  ),
  updateNotificationEmails: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        notificationEmails: { email: string }[]
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          notificationEmails: payload.notificationEmails
        },
        { merge: true }
      )
    }
  ),
  updateIsWebhookTriggerEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isWebhookTriggerEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isWebhookTriggerEnabled: payload.isWebhookTriggerEnabled
        },
        { merge: true }
      )
    }
  ),
  addWebhookTriggerConfig: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        webhookTriggerData: any
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          webhookTriggerData: payload.webhookTriggerData
        },
        { merge: true }
      )
    }
  ),
  updateWebhookTriggerConfig: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        webhookTriggerData: any
      }
    ) => {
      // Update only urlParams if it exists in the payload
      const updateData = {}
      if (payload.webhookTriggerData.urlParams) {
        updateData['webhookTriggerData.urlParams'] =
          payload.webhookTriggerData.urlParams
      }
      if (payload.webhookTriggerData.headerParams) {
        updateData['webhookTriggerData.headerParams'] =
          payload.webhookTriggerData.headerParams
      }

      return siteCollection.doc(payload.siteId).update(updateData)
    }
  ),
  updateIsEvalinkNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isEvalinkEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isEvalinkEnabled: payload.isEvalinkEnabled
        },
        { merge: true }
      )
    }
  ),
  updateIsEvalinkDeviceOverrideEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isEvalinkDeviceOverrideEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          'evalinkData.isDeviceOverrideEnabled':
            payload.isEvalinkDeviceOverrideEnabled
        },
        { merge: true }
      )
    }
  ),
  updateEvalinkConfig: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        evalinkData: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          evalinkData: payload.evalinkData
        },
        { merge: true }
      )
    }
  ),
  bindSiteCameras: firestoreAction(
    async (
      { state, bindFirestoreRef, commit }: any,
      payload: {
        userId: string
        clientId: string
        siteId: string
      }
    ) => {
      commit('setSiteCameras', null)
      const clientSnap = await clientCollection
        .where('clientId', '==', payload.clientId)
        .where('users', 'array-contains', payload.userId)
        .get()

      if (clientSnap.empty) {
        console.log("Something went wrong or client doesn't exists")
        return
      }

      const siteSnap = await siteCollection
        .where('siteId', '==', payload.siteId)
        .where('users', 'array-contains', payload.userId)
        .where('client', '==', clientSnap.docs[0].ref)
        .get()

      if (siteSnap.empty) {
        console.log("Something went wrong or client doesn't exists")
        return
      }

      return bindFirestoreRef(
        'siteCameras',
        cameraCollection
          .where('site', '==', siteSnap.docs[0].ref)
          .where('users', 'array-contains', payload.userId),
        {
          maxRefDepth: 0,
          wait: true,
          serialize: (doc: { data: () => any; id: any }) => {
            const users = doc.data().users ?? []
            const writeUsers = doc.data().writeUsers ?? []
            const overrideUsers = doc.data().overrideUsers ?? []
            const clientData = clientSnap.docs.at(0).data()
            const siteData = siteSnap.docs.at(0).data()
            const isSiteHardwareDevice = siteData?.isHardwareDevice
            const edgeLiveStreamLimit = siteData?.edgeLimits?.liveStreamingLimit
            return {
              ...doc.data(),
              id: doc.id,
              siteId: siteData.siteId,
              clientId: clientData.clientId,
              isDefault: clientData?.type === 'Default',
              isWriteEnabled: writeUsers.includes(payload.userId),
              isOverrideEnabled: overrideUsers.includes(payload.userId),
              isAccessEnabled: users.includes(payload.userId),
              isSiteHardwareDevice,
              edgeLiveStreamLimit
            }
          }
        }
      )
    }
  ),
  updateIsHealthCheckNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isHealthCheckNotificationEnabled: any
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        isHealthCheckNotificationEnabled:
          payload.isHealthCheckNotificationEnabled
      })
    }
  ),
  updateHcDuration: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        healthCheckDuration: number
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        healthCheckDuration: payload.healthCheckDuration
      })
    }
  ),
  updateIsLatestHealthCheckNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isLatestHealthCheckNotificationEnabled: any
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        isLatestHealthCheckNotificationEnabled:
          payload.isLatestHealthCheckNotificationEnabled
      })
    }
  ),
  updateHcType: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        healthCheckType: string
      }
    ) => {
      return siteCollection.doc(payload.siteId).update({
        healthCheckType: payload.healthCheckType
      })
    }
  ),
  updateIsSMTPNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        isSMTPNotificationEnabled: boolean
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          isSMTPNotificationEnabled: payload.isSMTPNotificationEnabled
        },
        { merge: true }
      )
    }
  ),
  updateSMTPConfig: firestoreAction(
    (
      context,
      payload: {
        siteId: string
        smtpNotificationData: any
      }
    ) => {
      return siteCollection.doc(payload.siteId).set(
        {
          smtpNotificationData: payload.smtpNotificationData
        },
        { merge: true }
      )
    }
  )
}

export default {
  ...actions
}
