import { generateUniqueId } from './Generator'
import { getNodeActiveStatus, getNodeUnarmedStatus } from './Utils'

export enum NodeType {
  CLIENT = 'client',
  SITE = 'site',
  CAMERA = 'camera',
  CUSTOMER = 'customer'
}
type LoadMore = {
  id: string
  key: string
  name: string
  page: number
  type: string
  nodeType: string
  userFirestoreId?: string
  clientFirestoreId?: string
  siteFirestoreId?: string
  cameraFirestoreId?: string
  meta?: Meta
}
type AddNode = {
  id: string
  key: string
  name: string
  type: string
  nodeType: string
  userFirestoreId: string
  clientFirestoreId?: string
  siteFirestoreId?: string
  clientId?: string
  siteId?: string
}
export interface BaseNodeFormatParams {
  data: any
  userId: string
  isAdmin?: boolean
}
interface NodeFormatParams extends BaseNodeFormatParams {
  page: number
  size: number
  isUserCustomer: boolean
  isWriteEnabled: boolean
  meta?: Meta
  loadMoreMeta?: any
}
interface SiteFormatParams
  extends Omit<NodeFormatParams, 'isUserCustomer' | 'isWriteEnabled'> {
  client: any
}
interface CameraFormatParams extends SiteFormatParams {
  site: any
}
export type Meta = {
  search: boolean
  level: string
}
type LoadMoreParams = {
  page: number
  type: NodeType
  userFirestoreId: string
  clientFirestoreId?: string
  siteFirestoreId?: string
  cameraFirestoreId?: string
  meta?: Meta
}
type AddNodeParams = {
  type: NodeType
  userFirestoreId: string
  clientFirestoreId?: string
  siteFirestoreId?: string
  clientId?: string
  siteId?: string
  meta?: Meta
}
const getLoadMoreEntry = (params: LoadMoreParams) => {
  const {
    page,
    type,
    userFirestoreId,
    clientFirestoreId,
    siteFirestoreId,
    cameraFirestoreId,
    meta
  } = params
  const uniqueKey = generateUniqueId()
  let id: string

  const loadMore: LoadMore = {
    id: '',
    key: '',
    name: 'See more',
    type,
    userFirestoreId: userFirestoreId,
    page,
    nodeType: 'load',
    meta
  }
  switch (type) {
    case NodeType.CUSTOMER:
      id = userFirestoreId
      break
    case NodeType.CLIENT:
      id = clientFirestoreId
      loadMore.clientFirestoreId = clientFirestoreId
      break
    case NodeType.SITE:
      id = siteFirestoreId
      loadMore.clientFirestoreId = clientFirestoreId
      loadMore.siteFirestoreId = siteFirestoreId
      break
    case NodeType.CAMERA:
      id = cameraFirestoreId
      loadMore.clientFirestoreId = clientFirestoreId
      loadMore.siteFirestoreId = siteFirestoreId
      loadMore.cameraFirestoreId = cameraFirestoreId
      break
  }
  loadMore.id = `${uniqueKey}-${type}-load-${id}`
  loadMore.key = `${uniqueKey}-${type}-load-${id}`
  return loadMore
}
const getAddNodeEntry = (params: AddNodeParams) => {
  const {
    type,
    userFirestoreId,
    clientFirestoreId,
    siteFirestoreId,
    clientId,
    siteId
  } = params
  const uniqueKey = generateUniqueId()
  const addNew: AddNode = {
    id: '',
    key: '',
    name: `Add ${type.charAt(0).toUpperCase() + type.slice(1)}`,
    type,
    userFirestoreId: userFirestoreId,
    nodeType: 'add'
  }
  switch (type) {
    case NodeType.CLIENT:
      addNew.id = `${uniqueKey}-add-${type}-${userFirestoreId}`
      addNew.key = `${uniqueKey}-add-${type}-${userFirestoreId}`
      break
    case NodeType.SITE:
      addNew.id = `${uniqueKey}-add-${type}-${clientFirestoreId}`
      addNew.key = `${uniqueKey}-add-${type}-${clientFirestoreId}`
      addNew.clientFirestoreId = clientFirestoreId
      addNew.clientId = clientId
      break
    case NodeType.CAMERA:
      addNew.id = `${uniqueKey}-add-${type}-${siteFirestoreId}-${clientFirestoreId}`
      addNew.key = `${uniqueKey}-add-${type}-${siteFirestoreId}-${clientFirestoreId}`
      addNew.clientFirestoreId = clientFirestoreId
      addNew.siteFirestoreId = siteFirestoreId
      addNew.clientId = clientId
      addNew.siteId = siteId
      break
  }
  return addNew
}
const getTotalPages = (size: number, total: number) => {
  return Math.ceil(total / size)
}
export const formatCameras = (params: CameraFormatParams, isFresh = false) => {
  const { data, site, client, userId, size, page, isAdmin, loadMoreMeta } =
    params
  const metaData =
    loadMoreMeta?.meta && loadMoreMeta.type === NodeType.CAMERA
      ? loadMoreMeta?.meta
      : {
          level: NodeType.CAMERA,
          search: data.data?.[0]?.meta?.level === NodeType.CAMERA
        }
  const uniqueId = generateUniqueId()
  const cameras = data.data.map((camera) => {
    return {
      ...camera,
      key: `${uniqueId}-${camera.id}`,
      isWriteEnabled: isAdmin || camera.writeUsers.includes(userId),
      isNodeArmed: getNodeUnarmedStatus(client, site, camera),
      isActive: getNodeActiveStatus(
        camera.lastPingAt,
        camera.isEdgeDeviceEnabled,
        true,
        camera.isAuthenticated
      ),
      isHardwareDevice: site.isHardwareDevice,
      siteId: site.siteId,
      clientId: client.clientId,
      siteFirestoreId: site.id,
      clientFirestoreId: client.id,
      userFirestoreId: userId
    }
  })
  if (site.isWriteEnabled && (isFresh || page === 1)) {
    cameras.push(
      getAddNodeEntry({
        type: NodeType.CAMERA,
        userFirestoreId: userId,
        clientFirestoreId: client.id,
        clientId: client.clientId,
        siteFirestoreId: site.id,
        siteId: site.siteId
      })
    )
  }
  if (page < getTotalPages(size, data.total)) {
    cameras.push(
      getLoadMoreEntry({
        page,
        type: NodeType.CAMERA,
        userFirestoreId: userId,
        clientFirestoreId: client.id,
        siteFirestoreId: site.id,
        cameraFirestoreId: cameras?.[0]?.id,
        meta: metaData
      })
    )
  }

  return cameras
}
export const formatSites = (params: SiteFormatParams, isFresh = false) => {
  const { data, client, userId, isAdmin, page, size, loadMoreMeta } = params
  const metaData =
    loadMoreMeta?.meta && loadMoreMeta.type === NodeType.SITE
      ? loadMoreMeta?.meta
      : {
          level: NodeType.SITE,
          search: data.data?.[0]?.meta?.level === NodeType.SITE
        }
  const uniqueId = generateUniqueId()
  const sites = data.data.map((site) => {
    const isWriteEnabled = isAdmin || site.writeUsers.includes(userId)
    return {
      ...site,
      key: `${uniqueId}-${site.id}`,
      isWriteEnabled,
      isNodeArmed: getNodeUnarmedStatus(client, site),
      isActive: getNodeActiveStatus(site.lastPingAt, site.isHardwareDevice),
      clientId: client.clientId,
      clientFirestoreId: client.id,
      userFirestoreId: userId,
      children: formatCameras(
        {
          data: { data: site.children, total: site.total, meta: loadMoreMeta },
          client,
          site: { ...site, isWriteEnabled },
          userId,
          size,
          page,
          isAdmin,
          loadMoreMeta
        },
        isFresh
      )
    }
  })
  if (client.isWriteEnabled && page === 1) {
    sites.push(
      getAddNodeEntry({
        type: NodeType.SITE,
        userFirestoreId: userId,
        clientFirestoreId: client.id,
        clientId: client.clientId
      })
    )
  }
  if (page < getTotalPages(size, data.total)) {
    sites.push(
      getLoadMoreEntry({
        page,
        type: NodeType.SITE,
        userFirestoreId: userId,
        clientFirestoreId: client.id,
        meta: metaData
      })
    )
  }

  return sites
}
const formatClients = (params: NodeFormatParams) => {
  const {
    data,
    page,
    size,
    userId,
    isAdmin,
    isWriteEnabled,
    isUserCustomer,
    loadMoreMeta
  } = params
  const uniqueId = generateUniqueId()
  const clients = data.data.map((client) => {
    const isWriteEnabled = isAdmin || client.writeUsers.includes(userId)
    return {
      ...client,
      key: `${uniqueId}-${client.id}`,
      isNodeArmed: getNodeUnarmedStatus(client),
      isWriteEnabled,
      isDefault: client.name === 'Default',
      userFirestoreId: userId,
      children: formatSites({
        data: { data: client.children, total: client.total },
        client: { ...client, isWriteEnabled },
        userId,
        size,
        page: 1,
        isAdmin,
        loadMoreMeta
      })
    }
  })
  if ((isAdmin || isUserCustomer || isWriteEnabled) && page === 1) {
    clients.push(
      getAddNodeEntry({
        type: NodeType.CLIENT,
        userFirestoreId: userId,
        clientFirestoreId: clients?.[0]?.id
      })
    )
  }
  if (page < getTotalPages(size, data.total)) {
    const meta =
      loadMoreMeta?.meta && loadMoreMeta.type === NodeType.CLIENT
        ? loadMoreMeta?.meta
        : clients?.[0]?.meta
    clients.push(
      getLoadMoreEntry({
        page,
        type: NodeType.CLIENT,
        userFirestoreId: userId,
        clientFirestoreId: clients?.[0]?.id,
        meta
      })
    )
  }

  return clients
}
const formatUsers = (params: NodeFormatParams) => {
  const { data, page, size, isUserCustomer, isWriteEnabled, loadMoreMeta } =
    params
  const uniqueId = generateUniqueId()
  const users = data.data.map((user) => {
    return {
      ...user,
      key: `${uniqueId}-${user.id}`,
      isNodeArmed: true,
      userFirestoreId: user.id,
      children: formatClients({
        data: { data: user.children, total: user.total },
        size,
        page: 1,
        userId: user.id,
        isAdmin: true,
        isUserCustomer,
        isWriteEnabled,
        loadMoreMeta
      })
    }
  })
  if (page < getTotalPages(size, data.total)) {
    const meta =
      loadMoreMeta?.meta && loadMoreMeta.type === NodeType.CUSTOMER
        ? loadMoreMeta?.meta
        : users?.[0]?.meta
    users.push(
      getLoadMoreEntry({
        page,
        type: NodeType.CUSTOMER,
        userFirestoreId: users?.[0]?.id,
        meta
      })
    )
  }
  return users
}
export const formatNodeStructure = (params: NodeFormatParams) => {
  const { isAdmin, userId } = params
  if (isAdmin && !userId) {
    return formatUsers(params)
  }
  return formatClients(params)
}

export const removeLoadMoreEntry = (list: any[], loadMoreId: string) => {
  const index = list.findIndex((item) => item.id === loadMoreId)
  if (index > -1) {
    list.splice(index, 1)
  }
}

export const swapAddNewNodeEntry = (list: any[]) => {
  const index = list.findIndex((item) => item.nodeType === 'add')
  if (index > -1) {
    const item = list.splice(index, 1)[0]
    if (list[list.length - 1]?.nodeType === 'load') {
      list.splice(list.length - 1, 0, item)
    } else {
      list.push(item)
    }
  }
}

export const savePaginatedNodesToTheRightPlace = (
  allNodes: any[],
  { userId, siteId, clientId, loadMoreId, data, isAdmin, page, size, type }
) => {
  let clientList = allNodes
  let userIndex
  if (isAdmin) {
    userIndex = allNodes.findIndex((item) => item.id === userId)
    if (userIndex > -1) {
      clientList = allNodes[userIndex].children
    }
  }
  if (clientId) {
    const clientIndex = clientList.findIndex((item) => item.id === clientId)
    const siteList = clientList[clientIndex].children
    if (siteId && type === NodeType.CAMERA) {
      const siteIndex = siteList.findIndex((item) => item.id === siteId)
      const cameraList = siteList[siteIndex].children
      removeLoadMoreEntry(cameraList, loadMoreId)
      const formattedData = formatCameras({
        client: clientList[clientIndex],
        site: siteList[siteIndex],
        data,
        page,
        size,
        userId,
        isAdmin
      })
      cameraList.push(...formattedData)
      swapAddNewNodeEntry(cameraList)
      siteList[siteIndex].children = cameraList
    } else if (type === NodeType.SITE) {
      removeLoadMoreEntry(siteList, loadMoreId)
      const formattedData = formatSites(
        {
          client: clientList[clientIndex],
          data,
          page,
          size,
          userId,
          isAdmin
        },
        true
      )
      siteList.push(...formattedData)
      swapAddNewNodeEntry(siteList)
    }
    clientList[clientIndex].children = siteList
    if (isAdmin && userIndex) {
      allNodes[userIndex].children = clientList
    } else {
      allNodes = clientList
    }
  }
}
