var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600px","text-xs-right":"","persistent":""},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('v-card',{staticStyle:{"padding":"10px"},style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.darkPrimaryColor
            : _vm.getColors.lightPrimaryColor,
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor
      })},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-toolbar',{staticClass:"secondary elevation-0",attrs:{"dark":""}},[_c('v-toolbar-title',{staticStyle:{"color":"black"},attrs:{"data-test-id":"create-new-user-label"}},[_vm._v(" Do you want to add this "+_vm._s(_vm.isUserCustomer ? 'collaborator' : 'customer')+"? ")])],1),(_vm.error)?_c('v-layout',[_c('v-flex',[_c('app-alert',{attrs:{"text":_vm.error.message},on:{"dismissed":_vm.onDismissed}})],1)],1):_vm._e(),_c('v-flex',[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{staticClass:"ma-2 pa-2"},[_c('Avatar',{attrs:{"width":'80px',"id":_vm.id}})],1),_c('v-flex',[_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-flex',[_c('v-card-text',{staticClass:"text-h6 text-left pa-0"},[_vm._v(" "+_vm._s(_vm.firstName)+" "+_vm._s(_vm.lastName)+" ")]),_c('v-card-text',{staticClass:"text-body-2 text-left pa-0"},[_vm._v(" "+_vm._s(_vm.email)+" ")])],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('Button',{staticClass:"text-subtitle-2 close-btn",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : 'black'
            }),attrs:{"text":"","btnStyle":"outlined","disabled":_vm.isLoading,"data-ci-new-user-close-button":true,"btnText":"Close","data-test-id":"create-user-cancel-button"},on:{"onButtonClick":_vm.cancelNewUser}}),_c('Button',{staticClass:"text-subtitle-2",attrs:{"type":"submit","color":"black--text elevation-0","disabled":!_vm.isValid,"loading":_vm.isLoading,"data-ci-new-user-save-button":true,"btnText":"Add","data-test-id":"create-user-save-button"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }