<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
      >
        FTP/SFTP
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        data-test-id="ftp-smtp-expand-icon"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>
    <div v-if="isExpanded" class="pr-3 pl-3 py-4 form">
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            FTP Host:
            <code
              class="pointer"
              v-text="'ftp.promiseq.com'"
              @click="onClickCopy('ftp.promiseq.com')"
              data-test-id="ftp-host-copy"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            SFTP Host:
            <code
              class="pointer"
              v-text="'sftp.promiseq.com'"
              @click="onClickCopy('sftp.promiseq.com')"
              data-test-id="sftp-host-copy"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            FTP Port:
            <code
              class="pointer"
              v-text="'21'"
              data-test-id="ftp-port-copy"
              @click="onClickCopy('21')"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            SFTP Port:
            <code
              class="pointer"
              v-text="'22'"
              data-test-id="sftp-port-copy"
              @click="onClickCopy('22')"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            FTP Mode:
            <code v-text="'PASSIVE'" />
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            Username:
            <code
              class="pointer"
              v-text="projectId"
              data-test-id="ftp-username-copy"
              @click="onClickCopy(`${projectId}`)"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col class="d-flex align-center">
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            Password:
          </p>
          <v-select
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            style="max-width: 50%; height: 40px"
            v-model="selectedFtpPassword"
            class="text-caption ml-1"
            append-icon="mdi-chevron-down"
            content-class="elevation-0"
            :items="subscriptionKeys"
            outlined
            hide-details="true"
            dense
          >
            <template #prepend-item>
              <v-card
                flat
                width="100%"
                height="100%"
                :style="{
                  backgroundColor:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }"
              />
            </template>
            <template #item="{ item }">
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                }"
              >
                {{ item }}
              </span>
            </template>
          </v-select>
          <v-btn
            :ripple="false"
            text
            icon
            class="ml-1"
            @click="onClickCopy(selectedFtpPassword)"
          >
            <v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="list-custom-icon"
              >{{ 'mdi-content-copy' }}</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            Path:
            <code
              class="pointer"
              v-text="getId"
              data-test-id="ftp-path-copy"
              @click="onClickCopy(getId)"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>

      <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          Turn on/off FTP integration
        </div>
        <div>
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            id="alarm-ftp-notification-switch"
            v-model="isFtpEnabled"
            text
            class="notification-switch pt-1"
            color="secondary"
            inset
            data-test-id="ftp-smtp-on-off-switch"
            @change="onToggleFtpStatus"
            :disabled="!isWriteEnabled"
          ></v-switch>
        </div>
      </div>
    </div>
    <!-- Snackbar message -->
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text text-subtitle-2"
        />
      </template>
    </v-snackbar>
    <!-- End of Snackbar message -->
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: { Button }
})
export default class FtpConfiguration extends Vue {
  @Prop() configuration: any
  @Prop() siteId: string
  @Prop() cameraId: string
  @Prop() subscriptionKeys: string[]
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop({ default: false }) isWriteEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isExpanded: boolean = false

  public projectId: string = process.env.VUE_APP_PROJECT_ID
  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public selectedFtpPassword = ''

  public isFtpEnabled: boolean = false

  public originalFtpData: any = null

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  public mounted() {
    this.watchConfiguration()
    this.watchSubscriptionKeys()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      this.isFtpEnabled = this.configuration.isFtpEnabled ?? false
      this.isExpanded = this.isFtpEnabled

      this.originalFtpData = {
        isFtpEnabled: this.isFtpEnabled
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return this.isFtpEnabled !== this.originalFtpData.isFtpEnabled
  }

  @Watch('subscriptionKeys')
  public watchSubscriptionKeys() {
    if (this.subscriptionKeys && this.subscriptionKeys.length > 0) {
      this.selectedFtpPassword = this.subscriptionKeys.at(0)
    }
  }

  public onToggleFtpStatus(value) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  public onClickCopy(text: string) {
    navigator.clipboard.writeText(text)
    this.popSnackbarMessage(`${text} copied.`)
  }

  public get getId() {
    if (this.siteId && this.cameraId) {
      return `${this.siteId}/${this.cameraId}`
    } else {
      return `${this.siteId}`
    }
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
  margin-bottom: 0;
}

.tooltip .tooltip-copy-text {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
}

.tooltip .tooltip-copy-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltip-copy-text {
  visibility: visible;
  opacity: 1;
}

.pointer {
  cursor: pointer;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
