import * as handlebars from 'handlebars'
import axios from 'axios'
const authHost = process.env.VUE_APP_AUTH_DOMAIN
const authUrl = `https://${authHost}`

export const loadTemplate = async (templatePath: string): Promise<string> => {
  try {
    const response = await axios.get(templatePath)
    return response.data
  } catch (error) {
    console.error('Error loading template:', error)
    throw new Error(`Failed to load template from ${templatePath}`)
  }
}

// Register partials with Handlebars
export const registerPartials = async (
  templateDir: string,
  partialNames: string[] = ['header', 'footer']
): Promise<void> => {
  for (const partialName of partialNames) {
    const partialPath = `${templateDir}/${partialName}.html`
    const partialContent = await loadTemplate(partialPath)
    handlebars.registerPartial(partialName, partialContent)
  }
}

// Generate email content from templates
export const generateEmailContent = async (
  specificTemplateName: string,
  data: Record<string, any>,
  logoPath: string = `${authUrl}/img/email_logo.png`,
  bannerPath: string = `${authUrl}/img/email_banner.jpg`
): Promise<{ subject: string; html: string }> => {
  const baseTemplateDir = '/emails/email-template/base-template'
  const specificTemplateDir = `/emails/email-template/${specificTemplateName}`

  // Register partials from the base template directory
  await registerPartials(baseTemplateDir)

  // Load and register the layout partial
  const layoutHtml = await loadTemplate(`${baseTemplateDir}/layout.html`)
  handlebars.registerPartial('layout', layoutHtml)

  // Load and compile the specific template HTML
  const specificHtml = await loadTemplate(
    `${specificTemplateDir}/${specificTemplateName}.html`
  )
  const compiledBase = handlebars.compile(specificHtml)
  const finalHtml = compiledBase({ ...data, logoPath, bannerPath })

  // Load and compile the specific template subject
  const specificSubject = await loadTemplate(
    `${specificTemplateDir}/${specificTemplateName}.subject`
  )
  const compiledSubject = handlebars.compile(specificSubject)

  return {
    subject: compiledSubject(data),
    html: finalHtml
  }
}
