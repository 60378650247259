<template>
  <v-col cols="7">
    <v-menu
      :disabled="disabled"
      :style="{
        pointerEvents: disabled ? 'none' : 'auto'
      }"
      bottom
      offset-y
      :close-on-content-click="false"
      v-model="isTimeSelectorMenuOpen"
    >
      <template v-slot:activator="{ on }">
        <v-card
          class="time-selector-btn mx-1 text-caption"
          :class="
            isUserAdmin && isDarkModeToggleEnabled ? 'admin-selector-card' : ''
          "
          :style="{
            'max-width': '313px',
            'background-color':
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkFrameColor
                : getColors.lightPrimaryColor
          }"
          dense
          outlined
        >
          <v-row no-gutters>
            <v-icon
              :disabled="disabled"
              class="mdi mdi-calendar-edit minimized-clickable-icon"
              style="cursor: pointer; padding-right: 0px"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : ''
              }"
              v-on="on"
              @mousedown.prevent
            ></v-icon>
            <v-text-field
              class="text-area-diplay-switch time-selector-btn mx-1 text-caption"
              :style="{
                'max-width': '235px'
              }"
              :background-color="
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.darkFrameColor
                  : getColors.lightPrimaryColor
              "
              dense
              readonly
              flat
              solo
              placeholder="Choose time range"
              hide-details
              :value="customTimeRange"
              style="cursor: pointer, color= 'red'"
              v-on="on"
              :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
            />
            <v-icon
              :disabled="disabled"
              class="mdi mdi-close-circle minimized-clickable-icon"
              style="cursor: pointer; padding-left: 0px"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : ''
              }"
              @mousedown.prevent
              @click="resetGlobalDateAndTime"
            ></v-icon>
          </v-row>
        </v-card>
      </template>
      <v-card class="timecards" max-width="400px">
        <v-container
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkPrimaryColor
                : getColors.lightPrimaryColor
          }"
          style="padding: 12px 6px 12px 18px !important"
        >
          <v-row dense class="dropdown-row">
            <p
              style="text-align: left; font-weight: bold"
              class="text-subtitle-2"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              Quick Select
            </p>
          </v-row>
          <v-row dense style="max-height: 50px">
            <v-col cols="3" class="d-flex flex-row justify-start">
              <v-select
                class="text-body-2"
                dense
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                v-model="type"
                :items="timeType"
                :append-icon="'mdi-chevron-down'"
                :menu-props="{}"
                outlined
                :responsive="{ xs: 12, sm: 'auto' }"
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              >
                <template #prepend-item>
                  <v-card
                    flat
                    width="100%"
                    height="100%"
                    :style="{
                      backgroundColor:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.darkFrameColor
                          : '',
                      position: 'absolute',
                      top: '0px'
                    }"
                  />
                </template>
                <template #item="{ item }">
                  <span
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : '',
                      margin: 'auto',
                      'font-size': '13px'
                    }"
                  >
                    {{ item }}
                  </span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="2" class="d-flex flex-row justify-start">
              <v-text-field
                class="text-body-2"
                dense
                v-model="timeInput"
                outlined
                placeholder="1"
                type="number"
                step="any"
                min="1"
                max="30"
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                :responsive="{ xs: 12, sm: 'auto' }"
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              ></v-text-field>
            </v-col>

            <v-col cols="4" class="d-flex flex-row justify-start">
              <v-select
                class="text-body-2"
                dense
                v-model="unit"
                :items="timeUnit"
                :append-icon="'mdi-chevron-down'"
                placeholder="Days"
                outlined
                :responsive="{ xs: 12, sm: 2 }"
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              >
                <template #prepend-item>
                  <v-card
                    flat
                    width="100%"
                    height="100%"
                    :style="{
                      backgroundColor:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.darkFrameColor
                          : '',
                      position: 'absolute',
                      top: '0px'
                    }"
                  />
                </template>
                <template #item="{ item }">
                  <span
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : '',
                      margin: 'auto',
                      'font-size': '13px'
                    }"
                  >
                    {{ item }}
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="3" class="d-flex flex-row justify-center">
              <Button
                class="lower-case selected text-subtitle-2"
                style="background-color: #ffd42a !important"
                color="white"
                :ripple="false"
                @onButtonClick="setDefinedQuickTimeInterval"
                btnText="Apply"
                :disabled="!isQuickSelectEnabled"
              />
            </v-col>
          </v-row>
          <v-divider
            horizontal
            class="mx-2 my-2"
            :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
          ></v-divider>

          <v-row class="dropdown-row">
            <v-col cols="12" class="d-flex-column text-left">
              <p
                style="text-align: left; font-weight: bold"
                class="text-subtitle-2"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : ''
                }"
              >
                Recently Used
              </p>
            </v-col>
          </v-row>

          <v-row
            v-if="recentArray.length > 0"
            class="dropdown-row"
            justify="start"
            no-gutters
          >
            <v-col
              cols="4"
              v-for="item in recentArray"
              :key="item.id"
              class="d-flex"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : 'grey'
              }"
            >
              <p
                class="timeText"
                @click="
                  setCommonlyUsedTimeRange(item.value, item.unit, item.label)
                "
              >
                {{ item.label }}
              </p>
            </v-col>
          </v-row>
          <v-row v-else class="dropdown-row" justify="start" no-gutters>
            <v-col cols="4" class="d-flex">
              <p class="timeText">&nbsp;</p>
            </v-col>
          </v-row>
          <v-divider
            horizontal
            class="mx-2 my-2"
            :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
          ></v-divider>
          <v-row class="dropdown-row">
            <v-col cols="12" class="d-flex-column text-left">
              <p
                style="text-align: left; font-weight: bold"
                class="text-subtitle-2"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : ''
                }"
              >
                Commonly Used
              </p>
            </v-col>
          </v-row>
          <v-row justify="start" no-gutters class="commonly-used-block">
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(1, 'days', 'Today')"
              >
                Today
              </p>
              <v-spacer />
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(1, 'hours', 'Last 1 hour')"
              >
                Last 1 hour
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(3, 'days', 'Last 3 days')"
              >
                Last 3 days
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(7, 'days', 'This week')"
              >
                This week
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(3, 'hours', 'Last 3 hours')"
              >
                Last 3 hours
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(5, 'days', 'Last 5 days')"
              >
                Last 5 days
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(15, 'minutes', 'Last 15 min')"
              >
                Last 15 min
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(12, 'hours', 'Last 12 hours')"
              >
                Last 12 hours
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(7, 'days', 'Last 7 days')"
              >
                Last 7 days
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(30, 'minutes', 'Last 30 min')"
              >
                Last 30 min
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(24, 'hours', 'Last 24 hours')"
              >
                Last 24 hours
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(14, 'days', 'Last 2 weeks')"
              >
                Last 2 weeks
              </p>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p
                class="timeText"
                @click="setCommonlyUsedTimeRange(30, 'days', 'Last 30 days')"
              >
                Last 30 days
              </p>
            </v-col>
          </v-row>
          <v-divider
            horizontal
            class="mx-2 my-2"
            :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
          ></v-divider>
          <v-row dense class="dropdown-row">
            <p
              style="text-align: left; font-weight: bold"
              class="text-subtitle-2"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : ''
              }"
            >
              Custom time range
            </p>
          </v-row>

          <v-row dense style="max-height: 50px">
            <v-col cols="5" class="d-flex flex-row justify-start">
              <v-text-field
                class="text-body-2"
                dense
                outlined
                v-model="customStartDate"
                label="Start date"
                type="date"
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-flex flex-row justify-start">
              <v-text-field
                class="text-body-2"
                dense
                outlined
                v-model="customStartTime"
                label=""
                type="time"
                step="0.001"
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense style="max-height: 50px">
            <v-col cols="5" class="d-flex flex-row justify-start">
              <v-text-field
                class="text-body-2"
                dense
                outlined
                v-model="customEndDate"
                label="End date"
                type="date"
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-flex flex-row justify-start">
              <v-text-field
                class="text-body-2"
                dense
                outlined
                v-model="customEndTime"
                label=""
                type="time"
                step="0.001"
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11" class="d-flex flex-row justify-end">
              <Button
                class="lower-case selected text-subtitle-2"
                color="black"
                :ripple="false"
                @onButtonClick="setGlobalDateAndTime"
                btnText="Apply"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </v-col>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Button from '@/components/app/Button.vue'
import moment from 'moment'
import { Getter, Mutation } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class TimeRangeSelector extends Vue {
  @Prop({ default: false }) public disabled: boolean

  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getGlobalTimeRange', namespaceConfig) public globalTimeRange: any
  @Getter('getIsAlarmRefreshEnabled', namespaceConfig) public isRefreshEnabled

  @Mutation('setGlobalTimeRange', namespaceConfig) public setGlobalTimeRange
  @Mutation('setIsAlarmRefreshEnabled', namespaceConfig)
  public setIsAlarmRefreshEnabled

  public type = 'Last'
  public unit = ''
  public timeType = ['Last']
  public timeUnit = ['min', 'hours', 'days', 'weeks']
  public timeInput: number = 1
  public recentArray = []

  public customStartDate: any = '0'
  public customStartTime: any = '0'
  public customEndDate: any = '0'
  public customEndTime: any = '0'

  public isStartTimeMenuOpened: boolean = false
  public isEndTimeMenuOpened: boolean = false
  public isStartDatePickerMenuOpened = false
  public isEndDatePickerMenuOpened = false
  public isQuickSelectEnabled: boolean = false
  public isTimeSelectorMenuOpen: boolean = false

  public customTimeRange: string = ''
  public DATE_FORMAT: string = 'YYYY-MM-DDTHH.mm.ss.SSS'

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public mounted() {
    this.watchRouteQuery()
    this.watchGlobalTimeRange()
  }

  @Watch('timeInput')
  @Watch('unit')
  private watchQuickSelect() {
    if (this.timeInput && this.unit) {
      this.isQuickSelectEnabled = true
    } else {
      this.isQuickSelectEnabled = false
    }
  }

  @Watch('$route.query')
  private async watchRouteQuery() {
    const { from: startDateQuery, to: endDateQuery } = this.$route.query
    const { startDateTimeGlobal, endDateTimeGlobal } = this.globalTimeRange

    if (startDateQuery && endDateQuery) {
      if (endDateQuery === 'now') {
        if (!this.isRefreshEnabled) {
          await this.setIsAlarmRefreshEnabled(true)
        }

        if (
          !moment(startDateQuery, this.DATE_FORMAT).isSame(
            moment(startDateTimeGlobal)
          )
        ) {
          this.setGlobalTimeRange({
            startDateTime: moment(startDateQuery, this.DATE_FORMAT),
            endDateTime: moment()
          })
          this.setDateTimeFields()
          this.setTimeRangeText(null)
        }
      } else {
        if (
          !moment(startDateQuery, this.DATE_FORMAT).isSame(
            moment(startDateTimeGlobal)
          ) ||
          !moment(endDateQuery, this.DATE_FORMAT).isSame(
            moment(endDateTimeGlobal)
          )
        ) {
          this.setGlobalTimeRange({
            startDateTime: moment(startDateQuery, this.DATE_FORMAT),
            endDateTime: moment(endDateQuery, this.DATE_FORMAT)
          })
          this.setDateTimeFields()
          this.setTimeRangeText(null)
        }
      }
    }
  }

  @Watch('globalTimeRange')
  private watchGlobalTimeRange() {
    const { startDateTime, endDateTime } = this.globalTimeRange
    const { from: startDateQuery, to: endDateQuery } = this.$route.query

    if (startDateTime && endDateTime) {
      if (this.isRefreshEnabled) {
        if (
          !moment(startDateQuery, this.DATE_FORMAT).isSame(
            moment(startDateTime)
          ) ||
          endDateQuery !== 'now'
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              from: moment(startDateTime).format(this.DATE_FORMAT),
              to: 'now'
            }
          })
          this.setDateTimeFields()
          this.setTimeRangeText(null)
        }
      } else {
        if (
          !moment(startDateQuery, this.DATE_FORMAT).isSame(
            moment(startDateTime)
          ) ||
          !moment(endDateQuery, this.DATE_FORMAT).isSame(moment(endDateTime))
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              from: moment(startDateTime).format(this.DATE_FORMAT),
              to: moment(endDateTime).format(this.DATE_FORMAT)
            }
          })
          this.setDateTimeFields()
          this.setTimeRangeText(null)
        }
      }
    }
  }

  public setDateTimeFields() {
    const { startDateTime, endDateTime } = this.globalTimeRange
    this.customStartDate = moment(startDateTime).format('YYYY-MM-DD')
    this.customEndDate = moment(endDateTime).format('YYYY-MM-DD')
    this.customStartTime = moment(startDateTime).format('HH:mm:ss.SSS')
    this.customEndTime = moment(endDateTime).format('HH:mm:ss.SSS')
  }

  public resetGlobalDateAndTime() {
    this.setGlobalTimeRange({
      startDateTime: moment().subtract(14, 'd'),
      endDateTime: moment()
    })
    this.setTimeRangeText(null)
  }

  private closeMenu() {
    this.isTimeSelectorMenuOpen = false
  }

  // Sets the recently used time ranges
  public addItemToRecentArray(value, unit, label) {
    const newNode = { value: value, unit: unit, label: label }

    for (let i = 0; i < this.recentArray.length; i++) {
      if (this.recentArray[i].label == label) {
        return
      }
    }
    if (this.recentArray.length >= 3) {
      this.recentArray.pop()
      this.recentArray.unshift(newNode)
    } else {
      this.recentArray.unshift(newNode)
    }
  }

  public setGlobalDateAndTime() {
    this.setGlobalTimeRange({
      startDateTime: moment(`${this.customStartDate}T${this.customStartTime}`),
      endDateTime: moment(`${this.customEndDate}T${this.customEndTime}`)
    })

    this.setTimeRangeText(null)
    this.setIsAlarmRefreshEnabled(false)
    this.closeMenu()
  }

  // Commonly used Time properties
  public async setCommonlyUsedTimeRange(
    count: number,
    unit: moment.unitOfTime.DurationConstructor,
    label: string = ''
  ) {
    let startDateTime = moment()
    if (label === 'Today') {
      startDateTime = startDateTime.startOf('day')
    } else if (label === 'This week') {
      startDateTime = startDateTime.startOf('week')
    } else {
      startDateTime = startDateTime.subtract(count, unit)
    }
    const endDateTime = moment()

    await this.setGlobalTimeRange({
      startDateTime: startDateTime,
      endDateTime: endDateTime
    })

    this.setDateTimeFields()

    this.addItemToRecentArray(count, unit, label)
    this.closeMenu()

    this.setTimeRangeText(label)
  }

  //Quick manual time selectors
  public async setDefinedQuickTimeInterval() {
    let durationUnit: moment.unitOfTime.DurationConstructor = 'days'
    const inputNumber: number = Number(this.timeInput)

    switch (this.unit) {
      case 'days':
        durationUnit = 'days'
        break
      case 'hours':
        durationUnit = 'hours'
        break
      case 'min':
        durationUnit = 'minutes'
        break
      case 'weeks':
        durationUnit = 'weeks'
        break
      default:
        durationUnit = 'days'
        break
    }

    const startDateTime = moment().subtract(inputNumber, durationUnit)
    const endDateTime = moment()

    await this.setGlobalTimeRange({
      startDateTime: startDateTime,
      endDateTime: endDateTime
    })

    this.setDateTimeFields()

    this.closeMenu()

    this.setTimeRangeText(`Last ${inputNumber} ${durationUnit}`)
  }

  // Assign timer range text
  private setTimeRangeText(label: string = null) {
    if (label) {
      this.customTimeRange = `${label}`
    } else {
      const start = this.globalTimeRange.startDateTime
      const end = this.globalTimeRange.endDateTime
      this.customTimeRange =
        ' from ' +
        moment(start).format('DD/MM/yy') +
        '       to ' +
        moment(end).format('DD/MM/yy')
    }
  }
}
</script>

<style>
.time-selector-btn {
  color: rgb(0, 0, 0) !important;
  border-radius: 4px !important;
  text-transform: unset !important;
}

.time-selector-btn input {
  cursor: pointer !important;
}

.clear-btn,
.apply-btn {
  text-transform: unset !important;
}

.clear-btn {
  border: 1px solid #ffd42a !important;
}

.clear-btn:hover {
  background-color: #ffe789 !important;
}

.tr-button {
  background-color: grey;
  text-transform: unset !important;
}

.timeText {
  cursor: pointer;
  color: grey;
  font-size: 14px;
  max-height: 10px;
}
.timeRow {
  display: flex;
  flex-direction: column;
}

.timecards {
  /* display: flex; */
}
.selector2 {
  width: 50%;
  margin-left: 20px;
}

.dropdown-row {
  max-height: 50px;
}

.commonly-used-block {
  margin-top: 10px !important;
}
.minimized-clickable-icon {
  padding: 7px 10px 7px 10px;
}

.text-area-diplay-switch {
  display: block !important;
}

.admin-selector-card {
  border: 1px solid #938d76 !important;
}

/* input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
} */

@media screen and (max-width: 800px) {
  .timecards {
    flex-direction: column;
  }

  .timeRow {
    display: flex;
    flex-direction: row;
  }

  .selector2 {
    width: 100%;
    margin-left: 0px;
    padding-top: 20px;
  }
}
@media screen and (max-width: 1580px) {
  .text-area-diplay-switch {
    display: none !important;
  }
  .minimized-clickable-icon {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .time-selector-btn {
    max-width: 90px !important;
  }
}
</style>
