export interface ISiteState {
  siteConfig: any
  siteCameras: any
  siteUsers: any[]
}

export const getDefaultState = (): ISiteState => {
  return {
    siteConfig: null,
    siteCameras: null,
    siteUsers: []
  }
}

const state = getDefaultState()

export default {
  ...state
}
