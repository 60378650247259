/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import Router from 'vue-router'
import Meta from 'vue-meta'
import { auth } from '@/provider/firebase'
import apm from '@/plugins/apm'
import { store } from '@/store'

document.title = 'promiseQ'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout.vue')
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('@/views/Imprint.vue')
  },
  {
    path: '/user-management/:userId?/:clientId?/:siteId?/:cameraId?',
    name: 'UserManagement',
    component: () => import('@/views/UserManagement.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sandbox/:userId?/:clientId?/:siteId?/:cameraId?',
    name: 'Sandbox',
    component: () => import('@/views/Sandbox.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/statistics/:userId?/:clientId?/:siteId?/:cameraId?',
    name: 'Statistics',
    component: () => import('@/views/Statistics.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('@/views/NewTodoLook.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/configurations/:userId?/:clientId?/:siteId?/:cameraId?',
    name: 'Configurations',
    component: () => import('@/views/Configurations.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/eagle-eye',
    name: 'EagleEyeConnect',
    component: () => import('@/views/EagleEyeConnect.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/incident/:incidentId',
    name: 'IncidentObservation',
    component: () => import('@/views/IncidentObservation.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/live/:userId?/:clientId?/:siteId?/:cameraId?',
    name: 'Live',
    component: () => import('@/views/Live.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/health-check/:userId?/:clientId?/:siteId?/:cameraId?',
    name: 'HealthCheck',
    component: () => import('@/views/HealthCheck.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/alarms/:userId?/:clientId?/:siteId?/:cameraId?',
    name: 'Alarms',
    component: () => import('@/views/Alarms.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/maps/:userId?/:clientId?/:siteId?/:cameraId?',
    name: 'Maps',
    component: () => import('@/views/Maps.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/worker-space-person',
    name: 'WorkerSpacePerson',
    component: () => import('@/views/WorkerSpacePerson.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/worker-space-health-check',
    name: 'WorkerSpaceHealthCheck',
    component: () => import('@/views/WorkerSpaceHealthCheck.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/worker-space-metadata',
    name: 'WorkerSpaceMetaData',
    component: () => import('@/views/WorkerSpaceMetaData.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/worker-space-info',
    name: 'WorkerSpaceInfo',
    component: () => import('@/views/WorkerSpaceInfo.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/worker-home',
    name: 'WorkerHome',
    component: () => import('@/views/WorkerHome.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import('@/views/TermsAndConditions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import('@/views/Disclaimer.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/onboard',
    name: 'Onboard',
    component: () => import('@/views/Onboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-settings',
    name: 'CustomerManagement',
    component: () => import('@/views/CustomerManagement.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/knowledge-base',
    name: 'KnowledgeBase',
    component: () => import('@/views/KnowledgeBase.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/service-status',
    name: 'ServiceStatus',
    component: () => import('@/views/ServiceStatus.vue'),
    meta: {
      requiresOnPrem: true
    }
  },
  {
    path: '/api',
    name: 'SwaggerUI',
    component: () => import('@/views/Swagger.vue')
  },
  {
    path: '/',
    redirect: () => {
      const routeSnapshot = store.getters['snapshot/getRouteSnapshot']
      return routeSnapshot?.route || '/alarms'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/components/error-pages/NotFound.vue')
  }
]

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const user = auth.currentUser

  if (to.meta?.requiresAuth && !user) {
    sessionStorage.setItem('intended_route', JSON.stringify(to))
    next({ name: 'Login' })
  } else {
    next()
  }
})

router.onError((err) => {
  console.error(err)
})

// apm traces
apm.initRouter(router)

Vue.use(Meta)

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.GOOGLE_ANALYTICS) {
  Vue.use(VueAnalytics, {
    id: process.env.GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development'
    }
  })
}

export default router
