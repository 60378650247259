<template>
  <div>
    <v-dialog max-width="600px" text-xs-right v-model="isDialog" persistent>
      <v-spacer></v-spacer>
      <template v-slot:activator="{ on }">
        <v-btn
          large
          style="background-color: #ffd42a"
          class="add-button elevation-0 text-subtitle-2"
          color=" black--text"
          v-on="on"
          :data-ci-new-user-add-user-button="true"
          :disabled="!isWriteEnabled"
          data-test-id="add-new-user-button"
        >
          <v-icon style="padding-right: 15px" data-test-id="add-new-user-label"
            >mdi-account-plus</v-icon
          >
          Add New
          {{ isUserCustomer ? 'Collaborator' : 'Customer' }}
        </v-btn>
      </template>
      <v-card
        style="padding: 10px"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor,
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor
        }"
      >
        <v-form
          @submit.prevent="addUser"
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <v-toolbar class="secondary elevation-0" dark>
            <v-toolbar-title
              style="color: black"
              data-test-id="create-new-user-label"
            >
              Add
              {{ isUserCustomer ? 'Collaborator' : 'Customer' }}
            </v-toolbar-title>
          </v-toolbar>
          <v-layout v-if="error">
            <v-flex>
              <app-alert
                :text="error.message"
                @dismissed="onDismissed"
              ></app-alert>
            </v-flex>
          </v-layout>
          <v-card-text>
            <v-container grid-list-md pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    :rules="emailRule"
                    label="Email*"
                    required
                    prepend-icon="mdi-email"
                    v-model="userEmail"
                    :data-ci-new-user-email="true"
                    :dark="
                      isUserAdmin && isDarkModeToggleEnabled ? true : false
                    "
                    data-test-id="create-user-email-input"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions class="d-flex justify-space-between">
            <Button
              @onButtonClick="cancelNewUser"
              text
              btnStyle="outlined"
              :disabled="isLoading"
              :data-ci-new-user-close-button="true"
              class="text-subtitle-2 close-btn"
              btnText="Close"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : 'black'
              }"
              data-test-id="create-user-cancel-button"
            />
            <Button
              type="submit"
              color="black--text elevation-0"
              :disabled="!isValid"
              :loading="isLoading"
              :data-ci-new-user-save-button="true"
              class="text-subtitle-2"
              btnText="Add"
              data-test-id="create-user-save-button"
            />
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card v-if="showAddUserDialog"
        ><AddNewUserDialog
          :initialEmail="userEmail"
          :handleCreateUser="handleCreateUser"
          @close="showAddUserDialog = false"
          @save="
            ;(isDialog = false), createForm.reset(), (showAddUserDialog = false)
          "
      /></v-card>
      <v-card v-if="showExistUserDialog"
        ><AddExistUserDialog
          :userDetails="coloboratorDetails"
          :coloboratorDetails="coloboratorDetails"
          :handleCreateUser="handleCreateUser"
          @close="showExistUserDialog = false"
          @save="
            ;(isDialog = false),
              createForm.reset(),
              (showExistUserDialog = false)
          "
        />
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" data-test-id="add-new-user-snackbar-message">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text"
          data-test-id="snackbar-close-button"
        />
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import AddNewUserDialog from './AddNewUserDialog.vue'
import AddExistUserDialog from './AddExistingUserDialog.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button,
    AddNewUserDialog,
    AddExistUserDialog
  }
})
export default class UserEmailDialog extends Vue {
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: '' }) userRole!: string
  @Prop({ required: true }) handleCreateUser!: (payload: {
    firstName: string
    lastName: string
    email: string
    role: string
  }) => Promise<any>

  @Getter('currentUser', namespaceUser) public currentUser!: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled!: any
  @Action('searchUserByEmail', namespaceUser) public searchUserByEmail!: any

  public isValid: boolean = false
  public isDialog: boolean = false
  public isLoading: boolean = false
  public userRoles: string[] = []
  public hasFormReset: boolean = false
  public snackbar: boolean = false
  public snackbarMessage: string = ''
  public userEmail: string = ''
  public isNewUser: boolean = false
  public coloboratorDetails: any = {}
  public showAddUserDialog: boolean = false
  public showExistUserDialog: boolean = false

  public setUserRoles() {
    if (this.userRole === 'Customer') {
      this.userRoles = ['Customer']
    } else if (this.userRole === 'Administrator') {
      this.userRoles = ['Administrator']
    } else {
      this.userRoles = ['Worker']
    }
    return this.userRoles
  }

  public get onUserRoleChange(): string {
    this.userRoles = this.setUserRoles()
    return this.userRole
  }

  get error() {
    return this.$store.getters.error
  }

  public emailRule = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /\S+@\S+\.\S+/.test(v) || 'E-mail must be valid'
  ]
  public roleRule = [(v: string) => !!v || 'The input is required']

  public role = 'Customer'

  public userId: string = ''

  @Ref('form') public createForm!: { validate: () => any; reset: () => void }

  @Watch('$route')
  @Watch('$route.params')
  private async watchParams() {
    this.userId = this.$route.params.userId ?? ''
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }

  mounted(): void {
    this.$store.dispatch('clearError')
    this.hasFormReset = true
    this.role = this.onUserRoleChange
  }

  public async searchEmails() {
    try {
      this.coloboratorDetails = await this.searchUserByEmail({
        email: this.userEmail
      })
      if (this.coloboratorDetails.length > 0) {
        this.isNewUser = false
      } else {
        this.isNewUser = true
      }
    } catch (error) {
      console.error('Error searching users by email:', error)
    }
  }

  public async addUser(): Promise<void> {
    this.watchParams()
    await this.searchEmails()

    if (this.createForm.validate()) {
      this.isLoading = true
      if (this.isNewUser) {
        this.showAddUserDialog = true
      } else {
        if (this.coloboratorDetails[0].role != 'Customer') {
          this.$store.commit('setError', {
            message: 'You can only add customers as collaborators'
          })
        } else if (this.coloboratorDetails[0].id == this.userId) {
          this.$store.commit('setError', {
            message: 'You cannot add yourself as a collaborator'
          })
        } else {
          this.showExistUserDialog = true
        }
      }
      this.isLoading = false
    }
  }

  public onDismissed() {
    this.$store.dispatch('clearError')
  }
  public cancelNewUser() {
    this.isDialog = false
    this.createForm.reset()
    this.$store.dispatch('clearError')
  }
}
</script>

<style scoped>
.add-button {
  margin: 1rem auto;
  text-transform: unset !important;
}

.close-btn:hover {
  background-color: transparent !important;
}
</style>
