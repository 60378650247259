<template>
  <div>
    <v-flex row class="pt-1 ml-1">
      <Header
        :bannerTitle="'Manage Requests List'"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor
        }"
        style="font-size: 1.25rem; padding-top: 18px"
        data-test-id="manage-requests-list-title"
      >
        <template v-slot:headerActionItems>
          <v-flex class="user-management__sticky-header">
            <v-flex row>
              <v-col>
                <v-text-field
                  style="width: 300px; margin-left: auto"
                  append-icon="mdi-magnify"
                  clearable
                  hide-details
                  label="Search"
                  data-test-id="search-request-input"
                  single-line
                  v-model="searchText"
                  :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
                ></v-text-field>
              </v-col>
            </v-flex>
          </v-flex>
        </template>
      </Header>
    </v-flex>
    <v-data-table
      :headers="userHeaders"
      :items="users"
      :search="searchText"
      :footer-props="{
        'items-per-page-options': rowsPerPageItems
      }"
      must-sort
      :items-per-page="10"
      :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
      :style="{
        backgroundColor:
          isUserAdmin && isDarkModeToggleEnabled
            ? getColors.darkPrimaryColor
            : getColors.lightPrimaryColor
      }"
      :loading="isProcessing"
      style="padding: 0 28px"
    >
      <template v-slot:progress>
        <v-progress-linear indeterminate color="secondary"></v-progress-linear>
      </template>
      <template v-slot:item.id="{ item }">
        <Avatar :id="item.id" style="padding-top: 6px"></Avatar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="button-container">
          <Button
            class="text-subtitle-2"
            @onButtonClick="openConfirmDialog(item.id, true)"
            text
            btnText="Approve"
            btnStyle="outlined"
            data-test-id="approve-user-button"
          />
          <Button
            class="reject-hover text-subtitle-2"
            @onButtonClick="openConfirmDialog(item.id, false)"
            text
            btnText="Reject"
            btnStyle="outlined"
            data-test-id="reject-user-button"
            color="#ff3333"
            :style="{
              borderColor: '#ff3333'
            }"
          />
        </div>
      </template>

      <template v-if="isLoading" slot="no-data">
        <tr class="loader-wrapper">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </tr>
      </template>
      <template v-else slot="no-data">
        <tr class="data-table__info-box">
          <td
            colspan="99"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : ''
            }"
          >
            No data available
          </td>
        </tr>
      </template>
      <template slot="no-results">
        <tr class="data-table__info-box">
          <td
            colspan="99"
            class="gt-table--center"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : ''
            }"
            data-test-id="search-no-results-message"
          >
            Your search for "{{ searchText }}" returned no results.
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="confirmDialog" persistent max-width="500">
      <v-card
        style="padding: 10px"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor
        }"
      >
        <v-toolbar class="secondary elevation-0" dark>
          <v-toolbar-title
            style="color: black"
            data-ci-user-management-deletion-confirm-header="true"
          >
            Confirm {{ approveDialog ? 'Approve' : 'Reject' }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          class="pt-5"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          Are you sure you want to
          {{ approveDialog ? 'approve' : 'reject' }} this user?
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <Button
            class="text-subtitle-2"
            @onButtonClick="
              confirmDialog = false
              approveDialog = false
            "
            text
            btnText="Cancel"
            btnStyle="outlined"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          />
          <Button
            :loading="isActionProcessing"
            @onButtonClick="approveUser(selectedUserId)"
            elevation="0"
            v-if="approveDialog"
            class="text-subtitle-2"
            btnText="Approve"
          />
          <Button
            :loading="isActionProcessing"
            @onButtonClick="rejectUser(selectedUserId)"
            elevation="0"
            v-if="!approveDialog"
            class="text-subtitle-2"
            btnText="Reject"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text"
        />
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Header from '@/components/Header.vue'
import NewUser from '@/components/user/NewUser.vue'
import Avatar from '@/components/user/Avatar.vue'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Header,
    NewUser,
    Avatar,
    Button
  }
})
export default class RequestTable extends Vue {
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: [] }) headers: any[]
  @Prop({ default: [] }) items: null
  @Prop({ default: null })
  configLevel: 'Admin' | 'Client' | 'Site' | 'Camera'

  @Prop({ default: false }) public showExtraFooterWithNoData!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('isLoadingUsers', namespaceUser) public isLoadingUsers: any
  @Getter('loading') public loading: any

  @Action('deleteUser', namespaceUser)
  private deleteUser: (payload: {
    userIds: string[]
    clientId?: string
    siteId?: string
    cameraId?: string
  }) => Promise<any>

  public rowsPerPageItems: number[] = [5, 10, 25, 50]
  public isActionProcessing: boolean = false

  public userId: string = ''
  public siteId: string = ''
  public cameraId: string = ''
  public clientId: string = ''

  public searchText: string = ''

  public isLoading = false
  public isProcessing = false
  public users = []
  public confirmDialog = false
  public approveDialog = false
  public actionType: string = ''
  public selectedUserId = null
  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get userHeaders() {
    return this.headers.filter((header) => {
      if (header.hiddenLevels) {
        return !header.hiddenLevels.includes(this.configLevel)
      }
      return true
    })
  }
  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  public async approveUser(userId: string) {
    this.isActionProcessing = true
    try {
      await this.$store.dispatch('user/approveUser', userId)
      this.popSnackbarMessage('User has been approved successfully')

      const userDetails = await this.$store.dispatch(
        'user/getUserByDocId',
        userId
      )
      if (userDetails && userDetails.email) {
        const baseUrl = `${window.location.origin}`
        const loginInUrl = `${baseUrl}/login`
        const emailSubject = 'Approval Notification - Welcome to promiseQ'
        const emailBody = `
                <p>Hello,</p>
                <p>You have been approved to the promiseQ video intelligence platform.</p>
                <p>Use the link below to sign in and start</p>
                <a href="${loginInUrl}">Sign in to promiseQ</a>
                <p>If you encounter any issues, please contact our support team.</p>
                <p>Best Regards,</p>
                <p>The promiseQ Team</p>
            `
        await this.$store.dispatch('user/sendEmail', {
          useremail: userDetails.email,
          subject: emailSubject,
          html: emailBody,
          attachments: ''
        })
      }
    } catch (error) {
      console.error('Error approving user or sending email:', error)
    } finally {
      this.isActionProcessing = false
      this.confirmDialog = false
      this.approveDialog = false
    }
  }

  public async rejectUser(userId: string) {
    this.isActionProcessing = true
    const payload: any = {
      userIds: [userId]
    }
    try {
      await this.deleteUser(payload)
      this.popSnackbarMessage('User has been rejected successfully')
    } catch (error) {
      console.error('Error rejecting user:', error)
    } finally {
      this.isActionProcessing = false
      this.confirmDialog = false
      this.approveDialog = false
    }
  }

  public mounted() {
    this.isLoading = true
    this.watchParams()
    this.watchItems()
  }

  @Watch('isLoadingUsers')
  watchIsLoadingUsers() {
    this.isLoading = this.isLoadingUsers
  }

  @Watch('$route')
  @Watch('$route.params')
  public watchParams() {
    this.isLoading = true
    this.userId = this.$route.params.userId ?? ''
    this.clientId = this.$route.params.clientId ?? ''
    this.siteId = this.$route.params.siteId ?? ''
    this.cameraId = this.$route.params.cameraId ?? ''
  }

  @Watch('items')
  public watchItems() {
    this.isLoading = true
    if (this.items) {
      this.users = this.items
      this.isLoading = false
    } else {
      this.users = []
      this.isLoading = false
    }
  }

  public openConfirmDialog(userId, approveDialog: boolean) {
    this.selectedUserId = userId
    this.confirmDialog = true
    if (approveDialog) {
      this.approveDialog = true
    }
  }
}
</script>

<style lang="stylus" scoped>
hagl-gray = #FFFDFD;

@media screen and (max-width: 400px) {
  .v-text-field {
    width: 200px;
  }
  .button {
    font-size: 12px;
  }
}
.v-application .elevation-1 {
  box-shadow: none
}

.gt-data-table div {
  display: flex;
  flex-direction: column-reverse;
}

.data-table__info-box {
  text-align: center;
  height: 100px;
}

.loader-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}

.button-container{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.reject-hover:hover {
  color: #FFFFFF !important;
  background-color: #C62828 !important;
}
.data-table >>> {
  & .v-text-field__details {
    display: none;
  }

  & .theme--dark.v-table {
    background-color: transparent;
  }

  & .gt-table--center {
    text-align: center;
  }

  & th {
    background-color: #f2f2f3;
  }

  & .v-input__slot {
    margin-bottom: 0;
  }

  & .v-datatable__actions {
    background-color: hagl-gray !important;
    border: none !important;
    border-radius: 1rem !important;
  }

  & table.v-table {
    border-collapse: separate;
    border-spacing: 0 3px;
    background-color: transparent;

    & .v-datatable__progress {
      display: none;
    }
  }

  & .v-table__overflow table tr {
    background-color: hagl-gray;

    &.already-in-use {
      & td, & th {
        text-decoration: line-through;
      }
    }
  }

  & td, & th {
    &:first-of-type {
      text-decoration: none;
    }
  }

  & .theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background-color: #FFFDFD;
  }
  .approveBtn{
    color: black;
    border-radius: 20px;
    background: #FFD42A;
    padding: 5px 16px;
   }
}
</style>
