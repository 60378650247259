<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      @click:outside="closeDialog()"
      max-width="800px"
    >
      <v-card>
        <v-container
          v-if="rows.length > 1"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkPrimaryColor
                : getColors.lightPrimaryColor
          }"
        >
          <v-card-title
            class="justify-center font-weight-bold"
            style="font-size: 18px"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlackColor
            }"
          >
            {{ cardTitle }}
          </v-card-title>
          <v-card-text v-if="!selectedLink">
            <v-container
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor
              }"
            >
              <v-row
                v-for="(row, index) in rows"
                :key="index"
                class="text-start dialogItem"
                style="font-size: 16px; padding: 10px 0"
                @click="openLink(row)"
              >
                <v-col>
                  <v-icon medium :class="row.class">{{ row.icon }}</v-icon>
                  <span>{{ row.text }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="selectedLink">
            <iframe
              :src="selectedLink"
              frameborder="0"
              width="100%"
              height="600"
            ></iframe>
          </v-card-text>
        </v-container>

        <v-container
          v-if="rows.length === 1"
          backgroundColor:string="red"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkPrimaryColor
                : getColors.lightPrimaryColor
          }"
        >
          <v-card-title
            class="justify-center font-weight-bold"
            style="font-size: 18px"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlackColor,
              padding: '8px'
            }"
          >
            {{ selectedText }}
          </v-card-title>
          <v-card-text style="padding: 0">
            <iframe
              :src="selectedLink"
              frameborder="0"
              width="100%"
              height="560"
            ></iframe>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <v-icon class="info-icon" color="white" dense @click.native="dialogViewer">
      mdi-help
    </v-icon>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

type RowObject = {
  class: string
  icon: string
  text: string
  link: string
}

@Component
export default class InfoButton extends Vue {
  @Prop() public rows!: RowObject[]

  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser)
  public getColors!: any

  public showDialog: boolean = false
  public selectedLink: string = ''
  public cardTitle: string = 'Learn more in the web app handbook'
  public initialRows: RowObject[] = []
  public selectedText: string = ''

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  mounted() {
    this.initialRows = [...this.rows]
  }
  // Logic implemented for showing multiple or single info items.
  // If there is only one item, it will be shown directly in the dialog.
  // If there are multiple items, they will be shown in a list and the user can select one.
  // Used only here for single item: If want to show multiple items, just add to the array in the parent component.

  dialogViewer() {
    this.showDialog = true
    if (this.rows.length === 1) {
      this.selectedLink = this.rows[0].link
      this.selectedText = this.rows[0].text
      this.cardTitle = this.rows[0].text
    }
  }

  openLink(row: any) {
    this.selectedLink = row.link
    this.selectedText = row.text
    this.cardTitle = row.text
  }

  closeDialog() {
    this.showDialog = false
    this.selectedLink = ''
    this.cardTitle = 'Learn more in the web app handbook'
  }
}
</script>

<style scoped>
* {
  text-decoration: none;
}
.info-icon {
  position: fixed;
  bottom: 45px;
  right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgb(93, 176, 252);
  transition: background-color 0.2s ease-in-out;
  z-index: 11;
}

.info-icon:hover,
.info-icon--active {
  background-color: rgb(0, 118, 222);
}
.dialogItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
