<template>
  <div
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <Header route="live" />
    <div v-if="cameras && cameras.length === 0">
      <NoCamera nodeType="site" />
    </div>

    <v-container fluid class="bg-surface-variant">
      <v-row no-gutters v-if="cameras && cameras.length !== 0">
        <!-- Empty v-col to take up remaining space and push v-select to the right -->
        <v-col cols="10"></v-col>

        <!-- v-col wrapping the v-select, aligned to the right -->
        <v-col cols="2" class="text-end">
          <v-select
            v-model="numberOfCameras"
            :items="[1, 2, 3, 4]"
            label="Layout per row"
            :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
          ></v-select>
        </v-col>
      </v-row>
      <div class="loading" v-if="isLoading">
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor
          }"
        >
          Loading Camera List...
        </p>
        <v-progress-circular indeterminate color="amber"></v-progress-circular>
      </div>

      <v-row>
        <v-col
          v-for="camera in cameras"
          :key="camera.id"
          style="color: black"
          :style="{
            color: 'black'
          }"
          cols="12"
          :sm="12 / numberOfCameras"
        >
          <LiveFrame
            :camera="camera"
            :numberOfItems="numberOfCameras"
            :level="'site'"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import NoCamera from '../../../components/error-pages/NoCamera.vue'
import LiveFrame from './LiveFrame.vue'

const namespaceUser = { namespace: 'user' }
const namespaceSite = { namespace: 'site' }
const namespaceCamera = { namespace: 'camera' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    NoCamera,
    LiveFrame
  }
})
export default class LiveViewSite extends Vue {
  @Prop({
    default: ''
  })
  siteId: string
  @Prop({
    default: false
  })
  isSiteHardwareDevice: boolean

  @Action('bindRtspStream', namespaceCamera) public bindRtspStream
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getSiteCameras', namespaceSite) public siteCameras
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public numberOfCameras: number = 3
  public isLoading: boolean = true
  public cameras: any[] | null = null

  public get isUserAdmin() {
    return this.currentUser.role === 'Administrator'
  }

  public mounted() {
    this.isLoading = true
    this.watchSiteCameras()
  }

  public beforeDestroy() {
    this.cameras = null
    this.isLoading = true
  }

  @Watch('siteCameras')
  private async watchSiteCameras() {
    if (this.siteCameras) {
      // Create a new array with sorted siteCameras
      const sortedSiteCameras = [...this.siteCameras].sort((a, b) => {
        const aUnarmed = a.isEdgeCameraUnarmed
        const bUnarmed = b.isEdgeCameraUnarmed

        if (aUnarmed === false) return -1
        if (bUnarmed === false) return 1
        if (aUnarmed === undefined && bUnarmed !== undefined) return -1
        if (aUnarmed !== undefined && bUnarmed === undefined) return 1
        if (aUnarmed === true) return 1
        if (bUnarmed === true) return -1
        return 0
      })

      const isDeviceAuthenticatedAndEnabled = (item) => {
        return (
          item.isAuthenticated !== false && item.isEdgeDeviceEnabled === true
        )
      }

      let cameraCount = 0
      this.cameras = sortedSiteCameras.filter((item) => {
        if (this.isSiteHardwareDevice) {
          const edgeLiveStreamLimit = this.siteCameras[0]?.edgeLiveStreamLimit
          if (edgeLiveStreamLimit > 0) {
            if (!item.isEdgeCameraUnarmed) {
              if (
                edgeLiveStreamLimit > cameraCount &&
                isDeviceAuthenticatedAndEnabled(item)
              ) {
                cameraCount++
                return isDeviceAuthenticatedAndEnabled(item)
              }
            } else {
              if (
                edgeLiveStreamLimit > cameraCount &&
                isDeviceAuthenticatedAndEnabled(item)
              ) {
                cameraCount++
                return isDeviceAuthenticatedAndEnabled(item)
              }
            }
          } else {
            return isDeviceAuthenticatedAndEnabled(item)
          }
        } else {
          return item.isLiveStreamEnabled === true
        }
      })
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.loading {
  color: black;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.alert {
  padding: 20px;
  background-color: #ff5252;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.camera-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(242, 242, 242);
  padding: 10px;
}

.camera-name {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  cursor: pointer;
}
</style>
