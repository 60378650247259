<template>
  <div class="camera">
    <div
      v-if="!isErrorFound && isAuthenticated === true"
      style="
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <video
        :id="videoElementId"
        autoplay
        muted
        loop
        playsinline
        :style="{
          width: '100%',
          height: '100%',
          display: isLoading ? 'none' : 'block'
        }"
      ></video>
      <div>
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="amber"
        ></v-progress-circular>
        <div
          v-if="isLoading"
          :style="{
            color: isUserAdmin && isDarkModeToggleEnabled ? 'white' : 'black'
          }"
        >
          <div>Loading...</div>
          <div>{{ statusMessage }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="isErrorFound"
      :style="{
        color: isUserAdmin && isDarkModeToggleEnabled ? 'white' : 'black'
      }"
    >
      <v-icon
        :style="{
          color: isUserAdmin && isDarkModeToggleEnabled ? 'white' : 'black'
        }"
        >mdi-information</v-icon
      >
      {{ statusMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import Button from '@/components/app/Button.vue'
import { Action, Getter } from 'vuex-class'
import {
  SIGNALING_SERVER,
  MAX_INITIAL_SIGNALING_SERVER_RESTART_ATTEMPTS,
  MAX_ONGOING_SIGNALING_SERVER_RESTART_ATTEMPTS,
  ICE_SERVERS,
  WS_HELLO,
  WS_OFFER_REQUEST,
  WS_SESSION_OK,
  LiveStreamQualityType,
  WebSocketCloseEventCode
} from '@/utils/Constants'

const namespaceCamera = { namespace: 'camera' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class WebRtcPlayer extends Vue {
  @Prop({ required: false }) cameraId!: any
  @Prop({ required: false, default: true }) isAuthenticated!: any
  @Prop({ required: false, default: LiveStreamQualityType.HIGH })
  liveStreamQuality!: LiveStreamQualityType
  @Action('updateCameraSessionId', namespaceCamera)
  public updateCameraSessionId
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public peerConnection: any = null
  public uuid: string = ''
  public serverConnection: any = null
  public localVideo: any = null
  public remoteVideo: any = null
  public callCreateTriggered: boolean = false
  public makingOffer: boolean = false
  public isSettingRemoteAnswerPending: boolean = false
  public isLoading: boolean = true
  public isErrorFound: boolean = false
  public politeOffer: boolean = true
  public serverRestartAttempts = MAX_INITIAL_SIGNALING_SERVER_RESTART_ATTEMPTS
  public restartTimeoutRef = null
  public restartTimeoutDelayInSeconds = 15
  public statusMessage: string = ''

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  peerConnectionConfig: RTCConfiguration = {
    // iceTransportPolicy: 'relay', //TODO: Can be set when TURN is stable
    iceServers: ICE_SERVERS
  }

  public get videoElementId() {
    return `web-rtc-video-${this.cameraId}`
  }

  public mounted() {
    if (this.isAuthenticated) {
      this.initiateConnection()
    }
  }

  @Watch('isAuthenticated')
  public watchIsAuthenticated() {
    if (this.isAuthenticated) {
      this.initiateConnection()
    }
  }

  public handleOnVideoPlay() {
    if (this.isLoading) {
      this.isLoading = false
      this.statusMessage = ''
    }
  }

  public beforeDestroy() {
    this.serverConnection.close()
    this.peerConnection.close()
    this.remoteVideo.removeEventListener('playing', this.handleOnVideoPlay)
  }

  public async initiateConnection() {
    this.isErrorFound = false
    console.log('Establishing RTC Connection!')
    this.statusMessage = 'Establishing RTC Connection!'

    this.peerConnection = new RTCPeerConnection(this.peerConnectionConfig)
    this.uuid = this.createUUID()
    if (this.cameraId !== '') {
      this.updateCameraSessionId({
        cameraId: this.cameraId,
        sessoinId: this.uuid,
        liveStreamQuality: this.liveStreamQuality
      })
    }

    this.remoteVideo = document.getElementById(this.videoElementId)

    const constraints = {
      video: true,
      audio: false
    }

    this.serverConnection = new WebSocket(SIGNALING_SERVER)
    this.serverConnection.addEventListener('open', (event) => {
      console.log('Opening signal server connection')
      this.statusMessage = 'Opening signal server connection!'
      this.serverConnection.send(`${WS_HELLO} ${this.uuid}`)

      // restart timeout on hanging connections
      this.restartTimeoutRef = setTimeout(() => {
        this.restartConnection()
      }, this.restartTimeoutDelayInSeconds * 1000)
    })
    this.serverConnection.addEventListener('error', this.onServerError)
    this.serverConnection.addEventListener('message', this.onServerMessage)
    this.serverConnection.addEventListener('close', this.onServerClose)
  }

  public async onServerError(event) {
    console.log('Server connection error')
  }

  public async onServerClose(event) {
    console.log('Closing signalling server connection', event.code)

    if (this.peerConnection) {
      this.peerConnection.close()
      if (this.restartTimeoutRef) clearTimeout(this.restartTimeoutRef)
    }

    if (event.code == WebSocketCloseEventCode.CLOSED_FOR_RESTART) {
      this.initiateConnection()
    } else if (event.code == WebSocketCloseEventCode.ABNORMAL_CLOSURE) {
      this.restartConnection(true)
    } else if (event.code == WebSocketCloseEventCode.NORMAL_CLOSURE) {
      this.isLoading = true
      this.statusMessage = 'Waiting for the remote to be back online!'
    } else if (event.code == WebSocketCloseEventCode.INTERNAL_ERROR) {
      this.isLoading = true
      this.restartConnection(true)
    } else {
      this.isLoading = true
      this.statusMessage = 'Unexpected error!'
    }
    this.callCreateTriggered = false
  }

  public async onServerMessage(event) {
    switch (event.data) {
      case WS_HELLO:
        return
      case WS_SESSION_OK:
        if (!this.callCreateTriggered) {
          this.createCall()
        }
        return
      case WS_OFFER_REQUEST:
        // The peer wants us to set up and then send an offer
        if (!this.callCreateTriggered) this.createCall()
        return
      default:
        if (event.data.startsWith('ERROR')) {
          this.isLoading = true
          this.statusMessage = 'Unexpected error!'
          console.log('Unexpected error!', event.data)
          return
        }
        // Handle incoming JSON SDP and ICE messages
        let msg
        try {
          msg = JSON.parse(event.data)
        } catch (e) {
          this.isLoading = true
          this.statusMessage = 'Unexpected error!'
          console.log('Unexpected error!', e)
          return
        }

        // Incoming JSON signals the beginning of a call
        if (!this.callCreateTriggered) this.createCall()

        if (msg.sdp != null) {
          this.onIncomingSDP(msg.sdp)
        } else if (msg.ice != null) {
          this.onIncomingICE(msg.ice)
        } else {
          this.isLoading = true
          this.statusMessage = 'Unexpected error!'
          console.log('Unexpected error!', msg)
        }
    }
  }

  public async createCall() {
    if (!this.peerConnection) return
    this.callCreateTriggered = true
    const sendChannel = this.peerConnection.createDataChannel('label', null)
    sendChannel.onopen = (event) => {
      console.log('dataChannel.OnOpen', event)
    }
    sendChannel.onmessage = (event) => {
      sendChannel.send('Hi! (from browser)')
    }
    sendChannel.onclose = (event) => {
      console.log('dataChannel.OnClose', event)
    }
    this.peerConnection.ondatachannel = (event) => {
      let receiveChannel = event.channel
      receiveChannel.onopen = (event) => {
        console.log('dataChannel.OnOpen', event)
      }
      receiveChannel.onmessage = (event) => {
        console.log('dataChannel.OnMessage:', event, event.data.type)

        sendChannel.send('Hi! (from browser)')
      }
      receiveChannel.onclose = (event) => {
        console.log('dataChannel.OnClose', event)
        this.restartConnection()
      }
    }

    this.peerConnection.ontrack = async ({ track, streams }) => {
      this.isErrorFound = false
      this.statusMessage = 'Stream received!'
      this.remoteVideo = document.getElementById(this.videoElementId)
      this.remoteVideo.srcObject = streams[0]
      await this.remoteVideo.play()
    }

    this.peerConnection.onicecandidate = (event) => {
      // We have a candidate, send it to the remote party with the
      this.statusMessage = 'ICE candidate sharing!'
      if (event.candidate == null) {
        console.log('ICE Candidate was null, done')
        return
      }
      this.serverConnection.send(JSON.stringify({ ice: event.candidate }))
    }

    this.peerConnection.oniceconnectionstatechange = (event) => {
      console.log(
        'oniceconnectionstatechange',
        this.peerConnection.iceConnectionState
      )
      if (this.peerConnection.iceConnectionState == 'connected') {
        console.log('ICE gathering complete')
        this.statusMessage = 'ICE gathering complete!'
        clearTimeout(this.restartTimeoutRef) // clear restarting process if connection established
        this.serverRestartAttempts =
          MAX_ONGOING_SIGNALING_SERVER_RESTART_ATTEMPTS
      }
      if (this.peerConnection.iceConnectionState == 'failed') {
        console.log('ICE gathering Error!')
        this.restartConnection()
      }
      if (this.peerConnection.iceConnectionState == 'disconnected') {
        this.statusMessage =
          'Disconnected from the remote peer. Awaiting for reconnection!'
        this.serverRestartAttempts =
          MAX_ONGOING_SIGNALING_SERVER_RESTART_ATTEMPTS
        this.restartConnection(true)
      }
    }

    // let the "negotiationneeded" event trigger offer generation
    this.peerConnection.onnegotiationneeded = async () => {}

    this.remoteVideo.addEventListener('playing', this.handleOnVideoPlay)
  }

  public async onIncomingSDP(sdp) {
    try {
      // An offer may come in while we are busy processing SRD(answer).
      // In this case, we will be in "stable" by the time the offer is processed
      // so it is safe to chain it on our Operations Chain now.
      this.statusMessage = 'Handling the session description protocol!'
      const readyForOffer =
        !this.makingOffer &&
        (this.peerConnection.signalingState == 'stable' ||
          this.isSettingRemoteAnswerPending)
      const offerCollision =
        !this.politeOffer && sdp.type == 'offer' && !readyForOffer

      if (offerCollision) {
        return
      }
      this.isSettingRemoteAnswerPending = sdp.type == 'answer'
      this.peerConnection.setRemoteDescription(sdp).then(() => {
        this.isSettingRemoteAnswerPending = false
        if (sdp.type == 'offer') {
          this.peerConnection.setLocalDescription().then(() => {
            let desc = this.peerConnection.localDescription
            console.log('Got local description: ' + JSON.stringify(desc))
            this.serverConnection.send(JSON.stringify({ sdp: desc }))
            if (this.peerConnection.iceConnectionState == 'connected') {
              console.log(
                'SDP ' + desc.type + ' sent, ICE connected, all looks OK'
              )
            }
          })
        }
      })
    } catch (err) {
      this.isErrorFound = true
    }
  }

  public async onIncomingICE(ice) {
    const candidate = new RTCIceCandidate(ice)
    this.peerConnection.addIceCandidate(candidate).catch(this.setError)
  }

  public setError(text) {
    console.error(text)
  }

  public async restartConnection(hardRestart = false) {
    if (this.serverRestartAttempts <= 0) {
      this.statusMessage = 'The live stream is no longer available!'
      this.isLoading = false
      this.isErrorFound = true
      return
    }

    this.statusMessage = 'Restarting RTC Connection!'

    this.isLoading = true
    --this.serverRestartAttempts

    this.statusMessage = `Attempting to reconnect!`

    if (hardRestart) {
      await this.sleep(5000) // sleep in-between retries
      this.initiateConnection()
      return
    }

    // restart the ice candidates
    this.peerConnection.restartIce()

    // close and auto-restart connection
    this.serverConnection.close(WebSocketCloseEventCode.CLOSED_FOR_RESTART)
  }

  sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  public createUUID(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}.${
      this.cameraId
    }`
  }
}
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* add a semi-transparent black background */
}
.overlay i {
  font-size: 5rem;
  color: white;
}
.offline {
  filter: grayscale(100%); /* apply a gray filter to the image */
}

.camera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.unauthenticated-overlay {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
