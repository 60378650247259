var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{staticClass:"px-1 py-1",style:({
    backgroundColor:
      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#333333' : '#f5f5f5',
    border:
      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '1px solid #C4C4C4' : 'none'
  })},[_c('div',{staticClass:"grid-item-wrapper",style:({ height: _vm.gridHeight })},[_c('LiveStreamPlayer',{attrs:{"cameraConfig":_vm.camera,"cameraId":_vm.camera.id,"isSiteHardwareDevice":_vm.camera.isSiteHardwareDevice,"liveStreamQuality":_vm.liveStreamQuality}})],1),_c('p',{staticClass:"mt-1 mb-0",staticStyle:{"text-align":"left","font-weight":"600","font-size":"13px"},style:({
      color:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.lightPrimaryColor
          : _vm.getColors.darkPrimaryColor
    })},[_vm._v(" "+_vm._s(_vm.breadcrumb)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }