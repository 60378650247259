import { render, staticRenderFns } from "./EmailDialog.vue?vue&type=template&id=7453fa81&scoped=true"
import script from "./EmailDialog.vue?vue&type=script&lang=ts"
export * from "./EmailDialog.vue?vue&type=script&lang=ts"
import style0 from "./EmailDialog.vue?vue&type=style&index=0&id=7453fa81&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7453fa81",
  null
  
)

export default component.exports