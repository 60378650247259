<template>
  <div
    class="live-view"
    v-bind:class="{
      dark: isDarkMode
    }"
  >
    <AntServerPlayer
      v-if="getStreamType === STREAM_TYPES.AntStream"
      :cameraConfig="cameraConfig"
      :cameraId="cameraId"
    />
    <WebRtcPlayer
      v-if="getStreamType === STREAM_TYPES.HardwareStream"
      :cameraId="cameraId"
      :isAuthenticated="cameraConfig.isAuthenticated"
      :liveStreamQuality="liveStreamQuality"
    />

    <EagleEyePlayer
      v-if="getStreamType === STREAM_TYPES.EagleEyeStream"
      :cameraId="cameraId"
      :cameraConfig="cameraConfig"
    />

    <HeitelStreamPlayer
      v-if="getStreamType === STREAM_TYPES.HeitelStream"
      :cameraId="cameraId"
      :cameraConfig="cameraConfig"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import AntServerPlayer from './AntServerPlayer.vue'
import WebRtcPlayer from './WebRtcPlayer.vue'
import EagleEyePlayer from './EagleEyePlayer.vue'
import HeitelStreamPlayer from './HeitelStreamPlayer.vue'
import { LiveStreamQualityType } from '@/utils/Constants'

@Component({
  components: {
    AntServerPlayer,
    WebRtcPlayer,
    EagleEyePlayer,
    HeitelStreamPlayer
  }
})
export default class LiveStreamPlayer extends Vue {
  @Prop({ required: true }) public cameraId: string
  @Prop({ required: true }) public cameraConfig: any
  @Prop({ required: false, default: false }) public isSiteHardwareDevice
  @Prop({ required: false, default: false }) public isDarkMode
  @Prop({ required: false, default: LiveStreamQualityType.HIGH })
  public liveStreamQuality

  public STREAM_TYPES = {
    AntStream: 0,
    EagleEyeStream: 1,
    HardwareStream: 2,
    HeitelStream: 3
  }

  public get getStreamType() {
    if (this.isSiteHardwareDevice) {
      return this.STREAM_TYPES.HardwareStream
    } else {
      const streamUrl = this.cameraConfig?.liveStream?.url
      const streamType = this.cameraConfig?.liveStream?.type
      if (streamType === 'Heitel') {
        return this.STREAM_TYPES.HeitelStream
      } else {
        if (
          streamUrl.includes('eagleeyenetworks') ||
          this.cameraConfig.integrationType === 'EagleEye'
        ) {
          return this.STREAM_TYPES.EagleEyeStream
        } else {
          return this.STREAM_TYPES.AntStream
        }
      }
    }
  }
}
</script>

<style scoped>
.live-view {
  width: 100%;
  height: 100%;
  flex: 1;
}

.live-view.dark {
  background: #333333;
}
</style>
