<template>
  <div>
    <v-dialog max-width="600px" text-xs-right v-model="isDialog" persistent>
      <v-card
        style="padding: 10px"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor,
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor
        }"
      >
        <v-form
          @submit.prevent="saveUser"
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <v-toolbar class="secondary elevation-0" dark>
            <v-toolbar-title
              style="color: black"
              data-test-id="create-new-user-label"
            >
              Do you want to add this
              {{ isUserCustomer ? 'collaborator' : 'customer' }}?
            </v-toolbar-title>
          </v-toolbar>
          <v-layout v-if="error">
            <v-flex>
              <app-alert
                :text="error.message"
                @dismissed="onDismissed"
              ></app-alert>
            </v-flex>
          </v-layout>

          <v-flex>
            <v-layout align-center justify-center>
              <v-flex class="ma-2 pa-2">
                <Avatar :width="'80px'" :id="id"></Avatar>
              </v-flex>
              <v-flex>
                <v-layout justify-space-between
                  ><v-flex>
                    <v-card-text class="text-h6 text-left pa-0">
                      {{ firstName }} {{ lastName }}
                    </v-card-text>
                    <v-card-text class="text-body-2 text-left pa-0">
                      {{ email }}
                    </v-card-text>
                  </v-flex></v-layout
                >
              </v-flex>
            </v-layout>
          </v-flex>
          <v-card-actions class="d-flex justify-space-between">
            <Button
              @onButtonClick="cancelNewUser"
              text
              btnStyle="outlined"
              :disabled="isLoading"
              :data-ci-new-user-close-button="true"
              class="text-subtitle-2 close-btn"
              btnText="Close"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : 'black'
              }"
              data-test-id="create-user-cancel-button"
            />
            <Button
              type="submit"
              color="black--text elevation-0"
              :disabled="!isValid"
              :loading="isLoading"
              :data-ci-new-user-save-button="true"
              class="text-subtitle-2"
              btnText="Add"
              data-test-id="create-user-save-button"
            />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import Avatar from '../Avatar.vue'
import { generateEmailContent } from '../../../utils/emailTemplateUtils'
import { mailCollection } from '@/provider/firebase/index'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button,
    Avatar
  }
})
export default class AddExistUserDialog extends Vue {
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: '' }) userRole!: string
  @Prop({ default: '' }) userDetails: any
  @Prop({ required: true }) handleCreateUser: (payload: {
    firstName: string
    lastName: string
    email: string
    role: string
  }) => Promise<any>

  @Getter('currentUser', namespaceUser)
  public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getBreadcrumbList', namespaceConfig) public breadcrumbList

  public isValid: boolean = false
  public isDialog: boolean = true
  public isLoading: boolean = false
  public userRoles = []
  public hasFormReset: boolean = false
  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public setUserRoles() {
    if (this.userRole === 'Customer') {
      this.userRoles = ['Customer']
    } else if (this.userRole === 'Administrator') {
      this.userRoles = ['Administrator']
    } else {
      this.userRoles = ['Worker']
    }
    return this.userRoles
  }

  public get onUserRoleChange(): string {
    this.userRoles = this.setUserRoles()
    return this.userRole
  }

  get error() {
    return this.$store.getters.error
  }

  public roleRule = [(v) => !!v || 'The input is required']

  public firstName = ''
  public lastName = ''
  public email = ''
  public id = ''
  public role = 'Customer'

  @Ref('form') public createForm: { validate: () => any; reset: () => void }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }
  get getBreadcrumbList() {
    let adjustedBreadcrumbList = [...this.breadcrumbList]

    if (this.isUserAdmin) {
      // Remove the first item from the list if the user is an admin
      adjustedBreadcrumbList.shift()
    }

    return adjustedBreadcrumbList.map((item) => item.text).join(' > ')
  }
  //generate path for the emails
  get fullPathUrl() {
    const host = process.env.VUE_APP_AUTH_DOMAIN
    const baseUrl = `https://${host}`
    let path = this.$route.path.replace(/^\/+/, '')

    //replace the current user id with added user id/'
    path = path.replace(/(user-management\/)[^/]+/, `$1${this.id}`)

    return `${baseUrl}/${path}`
  }
  mounted(): void {
    this.$store.dispatch('clearError')
    this.hasFormReset = true
    this.role = this.onUserRoleChange
    this.email = this.userDetails[0].email
    this.id = this.userDetails[0].id
    this.firstName = this.userDetails[0].firstName
    this.lastName = this.userDetails[0].lastName
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  public saveUser(): void {
    if (this.createForm.validate()) {
      this.isLoading = true

      this.handleCreateUser({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        role: 'Customer'
      })
        .then(() => {
          this.isLoading = false
          if (!this.error) {
            this.$emit('save')
            this.isDialog = false
          }
          if (!this.error) {
            this.sendExistingUserEmail(
              this.email,
              this.getBreadcrumbList,
              this.fullPathUrl
            )
            this.popSnackbarMessage('User added successfully')
          }
          this.createForm.reset()
          this.hasFormReset = true
        })
        .catch((error) => {
          this.isLoading = false
          this.popSnackbarMessage('Unable to add user')
        })
    }
  }

  public onDismissed() {
    this.$store.dispatch('clearError')
  }
  public cancelNewUser() {
    this.$emit('close')
    this.isDialog = false
    this.createForm.reset()
    this.$store.dispatch('clearError')
  }
  async sendExistingUserEmail(
    email: string,
    breadcrumbList: string,
    path: string
  ) {
    try {
      const emailContent = await generateEmailContent(
        'addExistingCollaborator',
        {
          firstName: this.firstName,
          lastName: this.lastName,
          breadcrumbList,
          path
        }
      )
      const payload = {
        to: email,
        message: {
          subject: emailContent.subject,
          html: emailContent.html
        }
      }
      // Add the email payload to the Firestore collection used by the Firebase Extension
      await mailCollection.add(payload)

      return { status: 'success', message: 'email sent' }
    } catch (error: any) {
      console.error('Error sending email:', error)
    }
  }
}
</script>

<style scoped>
.close-btn:hover {
  background-color: transparent !important;
}
</style>
