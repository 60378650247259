import moment from 'moment'

export const getDefaultState = () => {
  return {
    config: {
      acceptedSubscriptionKeys: []
    },
    clientType: 'global',
    globalTimeRange: {
      startDateTime: moment().subtract(14, 'd'),
      endDateTime: moment()
    },
    isNavigationUpdated: false,
    isAlarmRefreshEnabled: false,
    autoRefreshSettings: {
      value: 1,
      unit: 'min',
      autoRefreshPeriod: 1000 * 60
    },
    serviceStatus: {
      functions: false
    },
    breadcrumbList: [],
    selectedTreeNode: [],
    isTreeViewLoading: false,
    showPendingIncidents: false,
    treeCustomers: [],
    treeClients: [],
    treeSites: [],
    treeCameras: [],
    isDarkModeToggleEnabled: true,
    isNotFound: false,
    isToggleMenuEnabled: false,
    webAppVersion: null,
    promiseQubeVersion: null
  }
}

const state = getDefaultState()

export default {
  ...state
}
