<template>
  <div>
    <v-dialog v-model="active" width="600" persistent>
      <v-card
        style="padding: 20px"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor
        }"
      >
        <div>
          <h3
            class="mb-5"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          >
            Add a new {{ nodeType }}
          </h3>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="nodeName"
                outlined
                :label="`${capitalize(nodeType)} Name`"
                :dark="isUserAdmin && isDarkModeToggleEnabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nodeId"
                outlined
                :label="`${capitalize(nodeType)} ID (optional)`"
                :dark="isUserAdmin && isDarkModeToggleEnabled"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-progress-circular
            v-if="isSaving"
            indeterminate
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.lightTreeItemsColor
            }"
          ></v-progress-circular>
          <v-card-actions class="d-flex justify-space-between">
            <Button
              @onButtonClick="closeDialog"
              text
              btnText="Cancel"
              btnStyle="outlined"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            />
            <Button
              @onButtonClick="addNode"
              :style="`background-color: ${getColors.darkSecondaryColor}`"
              class="elevation-0 save-btn"
              btnText="Save"
            />
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- Snackbar message -->
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          btnStyle="outlined"
          class="secondary black--text"
        />
      </template>
    </v-snackbar>
    <!-- End of Snackbar message -->
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { capitalizeFirstLetter } from '@/utils/StringManipulation/transform'
import Button from '@/components/app/Button.vue'
import { isNodeIdValid, isNodeNameValid } from '@/utils/Validator'

const namespaceConfig = { namespace: 'config' }
const namespaceUser = { namespace: 'user' }

enum NodeType {
  client = 'client',
  site = 'site',
  camera = 'camera'
}

interface Payload {
  userId: string
  type: 'Client' | 'Site' | 'Camera'
  clientId?: string
  clientName?: string
  siteId?: string
  siteName?: string
  cameraId?: string
  cameraName?: string
}

@Component({
  components: {
    Button
  }
})
export default class AddNewNode extends Vue {
  @Prop({ required: true }) active: boolean
  @Prop({ required: false, default: NodeType.client }) nodeType: NodeType
  @Prop({ required: false }) userId: string
  @Prop({ required: false }) clientId: string
  @Prop({ required: false }) siteId: string

  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser)
  public getColors!: any

  @Getter('currentUser', namespaceUser) public currentUser: any

  @Action('createTreeNode', namespaceConfig) public createTreeNode: (
    payload: Payload
  ) => Promise<any>

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get capitalize() {
    return capitalizeFirstLetter
  }

  public snackbar = false
  public snackbarMessage = ''

  public nodeName = ''
  public nodeId = ''

  public isSaving = false

  public closeDialog() {
    this.nodeName = ''
    this.nodeId = ''
    this.$emit('closeDialog')
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  private loadTheCreatedNode(nodeId: string) {
    switch (this.nodeType) {
      case NodeType.client:
        this.$router.push({
          name: 'Configurations',
          params: {
            userId: this.userId,
            clientId: nodeId
          }
        })
        break
      case NodeType.site:
        this.$router.push({
          name: 'Configurations',
          params: {
            userId: this.userId,
            clientId: this.clientId,
            siteId: nodeId
          }
        })
        break
      case NodeType.camera:
        this.$router.push({
          name: 'Configurations',
          params: {
            userId: this.userId,
            clientId: this.clientId,
            siteId: this.siteId,
            cameraId: nodeId
          }
        })
        break
    }
  }

  public async addNode() {
    try {
      this.isSaving = true

      if (this.nodeName?.trim() === '') {
        this.popSnackbarMessage(
          `${capitalizeFirstLetter(this.nodeType)} name cannot be empty`
        )
        return
      }
      if (!isNodeNameValid(this.nodeName)) {
        this.popSnackbarMessage(
          `${capitalizeFirstLetter(
            this.nodeType
          )} name should not contain any disallowed special characters!`
        )
        this.isSaving = false
        return
      }
      if (this.nodeId !== '' && !isNodeIdValid(this.nodeId)) {
        this.popSnackbarMessage(
          `${capitalizeFirstLetter(
            this.nodeType
          )} ID should not contain any white spaces or special characters!`
        )
        this.isSaving = false
        return
      }

      let payload: Payload = {
        userId: this.userId,
        type: 'Client'
      }

      switch (this.nodeType) {
        case NodeType.client:
          payload = {
            ...payload,
            type: 'Client',
            clientId: this.nodeId?.trim(),
            clientName: this.nodeName?.trim()
          }
          break
        case NodeType.site:
          payload = {
            ...payload,
            type: 'Site',
            clientId: this.clientId,
            siteId: this.nodeId?.trim(),
            siteName: this.nodeName?.trim()
          }
          break
        case NodeType.camera:
          payload = {
            ...payload,
            type: 'Camera',
            clientId: this.clientId,
            siteId: this.siteId,
            cameraId: this.nodeId?.trim(),
            cameraName: this.nodeName?.trim()
          }
          break
      }

      const result = await this.createTreeNode(payload)

      if (result.status === 'success') {
        const nodeId = result.data
        this.popSnackbarMessage(result.message)
        this.loadTheCreatedNode(nodeId)
        this.closeDialog()
      } else {
        this.popSnackbarMessage(result.message ?? 'Failed to create client')
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.isSaving = false
    }
  }
}
</script>

<style scoped>
input[type='checkbox'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  border: 1px solid #ffd42a;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;
  -webkit-transition: box-shadow 200ms;
  -moz-transition: box-shadow 200ms;
  border-radius: 3px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
/* input checked border color */
input[type='checkbox']:checked {
  border-color: #ffd42a;
  background-color: #ffd42a;
}
/* checkbox checked */
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 8px;
  margin-top: 1px;
}
.black--text {
  display: flex;
  justify-content: space-between;
  flex-direction: flex-start;
  padding-right: inherit;
}
</style>
