<template>
  <div
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <div v-if="isLoading" class="loader-wrapper">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
    <v-container v-if="!isLoading" fluid>
      <v-row v-if="healthChecks.length > 0">
        <v-col
          v-for="item in healthChecks"
          :key="item.id"
          style="color: black"
          cols="12"
          :sm="12 / numberOfItems"
          :style="{
            color: 'black'
          }"
        >
          <v-sheet
            class="px-1 py-1"
            :style="{
              backgroundColor:
                isUserAdmin && isDarkModeToggleEnabled ? '#333333' : '#f5f5f5',
              border:
                isUserAdmin && isDarkModeToggleEnabled
                  ? '1px solid #C4C4C4'
                  : 'none'
            }"
          >
            <SimpleGalleryElement
              v-if="type === 'incident'"
              :mediaPath="item?.processedFile?.path"
              :mediaType="item?.mediaType"
              class="grid-item-wrapper"
              :style="{ height: gridHeight }"
              :isThumbnail="true"
            />
            <HealthCheckLiveStreamPlayer
              v-if="type === 'live'"
              :selectedHealthCheck="item"
              :liveStreamQuality="liveStreamQuality"
              class="grid-item-wrapper"
              :style="{ height: gridHeight }"
            />
            <p
              v-if="configLevel !== 'Camera'"
              class="mt-1 mb-0"
              style="text-align: left; font-weight: 600; font-size: 13px"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              <HealthCheckBreadCrumb
                :selectedHealthCheck="item"
                :configLevel="configLevel"
              />
            </p>

            <v-row class="my-1">
              <v-col class="d-flex justify-space-between align-center py-0">
                <v-btn
                  v-if="item.healthCheck.deviation[0] === 'No Deviation'"
                  class="py-0 px-2"
                  style="
                    text-transform: none;
                    background-color: #4dd053;
                    color: #ffffff;
                    height: 25px;
                    font-size: 12px;
                    box-shadow: none;
                  "
                >
                  No deviations detected
                </v-btn>
                <v-btn
                  v-if="item.healthCheck.deviation[0] !== 'No Deviation'"
                  class="py-0 px-2"
                  style="
                    text-transform: none;
                    background-color: #ff5252;
                    color: #ffffff;
                    height: 25px;
                    font-size: 12px;
                    box-shadow: none;
                  "
                >
                  <v-icon size="12" class="mr-1" color="#C4C4C4">
                    mdi-alert-circle
                  </v-icon>
                  Deviations detected
                </v-btn>

                <v-tooltip bottom v-if="item.healthCheck.reportMsg !== ''">
                  <template v-slot:activator="{ props, on }">
                    <v-btn
                      v-bind="props"
                      v-on="on"
                      class="py-0 px-2"
                      style="
                        text-transform: none;
                        height: 25px;
                        font-size: 12px;
                        border: 1px solid #c4c4c4;
                        box-shadow: none;
                      "
                      :style="{
                        backgroundColor:
                          isUserAdmin && isDarkModeToggleEnabled
                            ? '#333333'
                            : '#e5e5e5'
                      }"
                    >
                      <v-icon size="12" color="#C4C4C4"> mdi-comment </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.healthCheck.reportMsg }}</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row class="mb-1 mt-0">
              <v-col class="d-flex justify-space-between align-center py-0">
                <p
                  style="text-align: left; font-size: 12px"
                  class="mb-0"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkPrimaryColor
                  }"
                >
                  {{ getFormattedDate(item.healthCheck.createdAt) }}
                </p>
                <v-icon
                  :color="
                    isUserAdmin && isDarkModeToggleEnabled
                      ? '#C4C4C4'
                      : 'grey-lighten-1'
                  "
                  @click="setHealthCheckItem(item)"
                >
                  mdi-information
                </v-icon>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <Button
            v-if="!isLoading && hasMoreHealthChecks"
            @onButtonClick="loadMore()"
            btnText="More"
            :loading="isLoadMoreLoading"
          />
        </v-col>
      </v-row>
      <v-row v-if="healthChecks.length === 0">
        <v-col
          ><NoHealthChecks :nodeType="configLevel" :healthCheckType="type"
        /></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import SimpleGalleryElement from '../events/SimpleGalleryElement.vue'
import NoHealthChecks from '../error-pages/NoHealthChecks.vue'
import HealthCheckBreadCrumb from './HealthCheckBreadCrumb.vue'
import HealthCheckLiveStreamPlayer from './HealthCheckLiveStreamPlayer.vue'
import { LiveStreamQualityType } from '@/utils/Constants'
import moment from 'moment'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    SimpleGalleryElement,
    HealthCheckLiveStreamPlayer,
    NoHealthChecks,
    HealthCheckBreadCrumb,
    Button
  }
})
export default class HealthCheckFrame extends Vue {
  @Prop() public healthChecks!: any[]
  @Prop() public isLoading!: boolean
  @Prop() public isLoadMoreLoading!: boolean
  @Prop() public numberOfItems!: number
  @Prop() public configLevel!: string
  @Prop() public type!: string
  @Prop() public hasMoreHealthChecks!: boolean

  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public liveStreamQuality = LiveStreamQualityType.LOW

  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get gridHeight() {
    const heights = [700, 350, 250, 175]
    const index = Math.min(this.numberOfItems - 1, heights.length - 1)
    return `${heights[index]}px`
  }

  public loadMore() {
    this.$emit('update:loadHealthChecks', true)
  }

  public setHealthCheckItem(item: any) {
    this.$emit('update:selectedHealthCheck', item)
  }

  public getFormattedDate(dateTime: any) {
    const convertedDate = new Date(
      dateTime.seconds * 1000 + dateTime.nanoseconds / 1000000
    )
    return moment(convertedDate).format('DD/MM/YY HH:mm')
  }
}
</script>

<style scoped>
.camera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 116px);
}
.loading {
  color: black;
  font-size: 1rem;
}
.alert {
  padding: 20px;
  background-color: #ff5252;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.loader-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.permission-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  gap: 10px;
}

.grid-item-wrapper {
  width: 100%;
  height: 175px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item-wrapper img,
.grid-item-wrapper video {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
