import { getDefaultState } from './state'

const mutations = {
  setNodes: (state, payload) => {
    state.nodes = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  }
}
export default {
  ...mutations
}
