<template>
  <v-sheet
    class="px-1 py-1"
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled ? '#333333' : '#f5f5f5',
      border:
        isUserAdmin && isDarkModeToggleEnabled ? '1px solid #C4C4C4' : 'none'
    }"
  >
    <div class="grid-item-wrapper" :style="{ height: gridHeight }">
      <LiveStreamPlayer
        :cameraConfig="camera"
        :cameraId="camera.id"
        :isSiteHardwareDevice="camera.isSiteHardwareDevice"
        :liveStreamQuality="liveStreamQuality"
      />
    </div>
    <p
      class="mt-1 mb-0"
      style="text-align: left; font-weight: 600; font-size: 13px"
      :style="{
        color:
          isUserAdmin && isDarkModeToggleEnabled
            ? getColors.lightPrimaryColor
            : getColors.darkPrimaryColor
      }"
    >
      {{ breadcrumb }}
    </p>
  </v-sheet>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import LiveStreamPlayer from '../player/LiveStreamPlayer.vue'
import { LiveStreamQualityType } from '@/utils/Constants'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    LiveStreamPlayer
  }
})
export default class LiveFrame extends Vue {
  @Prop() public camera!: any
  @Prop() public numberOfItems!: number
  @Prop() public level!: string

  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public liveStreamQuality = LiveStreamQualityType.LOW

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get gridHeight() {
    const heights = [700, 350, 250, 190]
    const index = Math.min(this.numberOfItems - 1, heights.length - 1)
    return `${heights[index]}px`
  }

  public get breadcrumb() {
    let nodeNames = ''
    if (this.level === 'site') {
      nodeNames = this.camera.name
    } else if (this.level === 'client') {
      nodeNames = `${this.camera?.siteName} > ${this.camera.name}`
    } else if (this.level === 'global') {
      nodeNames = `${this.camera?.clientName} > ${this.camera?.siteName} > ${this.camera.name}`
      if (this.camera?.userDisplayName) {
        nodeNames = `${this.camera.userDisplayName} > ${nodeNames}`
      }
    }

    return nodeNames
  }
}
</script>

<style scoped>
.grid-item-wrapper {
  width: 100%;
  height: 175px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item-wrapper img,
.grid-item-wrapper video {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
