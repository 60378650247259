<template>
  <div style="height: 69vh; overflow: auto">
    <v-data-table
      id="dashboard-table"
      :items="results"
      :hide-default-footer="true"
      :items-per-page="10"
      data-test-id="incidents-list"
    >
      <template v-slot:item="props">
        <tr
          id="search-an-table-row"
          @click="onRowClick(props.item)"
          :style="{
            backgroundColor: getBackgroundColor(props.item.id.raw)
          }"
        >
          <v-row
            class="py-1"
            :style="isUserAdmin && isDarkModeToggleEnabled ? 'white' : 'black'"
            no-gutters
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              border-bottom: 1px solid #d4d4d4;
            "
            :class="smallScreen ? 'alarm-view-smallscreen' : ''"
          >
            <v-col :cols="smallScreen ? 3 : 5" class="col-lg-5">
              <SnapshotModalForAlarms
                :item="props.item"
                :key="props.item.id.raw"
                :isPending="
                  !isErrorIncident(props.item) && isPending(props.item)
                "
              ></SnapshotModalForAlarms>
            </v-col>
            <v-col
              cols="6"
              style="display: flex; flex-direction: row; align-items: center"
              class="custom-offset"
            >
              <div>
                <div
                  v-if="props.item?.completed?.raw === 'true'"
                  style="display: flex; justify-content: start"
                  :class="
                    smallScreen
                      ? 'alarm-view-datetime-container-smallscreen'
                      : ''
                  "
                >
                  <p
                    v-if="props.item?.completed?.raw === 'true'"
                    style="
                      text-align: left;
                      margin-bottom: 0px;
                      font-size: 18px;
                      margin-right: 10px;
                    "
                    :class="
                      smallScreen ? 'alarm-view-datetime-smallscreen' : ''
                    "
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled ? '#fff' : '#000'
                    }"
                  >
                    {{
                      getFormattedDate(
                        new Date(props.item?.createdat?.raw).toString()
                      )
                    }}
                  </p>
                  <p
                    v-if="props.item?.completed?.raw === 'true'"
                    style="text-align: left; font-size: 18px"
                    :class="smallScreen ? 'alarm-view-time-smallscreen' : ''"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled ? '#fff' : '#000'
                    }"
                  >
                    {{
                      getFormattedTime(
                        new Date(props.item?.createdat?.raw).toString()
                      )
                    }}
                  </p>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  "
                >
                  <div style="margin-right: 25px">
                    <p
                      style="
                        text-align: left;
                        margin-bottom: 0px;
                        font-size: 10px;
                      "
                      :style="{
                        color:
                          isUserAdmin && isDarkModeToggleEnabled
                            ? '#fff'
                            : '#8c8c8c'
                      }"
                      :class="smallScreen ? 'alarm-view-id-smallscreen' : ''"
                    >
                      ID: {{ props.item.id.raw }}
                    </p>
                    <div
                      style="display: flex; justify-content: start"
                      :style="
                        isErrorIncident(props.item) && smallScreen
                          ? 'height: 10px'
                          : ''
                      "
                    >
                      <p
                        style="
                          text-align: left;
                          margin-bottom: 0px;
                          font-size: 12px;
                        "
                        v-if="isPending(props.item)"
                        :class="
                          smallScreen
                            ? 'error-alarm-view-datetime-smallscreen'
                            : 'error-alarm-view-datetime-pending'
                        "
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? '#fff'
                              : '#000'
                        }"
                      >
                        Created at:
                        {{ getFormattedDate(props.item?.createdat?.raw) }}
                      </p>
                      <p
                        v-if="isPending(props.item)"
                        :class="
                          smallScreen ? 'error-alarm-view-time-smallscreen' : ''
                        "
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? '#fff'
                              : '#000'
                        }"
                      >
                        {{ getFormattedTime(props.item?.createdat?.raw) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class=""
                  v-if="
                    !isErrorIncident(props.item) &&
                    isPending(props.item) &&
                    !smallScreen
                  "
                >
                  <p
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled ? '#fff' : '#000'
                    }"
                  >
                    Pending
                  </p>
                  <v-icon
                    :class="
                      isUserAdmin && isDarkModeToggleEnabled
                        ? 'text-yellow'
                        : 'text-red'
                    "
                  >
                    mdi-clock-time-four-outline
                  </v-icon>
                </div>
                <div
                  :class="
                    smallScreen && isUserAdmin
                      ? 'div-container-mobile-admin'
                      : ''
                  "
                >
                  <div
                    :class="
                      smallScreen && isUserAdmin
                        ? 'div-container-mobile-admin-text'
                        : ''
                    "
                  >
                    <p
                      v-if="
                        props.item?.isaionly?.raw === 'false' && isUserAdmin
                      "
                      class="ai-human-ind"
                      title="Reviewed by AI and Human"
                      :color="
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkBlackColor
                      "
                    >
                      <span
                        v-if="smallScreen"
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? '#fff'
                              : '#000'
                        }"
                        >Reviewed by : Ai +</span
                      >
                      <span
                        v-else
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? '#fff'
                              : '#000'
                        }"
                        >Ai +</span
                      >
                      <v-icon
                        :color="
                          isUserAdmin && isDarkModeToggleEnabled
                            ? getColors.lightPrimaryColor
                            : getColors.darkBlackColor
                        "
                        small
                        >mdi-account</v-icon
                      >
                    </p>
                    <p
                      v-if="props.item?.isaionly?.raw === 'true' && isUserAdmin"
                      class="ai-human-ind"
                      title="Reviewed by AI only"
                    >
                      <span
                        v-if="smallScreen"
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? '#fff'
                              : '#000'
                        }"
                        >Reviewed by : Ai
                      </span>
                      <span
                        v-else
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? '#fff'
                              : '#000'
                        }"
                        >Ai
                      </span>
                    </p>
                  </div>
                  <div
                    :class="
                      smallScreen && isUserAdmin
                        ? 'div-container-mobile-admin-icons'
                        : ''
                    "
                  >
                    <div
                      :class="{
                        'bottom-result-box':
                          !smallScreen && isErrorIncident(props.item),
                        'bottom-result-box-customer':
                          !smallScreen && !isUserAdmin,
                        'bottom-result-box-left':
                          !isErrorIncident(props.item) &&
                          isUserAdmin &&
                          !smallScreen,
                        'bottom-result-box-mobile':
                          smallScreen && isErrorIncident(props.item),
                        'bottom-result-box-customer-mobile':
                          smallScreen && !isUserAdmin,
                        'bottom-result-box-left-mobile':
                          !isErrorIncident(props.item) &&
                          isUserAdmin &&
                          smallScreen
                      }"
                    >
                      <div
                        :class="{
                          assessment: isUserAdmin,
                          'assessment-customer': !isUserAdmin,
                          'assessment-customer-mobile':
                            !isUserAdmin && smallScreen
                        }"
                      >
                        <div>
                          <div
                            v-if="
                              props.item?.finalresult__personassessment?.raw ===
                              'unsure'
                            "
                            class="my-1 text-yellow"
                            :class="{
                              'dark-mode-text-yellow':
                                isUserAdmin && isDarkModeToggleEnabled,
                              'icon-size-mobile': smallScreen
                            }"
                          >
                            <!-- Person Unsure -->
                            <v-icon
                              small
                              class="text-yellow"
                              :class="{
                                'dark-mode-text-yellow':
                                  isUserAdmin && isDarkModeToggleEnabled,
                                'icon-size-mobile': smallScreen
                              }"
                              title="Person Unsure"
                              >mdi-human-handsdown</v-icon
                            >
                          </div>
                          <div
                            v-if="
                              props.item?.finalresult__personassessment?.raw ===
                              'alarm'
                            "
                            class="my-1 text-red"
                            :class="{
                              'dark-mode-text-red':
                                isUserAdmin && isDarkModeToggleEnabled,
                              'icon-size-mobile': smallScreen
                            }"
                          >
                            <!-- Person -->
                            <v-icon
                              small
                              class="text-red"
                              :class="{
                                'dark-mode-text-red':
                                  isUserAdmin && isDarkModeToggleEnabled,
                                'icon-size-mobile': smallScreen
                              }"
                              title="Person"
                              >mdi-walk</v-icon
                            >
                          </div>
                          <div
                            v-if="
                              props.item?.finalresult__personassessment?.raw ===
                                'no-alarm' &&
                              props.item?.isunarmed.raw !== 'true'
                            "
                            class="my-1 text-green"
                            :class="{
                              'dark-mode-text-green':
                                isUserAdmin && isDarkModeToggleEnabled,
                              'icon-size-mobile': smallScreen
                            }"
                          >
                            <!-- No Person -->
                            <v-icon
                              small
                              class="text-green"
                              title="No Person"
                              :class="{
                                'dark-mode-text-green':
                                  isUserAdmin && isDarkModeToggleEnabled,
                                'icon-size-mobile': smallScreen
                              }"
                              >mdi-human-handsdown</v-icon
                            >
                          </div>
                          <div
                            v-else-if="
                              props.item?.finalresult__personassessment?.raw ===
                                'no-alarm' &&
                              props.item?.isunarmed.raw === 'true'
                            "
                            class="my-1 text-grey"
                            :class="{
                              'dark-mode-text-grey':
                                isUserAdmin && isDarkModeToggleEnabled,
                              'icon-size-mobile': smallScreen
                            }"
                          >
                            <!-- No Person -->
                            <v-icon
                              small
                              class="text-grey"
                              title="No Person"
                              :class="{
                                'dark-mode-text-grey':
                                  isUserAdmin && isDarkModeToggleEnabled,
                                'icon-size-mobile': smallScreen
                              }"
                              >mdi-walk</v-icon
                            >
                          </div>
                        </div>
                        <div
                          v-if="
                            props.item?.alarmdefinition?.raw ===
                            'person_vehicle'
                          "
                          class="mx-1"
                        >
                          <v-icon
                            v-if="!smallScreen"
                            x-small
                            disabled
                            :class="
                              isUserAdmin && isDarkModeToggleEnabled
                                ? 'white-text'
                                : 'black-text'
                            "
                            >mdi-plus-thick</v-icon
                          >
                        </div>
                        <div
                          v-if="
                            props.item?.alarmdefinition?.raw ===
                            'person_vehicle'
                          "
                        >
                          <div
                            v-if="
                              props.item?.finalresult__vehiclemovement &&
                              (props.item?.finalresult__vehiclemovement?.raw ===
                                'alarm' ||
                                props.item?.finalresult__vehiclemovement
                                  ?.raw === 'true')
                            "
                            class="my-1 mx-1 vehicle-radius"
                            :class="
                              (isUserAdmin && isDarkModeToggleEnabled
                                ? 'dark-mode-text-red '
                                : '') +
                              (smallScreen ? 'text-red-mobile' : 'text-red')
                            "
                          >
                            <div
                              v-if="
                                props.item?.filemetadata__mediatype?.raw ===
                                'image'
                              "
                            >
                              <v-icon
                                class="text-red vehicle-radius"
                                :class="{
                                  'dark-mode-text-red':
                                    isUserAdmin && isDarkModeToggleEnabled,
                                  'icon-size-mobile': smallScreen
                                }"
                                title="Vehicle"
                                >mdi-car-hatchback</v-icon
                              >
                            </div>
                            <div v-else>
                              <img
                                :class="
                                  smallScreen ? 'image-size-mobile-view' : ''
                                "
                                :src="'/img/vehicle_movement.svg'"
                                alt="Icon"
                                title="Vehicle Movement"
                              />
                            </div>
                          </div>
                          <div
                            v-if="
                              (props.item?.finalresult__vehiclemovement &&
                                (props.item?.finalresult__vehiclemovement
                                  ?.raw === 'no-alarm' ||
                                  props.item?.finalresult__vehiclemovement
                                    ?.raw === 'false')) ||
                              props.item?.finalresult__vehiclemovement ===
                                undefined
                            "
                            class="my-1 mx-1 vehicle-radius"
                            :class="{
                              'text-green':
                                props.item?.isunarmed?.raw !== 'true',
                              'text-grey':
                                props.item?.isunarmed?.raw === 'true',
                              'dark-mode-text-green':
                                isUserAdmin &&
                                isDarkModeToggleEnabled &&
                                props.item?.isunarmed?.raw !== 'true',
                              'dark-mode-text-grey':
                                isUserAdmin &&
                                isDarkModeToggleEnabled &&
                                props.item?.isunarmed?.raw === 'true',
                              'icon-size-mobile': smallScreen
                            }"
                          >
                            <v-icon
                              :class="{
                                'text-green vehicle-radius':
                                  props.item?.isunarmed?.raw !== 'true',
                                'text-grey vehicle-radius':
                                  props.item?.isunarmed?.raw === 'true',
                                'dark-mode-text-green':
                                  isUserAdmin &&
                                  isDarkModeToggleEnabled &&
                                  props.item?.isunarmed?.raw !== 'true',
                                'dark-mode-text-grey':
                                  isUserAdmin &&
                                  isDarkModeToggleEnabled &&
                                  props.item?.isunarmed?.raw === 'true',
                                'icon-size-mobile': smallScreen
                              }"
                              :title="
                                props.item?.filemetadata__mediatype?.raw ===
                                'image'
                                  ? 'No Vehicle'
                                  : 'No Vehicle Movement'
                              "
                            >
                              mdi-car-hatchback
                            </v-icon>
                          </div>

                          <div
                            v-if="
                              props.item?.finalresult__vehiclemovement?.raw ===
                              'unsure'
                            "
                            class="my-1 text-yellow vehicle-radius"
                            :class="{
                              'dark-mode-text-yellow':
                                isUserAdmin && isDarkModeToggleEnabled,
                              'icon-size-mobile': smallScreen
                            }"
                          >
                            <!-- Vehicle Unsure -->
                            <v-icon
                              class="text-yellow vehicle-radius"
                              title="Vehicle Unsure"
                              :class="{
                                'dark-mode-text-yellow':
                                  isUserAdmin && isDarkModeToggleEnabled,
                                'icon-size-mobile': smallScreen
                              }"
                            >
                              mdi-car-hatchback
                            </v-icon>
                          </div>
                        </div>
                        <div
                          v-if="
                            props.item?.missed &&
                            isUserAdmin &&
                            props.item?.missed?.raw === 'true'
                          "
                          class="mx-1"
                        >
                          <v-icon
                            x-small
                            disabled
                            :class="
                              isUserAdmin && isDarkModeToggleEnabled
                                ? 'white-text'
                                : 'black-text'
                            "
                            >mdi-plus-thick</v-icon
                          >
                        </div>
                        <div
                          v-if="
                            props.item?.missed &&
                            isUserAdmin &&
                            props.item?.missed?.raw === 'true'
                          "
                          class="my-1 ml-1"
                        >
                          <!-- missed -->
                          <v-icon
                            class="text-red"
                            :class="{
                              'dark-mode-text-yellow':
                                isUserAdmin && isDarkModeToggleEnabled,
                              'icon-size-mobile': smallScreen
                            }"
                            title="Missed"
                          >
                            mdi-close-circle
                          </v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import SnapshotModalForAlarms from '../material/SnapshotModalForAlarms.vue'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'

const namespaceCamera = { namespace: 'camera' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({ components: { SnapshotModalForAlarms } })
export default class SearchResults extends Vue {
  @Prop() public results: Array<any>
  @Prop() public currentItem: any
  @Prop() public isUserAdmin: boolean
  @Prop() public setCurrentItem: (item: any) => void
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Mutation('addToCameraList', namespaceCamera)
  private addToCameraList: (cameraId: string) => Promise<void>

  // camera list as SET to avoid duplicates
  public cameraList: Set<{
    cameraId: string
    incidentId: string
  }> = new Set()

  public headers = [
    { text: 'created at', value: 'createdAt', align: 'center' },
    { text: 'alarm definition', value: 'alarmDef', align: 'center' },
    { text: 'snapshot', value: 'url', align: 'center' },
    { text: 'result', value: 'result', align: 'center' },
    { text: 'actions', value: 'actions', align: 'center' }
  ]

  isRefreshEnabled: boolean = false
  currentItemId: string = ''

  public isPending(item: {
    missed: { raw: string }
    completed: { raw: string }
  }) {
    return item?.completed?.raw !== 'true'
  }

  public isErrorIncident(item: {
    completed: { raw: string }
    deadline: { raw: string }
  }) {
    return (
      item?.completed?.raw !== 'true' &&
      this.hasDeadlinePassed(item?.deadline?.raw)
    )
  }

  public get smallScreen() {
    return isCompactView()
  }

  private hasDeadlinePassed(deadline: string) {
    const deadlineDate = new Date(deadline)
    const currentDate = new Date()
    return deadlineDate < currentDate
  }

  private async mounted() {
    if (this.results.length > 0) {
      this.currentItemId = this.results[0].id.raw
    }
  }

  public onRowClick(item: { id: { raw: string } }) {
    this.setCurrentItem(item)
    this.currentItemId = item.id.raw
  }

  public getFormattedDate(dateTime: Date) {
    return this.smallScreen
      ? moment(dateTime).format('DD/MM/YY')
      : moment(dateTime).format('DD/MM/YYYY')
  }

  public getFormattedTime(dateTime: Date) {
    return this.smallScreen
      ? moment(dateTime).format('HH:mm')
      : moment(dateTime).format('HH:mm:ss')
  }

  public getBackgroundColor(propsItemId: string) {
    if (this.isUserAdmin && this.isDarkModeToggleEnabled) {
      return this.currentItemId === propsItemId ? '#6f6c61' : '#333333'
    }
    return this.currentItemId === propsItemId ? '#faf2db' : 'white'
  }

  @Watch('currentItem')
  public watchCurrentItem() {
    this.currentItemId = this.currentItem?.id.raw
  }
}
</script>

<style scoped>
.div-container-mobile-admin {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 70vw;
}
.div-container-mobile-admin-text {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 70vw;
}
.div-container-mobile-admin-icons {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  width: 70vw;
}
.alarm-view-smallscreen {
  height: 75px;
  justify-content: flex-start !important;
  padding-left: 5px;
}
.alarm-view-datetime-smallscreen {
  font-size: 12px !important;
  font-weight: 600;
  padding-left: 20px;
}

.error-alarm-view-datetime-smallscreen {
  font-size: 10px !important;
  font-weight: 300;
  padding-left: 20px;
}

.error-alarm-view-datetime-pending {
  font-size: 14px !important;
  font-weight: 300;
  padding-right: 10px;
}

.alarm-view-time-smallscreen {
  font-size: 12px !important;
  font-weight: 600;
}

.error-alarm-view-time-smallscreen {
  font-size: 10px !important;
  font-weight: 300;
  padding-left: 10px;
}

.alarm-view-id-smallscreen {
  padding-left: 20px;
  width: 200px;
}

.alarm-view-datetime-container-smallscreen {
  height: 20px !important;
}

.text-green {
  color: #4ed053;
  background-color: #cdebcf;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.dark-mode-text-green {
  color: #4dd052;
  background-color: #39753b;
}

.text-grey {
  color: #8c8c8c;
  background-color: #e5e5e5;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.dark-mode-text-grey {
  color: #333333;
  background-color: #938d76;
}

.text-red {
  color: #ff5252;
  background-color: #f6d9d9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.image-size-mobile-view {
  width: 30px;
  height: 16px;
}

.text-red-mobile {
  color: #ff5252;
  background-color: #f6d9d9;
  border-radius: 50%;
  width: 40px !important;
  height: 20px !important;
}

.dark-mode-text-red {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #ff5252;
  background-color: #894a4a;
}
.text-yellow {
  color: #ffd42b;
  background-color: #fff5ce;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.icon-size-mobile {
  width: 20px;
  height: 20px;
}
.dark-mode-text-yellow {
  color: #ffd42a;
  background-color: #877018;
}
.vehicle-radius {
  border-radius: 15px;
  width: 40px;
}
.missed-icon {
  background-color: transparent;
}
.text-black {
  color: black;
}
.custom-offset {
  margin-left: 10px;
}
.assessment {
  float: right;
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
}
.assessment-customer {
  float: left;
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  justify-content: flex-start;
}

.assessment-customer-mobile {
  float: left;
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  justify-content: flex-end;
}

.bottom-result-box {
  padding-top: 30px;
  display: flex;
  align-items: center;
}
.bottom-result-box-customer {
  padding-top: 30px;
  display: flex;
  align-items: center;
}
.bottom-result-box-left {
  padding-top: 30px;
  display: flex;
  align-items: center;
  padding-left: 17px;
}

.bottom-result-box-mobile {
  width: 47px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  padding-left: 2px;
}
.bottom-result-box-customer-mobile {
  width: 260px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 0px;
  display: flex;
  padding-right: 8px;
}
.bottom-result-box-left-mobile {
  width: 47px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  padding-left: 2px;
}
.bottom-result-box-padding {
  padding-top: 0px;
}
.review-mode {
  float: left;
  width: 50%;
}

.alarm-type-select {
  width: 50%;
  float: right;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: black !important;
  font-size: 18px !important;
  font-weight: 400;
  border-bottom: 2px solid #c4c4c4 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: #8c8c8c !important;
}

.action-button {
  background-color: #ffe88e !important;
  color: black;
  border: 2px solid #ffe88e !important;
  box-shadow: none !important;
  text-transform: unset !important;
  min-width: 45px !important;
}
.border-rounded {
  border: 2px solid #c4c4c4;
  border-radius: 10px;
  width: 100%;
}
.bg-clr-white {
  background-color: white !important;
}
.bg-clr-grey {
  background-color: #faf2db !important;
}
.bg-dark-grey {
  background-color: #333333 !important;
}
.bg-dark-yellow {
  background-color: #6f6c61 !important;
}
.ai-human-ind {
  text-align: left;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 12px;
  padding-left: 20px;
  padding-top: 5px;
}
::v-deep .v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
}

.white-text {
  color: white !important;
}
.black-text {
  color: black;
}

.unsuccess-status {
  margin: 5px 0 0 5px;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'sm-and-up' )}
.theme--light.v-icon.v-icon.v-icon--disabled
  color: #ababab !important,
@media #{map-get($display-breakpoints, 'xs-only' )}
.theme--light.v-icon.v-icon.v-icon--disabled
  color: #ababab !important,
</style>
