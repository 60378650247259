<template>
  <v-btn
    :elevation="elevation"
    :class="`common-btn text-subtitle-2 ${btnStyle}`"
    :href="href"
    :target="target"
    :disabled="disabled"
    @click="$emit('onButtonClick')"
    v-bind="$attrs"
    :loading="loading"
  >
    <!-- Define a slot for the optional v-icon -->
    <slot name="icon"></slot>
    <slot></slot>
    <div
      class="text-body-2"
      :style="{
        color: btnStyle === 'default' ? 'black' : ''
      }"
    >
      {{ btnText }}
    </div>
  </v-btn>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Button extends Vue {
  @Prop({ default: '0' }) public elevation!: string
  @Prop() public btnText?: string
  @Prop() public href!: string
  @Prop({ default: '_self' }) public target!:
    | '_self'
    | '_blank'
    | '_parent'
    | '_top'
  @Prop({ default: 'default' }) public btnStyle!:
    | 'default'
    | 'outlined'
    | 'outlined-grey'
    | 'delete'
  @Prop({ default: false }) public disabled!: boolean
  @Prop({ default: false }) public loading: boolean
}
</script>

<style scoped>
.common-btn {
  border-radius: 5px;
  box-shadow: none;
  text-transform: unset !important;
}

.default {
  background-color: #ffd42a !important;
}

.outlined {
  background-color: transparent !important;
  border: 2px solid #ffd42a;
}

.delete {
  background-color: #ff5252 !important;
  color: white !important;
}

.outlined:hover {
  background-color: #ffe789 !important;
}

.outlined-grey {
  background-color: white !important;
  border: 2px solid #c4c4c4;
}
.test-button {
  text-transform: unset !important;
  color: black !important;
  background-color: #ffd42a !important;
}
.worker-ready {
  color: black !important;
  background-color: #ffd42a !important;
}
</style>
