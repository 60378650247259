var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:({
    backgroundColor:
      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? _vm.getColors.darkPrimaryColor
        : _vm.getColors.lightPrimaryColor
  })},[_c('Header',{attrs:{"route":"live"}}),(_vm.cameras && _vm.cameras.length === 0)?_c('div',[_c('NoCamera',{attrs:{"nodeType":"site"}})],1):_vm._e(),_c('v-container',{staticClass:"bg-surface-variant",attrs:{"fluid":""}},[(_vm.cameras && _vm.cameras.length !== 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}}),_c('v-col',{staticClass:"text-end",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":[1, 2, 3, 4],"label":"Layout per row","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false},model:{value:(_vm.numberOfCameras),callback:function ($$v) {_vm.numberOfCameras=$$v},expression:"numberOfCameras"}})],1)],1):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('p',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlackColor
        })},[_vm._v(" Loading Camera List... ")]),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}})],1):_vm._e(),_c('v-row',_vm._l((_vm.cameras),function(camera){return _c('v-col',{key:camera.id,staticStyle:{"color":"black"},style:({
          color: 'black'
        }),attrs:{"cols":"12","sm":12 / _vm.numberOfCameras}},[_c('LiveFrame',{attrs:{"camera":camera,"numberOfItems":_vm.numberOfCameras,"level":'site'}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }