<template>
  <div
    v-if="item"
    class="d-flex justify-space-between align-center input--border pa-2"
  >
    <p
      class="my-0 font-weight-bold"
      :style="{
        color:
          isUserAdmin && isDarkModeToggleEnabled
            ? getColors.lightPrimaryColor
            : getColors.darkBlackColor
      }"
    >
      {{ displayName }}
    </p>
    <div v-if="!isUserDetailsRequired" class="d-flex flex-row justify-end">
      <v-icon
        v-if="allowCopy"
        class="mr-2"
        @click="onClickCopy(getValue(item.raw))"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
        >{{ 'mdi-content-copy' }}</v-icon
      >
      <p
        class="my-0 select"
        :class="getClass(item.raw)"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
      >
        {{ getValue(item.raw) }}
      </p>
    </div>
    <div v-if="isUserDetailsRequired" class="d-flex flex-row justify-end">
      <Avatar
        v-if="isUserAvailable()"
        :width="'24px'"
        :id="item.raw"
        contain
      ></Avatar>
      <v-icon
        v-else
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
        >mdi-account-group-outline
      </v-icon>
      <p
        class="my-0 ml-3 select"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
      >
        {{ userValue ? userValue : 'Loading...' }}
      </p>
    </div>

    <!-- Snackbar message -->
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          btnStyle="outlined"
        />
      </template>
    </v-snackbar>
    <!-- End of Snackbar message -->
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Avatar from '../user/Avatar.vue'
import Button from '@/components/app/Button.vue'
import { DocumentData } from 'firebase-admin/firestore'

enum AlarmDetection {
  'alarm' = 'Alarm',
  'no-alarm' = 'No Alarm',
  'unsure' = 'Unsure'
}
type item = {
  raw: string
}
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: { Avatar, Button }
})
export default class Information extends Vue {
  @Action('bindAllUser', namespaceUser) public bindAllUser: () => any
  @Action('getUserByDocId', namespaceUser) public getUserByDocId: (
    id: string
  ) => Promise<DocumentData | null>
  @Getter('allUser', namespaceUser) public users: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Prop() item: item
  @Prop() displayName: string
  @Prop({ default: false }) isUserDetailsRequired: boolean
  @Prop({ default: false }) allowCopy: boolean

  public snackbar: boolean = false
  public snackbarMessage: string = ''
  public userValue: any = null

  @Watch('item.raw')
  async onItemRawChanged(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      this.userValue = 'Loading...'
      this.userValue = await this.getUserValue(newVal)
    }
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async getUserValue(id: string) {
    const correspondingUser = await this.getUserByDocId(id)
    return correspondingUser?.display_name ?? ''
  }

  public getValue(value: string) {
    if (AlarmDetection[value]) {
      return AlarmDetection[value]
    } else {
      return value
    }
  }

  public getClass(value: string) {
    if (AlarmDetection[value]) {
      return value
    } else {
      return ''
    }
  }

  public isUserAvailable() {
    const correspondingUser = this.users.find(
      (user: { id: string }) => user.id === this.item?.raw
    )
    if (correspondingUser) {
      return true
    } else {
      return false
    }
  }

  private async mounted() {
    if (this.isUserDetailsRequired) {
      this.userValue = await this.getUserValue(this.item?.raw)
      await this.bindAllUser()
    }
  }
  public onClickCopy(text: string) {
    navigator.clipboard.writeText(text)
    this.popSnackbarMessage(`${text} copied.`)
  }
  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }
}
</script>

<style scoped>
.input--border {
  border: 1px solid #e1dede !important;
  border-radius: 8px;
}
.alarm {
  color: red;
}
.no-alarm {
  color: green;
}
.unsure {
  color: #ffd42a;
}
.select {
  user-select: text;
  color: black;
}
.font-weight-bold {
  color: black;
}
</style>
