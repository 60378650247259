<template>
  <div class="camera">
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="amber"
    ></v-progress-circular>
    <video
      :id="videoElementId"
      controls
      autoplay
      playsinline
      muted
      v-bind:class="{
        hidden: isLoading,
        'camera-video': !isLoading
      }"
    ></video>
  </div>
</template>

<script lang="ts">
import { Vue, Watch, Prop, Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor'

const namespaceCamera = { namespace: 'camera' }

@Component({
  components: {}
})
export default class AntServerPlayer extends Vue {
  @Prop({ required: true }) public cameraId: string
  @Prop({ required: true }) public cameraConfig: any

  @Action('bindRtspStream', namespaceCamera) private bindRtspStream
  @Action('stopRtspStream', namespaceCamera) private stopRtspStream

  private readonly ANT_SERVER_URL =
    process.env.VUE_APP_ANT_SERVER_URL.toString()?.trim() ?? ''

  public isLoading = true
  public streamId: string = null
  public webRtcAdaptor: WebRTCAdaptor = null

  get videoElementId() {
    return `ant-video-${this.cameraId}`
  }

  public async mounted() {
    this.watchCameraConfig()
  }

  beforeMount() {
    window.addEventListener('beforeunload', this.unloadEventHandler)
  }

  public async beforeDestroy() {
    // avoid memory leaks
    window.removeEventListener('beforeunload', this.unloadEventHandler)

    if (this.webRtcAdaptor) {
      this.webRtcAdaptor.stop(this.streamId)
    }
    this.removeStream()
  }

  unloadEventHandler = (event) => {
    this.removeStream(true)
  }

  removeStream(isWindowUnloading = false) {
    if (this.streamId) {
      this.stopRtspStream({ streamId: this.streamId, isWindowUnloading })
    }
  }

  @Watch('cameraConfig')
  @Watch('cameraId')
  public async watchCameraConfig() {
    this.isLoading = true

    if (this.cameraConfig && this.cameraId) {
      await this.initRtspStream()
    }
  }

  public async initRtspStream() {
    this.isLoading = true
    this.streamId = await this.bindRtspStream({
      cameraDocId: this.cameraId,
      url: this.cameraConfig.liveStream.url
    })
    this.initWebRtcAdaptor()
  }

  public initWebRtcAdaptor() {
    const antServerHost = new URL(this.ANT_SERVER_URL).host

    if (this.webRtcAdaptor) {
      this.webRtcAdaptor.stop(this.streamId)
    }
    this.webRtcAdaptor = new WebRTCAdaptor({
      websocketURL: `wss://${antServerHost}:5443/WebRTCAppEE/websocket`,
      mediaConstraints: {
        video: true,
        audio: true
      },
      peerconnection_config: {
        iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }]
      },
      isMultiPeer: true,
      isPlayMode: true,
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false
      },
      remoteVideoElement: document.getElementById(this.videoElementId),
      callback: (info, obj) => {
        if (info === 'play_started') {
          this.isLoading = false
        }
      }, // check info callbacks bellow
      callbackError: function (error, message) {} // check error callbacks bellow
    })
    this.webRtcAdaptor.play(this.streamId)
  }
}
</script>

<style scoped>
.camera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.camera-video {
  width: 100%;
  height: 100%;
}
.hidden {
  display: none;
}
</style>
