<template>
  <div>
    <div
      v-if="modifiedSections.length > 0"
      class="sticky-bar"
      :style="{
        backgroundColor:
          isUserAdmin && isDarkModeToggleEnabled
            ? getColors.darkBlackColor
            : getColors.lightTreeItemsColor
      }"
      data-test-id="camera-config-sticky-bar"
    >
      <p
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
        data-test-id="camera-config-sticky-bar-message"
      >
        {{
          `Do you wanna save the changes to ${modifiedSections.join(
            ', '
          )} config(s)? `
        }}
      </p>
      <div class="sticky-button-group">
        <Button
          style="background-color: #ffd42a; width: 90px; float: left"
          btnText="Save"
          class="mb-2"
          @onButtonClick="onSaveConfigs"
          data-test-id="camera-config-save-btn"
        />
        <Button
          style="width: 90px; float: left"
          btnText="Discard"
          class="mb-2"
          @onButtonClick="onDiscardSaveConfigs"
          data-test-id="camera-config-discard-btn"
        />
      </div>
    </div>
    <v-row>
      <v-col :cols="6" class="black--text pt-7 pl-8 pr-5">
        <v-col>
          <BlockHeader :title="'Camera Information'" :titleOnly="true" />

          <v-row class="black--text d-flex flex-column pr-6">
            <v-col class="card-style">
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
                data-test-id="camera-id-label"
              >
                Camera Id :
                <code
                  data-test-id="camera-id-copy"
                  class="pointer"
                  v-text="cameraId"
                  @click="onClickCopy(cameraId)"
                >
                </code>
              </p>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
                data-test-id="camera-created-label"
              >
                Created
                <code
                  data-test-id="camera-created-value"
                  class="pointer"
                  v-text="`on ${cameraCreatedDate}, at ${cameraCreatedTime}`"
                >
                </code>
              </p>
              <div class="form-item">
                <div>
                  <p
                    class="font-weight-bold text-left"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkBlack
                    }"
                    data-test-id="camera-name-label"
                  >
                    Camera Name
                  </p>
                </div>
                <v-text-field
                  v-model="cameraName"
                  placeholder="Camera Name"
                  hide-details="true"
                  style="margin-top: -20px; font-size: 15px"
                  :dark="isUserAdmin && isDarkModeToggleEnabled"
                  :readonly="!isWriteEnabled"
                  data-test-id="camera-name-input"
                >
                  <template v-if="isWriteEnabled" v-slot:append-outer>
                    <v-btn
                      icon
                      v-if="cameraName"
                      @click="cameraName = ''"
                      title="Clear"
                      data-test-id="camera-name-clear-button"
                    >
                      <v-icon dark> mdi-close </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <p
                  v-if="cameraActivatedDate && cameraActivatedTime"
                  class="font-weight-bold text-left"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlack
                  }"
                  data-test-id="camera-last-activated-label"
                >
                  Last Active
                  <code
                    data-test-id="camera-last-activated-value"
                    class="pointer"
                    v-text="
                      `on ${cameraActivatedDate}, at ${cameraActivatedTime}`
                    "
                  >
                  </code>
                </p>
              </div>
            </v-col>
            <v-col
              class="card-style black--text d-flex flex-column pb-4 pl-4 pr-3"
            >
              <v-row class="pb-1 pl-3 mt-2">
                <div
                  class="font-weight-bold text-left mb-0"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlack
                  }"
                  data-test-id="camera-zone-label"
                >
                  Camera Zone (eg: 001)
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  class="pl-3 pt-0"
                  v-model="zone"
                  placeholder="Camera Zone"
                  style="font-size: 15px; max-width: 124px"
                  :dark="isUserAdmin && isDarkModeToggleEnabled"
                  :rules="[threeDigitNumberRule]"
                  :disabled="!isWriteEnabled"
                  data-test-id="camera-zone-input"
                >
                  <template v-slot:append-outer v-if="isWriteEnabled">
                    <v-btn
                      data-test-id="camera-zone-clear-button"
                      icon
                      v-if="zone"
                      @click="zone = ''"
                      title="Clear"
                    >
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-row>
            </v-col>

            <div>
              <div
                class="card-style black--text d-flex flex-column pb-6 pl-4 pr-3"
              >
                <div
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlack
                  }"
                  data-test-id="reference-image-label"
                  class="font-weight-bold text-left my-1 pt-4"
                  v-if="!isSelectArea"
                  title="Select a image to be used as reference for the camera"
                >
                  Reference Image
                </div>
                <div
                  v-if="
                    referenceImageUrl &&
                    referenceImageUpdatedDateTime !== '' &&
                    !isUploadingReferenceImage
                  "
                >
                  <p
                    class="text-left"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkBlack
                    }"
                    data-test-id="ref-updated-date"
                  >
                    Updated
                    <code
                      data-test-id="ref-updated-value"
                      class="pointer"
                      v-text="referenceImageUpdatedDateTime"
                    >
                    </code>
                  </p>
                </div>
                <div>
                  <div v-if="isReferenceLoading && !referenceImageUrl">
                    <v-alert dense outlined type="info" color="#ffd42a">
                      <p data-test-id="reference-image-add-msg">
                        <span v-if="isEdgeDeviceEnabled"
                          >Adding Reference Image from promiseQube Device</span
                        >
                        <span v-else
                          >Adding Reference Image from Live Stream</span
                        >
                      </p>
                      <v-progress-circular indeterminate />
                    </v-alert>
                  </div>
                  <div
                    v-if="needsReExtractFromLiveStream && !referenceImageUrl"
                  >
                    <v-alert dense outlined type="error" color="#FF5252">
                      <p data-test-id="reference-image-add-warning-edge">
                        {{
                          isReferenceLoadingError
                            ? 'Updating reference image from live stream failed.'
                            : 'Reference image is not available.'
                        }}
                      </p>
                    </v-alert>
                  </div>
                  <div
                    v-if="needsReExtractFromEdgeDevice && !referenceImageUrl"
                  >
                    <v-alert dense outlined type="error" color="#FF5252">
                      <p data-test-id="reference-image-add-warning-live">
                        {{
                          isReferenceLoadingError
                            ? 'Updating reference image from edge device failed.'
                            : 'Reference image is not available.'
                        }}
                      </p>
                    </v-alert>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 20px;
                      width: 100%;
                    "
                  >
                    <DisplayDrawing
                      v-if="referenceImageUrl"
                      :referenceImage="referenceImageUrl"
                      :cameraDocId="cameraDocId"
                      :existingRegions="excludedZones"
                      :metadata="referenceImageMetadata"
                      data-test-id="display-drawing-component"
                    />
                  </div>

                  <v-progress-linear
                    color="secondary"
                    class="mt-2 progress-bar"
                    :value="uploadValue"
                    height="14"
                    v-if="isUploadingReferenceImage"
                    data-test-id="reference-image-upload-progress"
                  >
                    {{
                      `${Math.round(uploadValue)}% ${
                        uploadValue >= 100 ? 'Upload Complete' : 'Uploading ...'
                      }`
                    }}
                  </v-progress-linear>

                  <v-dialog v-model="showExcludeZones" fullscreen>
                    <v-card
                      :style="{
                        backgroundColor:
                          isUserAdmin && isDarkModeToggleEnabled
                            ? getColors.darkPrimaryColor
                            : getColors.lightPrimaryColor
                      }"
                    >
                      <v-card-text>
                        <ExcludedZones
                          v-if="isSelectArea && referenceImageUrl"
                          :referenceImage="referenceImageUrl"
                          :cameraDocId="cameraDocId"
                          :existingRegions="excludedZones"
                          :metadata="referenceImageMetadata"
                        />
                      </v-card-text>

                      <v-card-actions style="justify-content: center">
                        <v-btn
                          class="close-btn text-subtitle-2 elevation-0"
                          color="secondary black--text"
                          @click="onClickCloseModal"
                          v-if="referenceImageUrl && isSelectArea"
                          data-test-id="reference-image-close-button"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <div style="align-item: center; width: 100%; margin: 0 auto">
                    <v-row style="display: flex; justify-content: center">
                      <v-col cols="auto" style="padding: 5px">
                        <v-btn
                          dark
                          depressed
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            background-color: var(--v-error-base);
                          "
                          color="secondary black--text"
                          class="image-btn custome-width text-subtitle-2"
                          @click="deleteReferenceImage()"
                          v-if="referenceImageUrl"
                          title="Delete Reference Image"
                          :disabled="
                            isUploadingReferenceImage || !isWriteEnabled
                          "
                          data-test-id="reference-image-delete-button"
                        >
                          <v-icon class="pr-lg-2" dark style="flex: 0">
                            mdi-delete
                          </v-icon>
                          <div class="d-none d-lg-flex" style="flex: 1">
                            <p class="ma-0">Delete</p>
                          </div>
                        </v-btn>
                      </v-col>

                      <v-col cols="auto" style="padding: 5px">
                        <v-btn
                          dark
                          depressed
                          style="
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                          "
                          color="secondary black--text"
                          class="image-btn custome-width text-subtitle-2"
                          @click="onClickNewReference"
                          v-if="!isSelectArea"
                          :title="
                            referenceImageUrl
                              ? 'Change Reference Image'
                              : 'Add a Reference Image'
                          "
                          :disabled="
                            isUploadingReferenceImage || !isWriteEnabled
                          "
                          data-test-id="reference-image-upload-button"
                        >
                          <v-icon class="pr-lg-2" dark>
                            {{
                              referenceImageUrl ? 'mdi-pencil' : 'mdi-upload'
                            }}
                          </v-icon>

                          <div class="d-none d-lg-flex">
                            <p class="ma-0">
                              {{ referenceImageUrl ? 'Change' : 'Upload' }}
                            </p>
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto" style="padding: 5px">
                        <v-btn
                          dark
                          depressed
                          style="
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                          "
                          color="secondary black--text"
                          class="image-btn custome-width text-subtitle-2"
                          @click="extractReferenceImageFromEdgeDevice"
                          v-if="isEdgeDeviceEnabled"
                          title="Extract the Reference Image from the promiseQube Device"
                          data-test-id="reference-image-extract-edge-button"
                        >
                          <v-icon class="pr-lg-2" dark> mdi-download </v-icon>
                          <div class="d-none d-lg-flex">
                            <p class="ma-0">Extract from Device</p>
                          </div>
                        </v-btn>
                      </v-col>

                      <v-col cols="auto" style="padding: 5px">
                        <v-btn
                          dark
                          depressed
                          style="
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                          "
                          color="secondary black--text"
                          class="image-btn custome-width text-subtitle-2"
                          @click="onClickExtractFromLiveStream"
                          v-if="
                            configuration?.isLiveStreamEnabled &&
                            !isReferenceLoading
                          "
                          title="Extract the Reference Image from the Live
                          Stream"
                          data-test-id="reference-image-extract-live-button"
                        >
                          <v-icon class="pr-lg-2" dark> mdi-download </v-icon>
                          <div class="d-none d-lg-flex">
                            <p class="ma-0">
                              {{
                                isReferenceLoadingError
                                  ? 'Retry'
                                  : 'Extract Image from Stream'
                              }}
                            </p>
                          </div>
                        </v-btn>
                      </v-col>

                      <v-col cols="auto" style="padding: 5px">
                        <v-btn
                          dark
                          depressed
                          style="
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                          "
                          color="secondary black--text"
                          class="image-btn custome-width text-subtitle-2"
                          @click="onClickSelectArea"
                          v-if="referenceImageUrl && !isSelectArea"
                          title="Masked Areas/Excluded Zones: Choose Areas to Exclude from Monitoring"
                          :disabled="
                            isUploadingReferenceImage || !isWriteEnabled
                          "
                          data-test-id="reference-image-exclude-zones-button"
                        >
                          <v-icon class="pr-lg-2" dark> mdi-select </v-icon>
                          <div class="d-none d-lg-flex">
                            <p class="ma-0">Draw Excluded Zones</p>
                          </div>
                        </v-btn>
                      </v-col>

                      <v-file-input
                        accept="image/*"
                        ref="referenceFileInput"
                        class="d-none"
                        filled
                        @change="onFileChangeReferenceImage"
                        data-test-id="reference-image-file-input"
                      ></v-file-input>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>
          </v-row>
        </v-col>

        <v-col class="d-flex flex-column pt-12 pb-6 pl-0 pr-0">
          <v-col>
            <BlockHeader
              :title="'Contact'"
              :configuration="configuration"
              :type="'camera'"
              :isWriteEnabled="isWriteEnabled"
              :onSwitchChange="onContactSettingsChange"
              data-test-id="camera-config-contact-header"
            />
            <v-row :class="getContentOverlayClass(isContactBlockEnabled)">
              <Contact
                :configuration="configuration"
                :clientConfig="clientConfig"
                :siteConfig="siteConfig"
                :onChange="onContactChange"
                :level="'camera'"
                :isWriteEnabled="isWriteEnabled"
                :isContactFieldEnabled="isContactFieldEnabled"
              />
            </v-row>
          </v-col>
        </v-col>

        <v-col
          class="d-flex flex-column pt-12 pb-12 pl-0 pr-0"
          v-if="isHardwareDevice"
        >
          <v-col>
            <BlockHeader
              :title="'promiseQube'"
              :configuration="configuration"
              :isWriteEnabled="isWriteEnabled"
              :onSwitchChange="onToggleEdgeDeviceStatus"
              :isLimitExceeded="isLimitExceeded"
            />

            <v-row class="card-component-style">
              <EdgeDeviceConfiguration
                v-if="isHardwareDevice"
                :configuration="configuration"
                :onChange="onSaveEdgeDevice"
                :onNavigateToLive="routeToLiveCamera"
                :isWriteEnabled="isWriteEnabled"
                :siteId="siteId"
                :clientId="clientId"
                :userId="userId"
              />
            </v-row>
          </v-col>
        </v-col>
        <v-col
          class="d-flex flex-column pt-12 pb-12 pl-0 pr-0"
          v-if="isHardwareDevice"
        >
          <v-col>
            <BlockHeader
              :title="'Recordings'"
              :configuration="configuration"
              :isWriteEnabled="isWriteEnabled"
              :onSwitchChange="onEdgeRecordingSettingsChange"
            />

            <v-row
              class="card-component-style"
              :class="getContentOverlayClass(isErBlockEnabled)"
            >
              <EdgeDeviceRecording
                :configuration="configuration"
                :isWriteEnabled="isWriteEnabled"
                :onRecordingSwitchChange="onToggleEdgeRecordingStatus"
                :isEdgeRecordingsFieldEnabled="isEdgeRecordingsFieldEnabled"
                :level="'camera'"
                :siteConfig="siteConfig"
                :isLimitExceeded="isLimitExceeded"
              />
            </v-row>
          </v-col>
        </v-col>
        <v-col class="d-flex flex-column pt-12 pb-12 pl-0 pr-0">
          <v-col>
            <BlockHeader :title="'Integrations'" :titleOnly="true" />
            <v-row class="card-component-style">
              <LiveStreamConfiguration
                v-if="!isHardwareDevice"
                :configuration="configuration"
                :onSwitchChange="onToggleLiveStreamStatus"
                :onChange="onSaveLiveStream"
                :onNavigateToLive="routeToLiveCamera"
                :isWriteEnabled="isWriteEnabled"
              />
            </v-row>
            <v-row class="card-component-style">
              <FtpConfiguration
                v-if="!isHardwareDevice"
                :configuration="configuration"
                :siteId="siteId"
                :cameraId="cameraId"
                :subscriptionKeys="subscriptionKeys"
                :onSwitchChange="onToggleFtpStatus"
                :isWriteEnabled="isWriteEnabled"
              />
            </v-row>
            <v-row class="card-component-style">
              <SmtpConfiguration
                v-if="!isHardwareDevice"
                :configuration="configuration"
                :siteId="siteId"
                :cameraId="cameraId"
                :subscriptionKeys="subscriptionKeys"
                :onSwitchChange="onToggleSmtpStatus"
                :isWriteEnabled="isWriteEnabled"
              />
            </v-row>
            <v-row class="card-component-style">
              <EagleEyeConfiguration
                :configuration="configuration"
                :isWriteEnabled="isWriteEnabled"
                :isCameraLevel="true"
              />
            </v-row>
          </v-col>
        </v-col>
      </v-col>
      <v-col cols="6" class="black--text pt-7">
        <v-col class="pt-3 pb-5">
          <BlockHeader
            :title="'Alarm Settings'"
            :configuration="configuration"
            :type="'camera'"
            :isWriteEnabled="isWriteEnabled"
            :onSwitchChange="onAlarmSettingsChange"
            data-test-id="camera-config-alarm-settings-header"
          />
          <v-row :class="getContentOverlayClass(isAsBlockEnabled)">
            <HumanReviewConfiguration
              :configuration="configuration"
              :clientConfig="clientConfig"
              :siteConfig="siteConfig"
              :onSwitchChange="onIsAskCrowdWorkersChange"
              :level="'camera'"
              :isWriteEnabled="isWriteEnabled"
              :isAlarmFieldEnabled="isAlarmFieldEnabled"
            />
          </v-row>
          <v-row :class="getContentOverlayClass(isAsBlockEnabled)">
            <AlarmDefinitionConfiguration
              :configuration="configuration"
              :clientConfig="clientConfig"
              :siteConfig="siteConfig"
              :onChange="onAlarmDefinitionChange"
              :level="'camera'"
              :isWriteEnabled="isWriteEnabled"
              :isAlarmFieldEnabled="isAlarmFieldEnabled"
            />
          </v-row>
        </v-col>

        <v-col class="pt-12 pb-7">
          <BlockHeader
            :title="'Disarmed Time Range'"
            :configuration="configuration"
            :onSwitchChange="onUnarmedTimeRangeSettingsChange"
            :type="'camera'"
            :isWriteEnabled="isWriteEnabled"
            data-test-id="camera-config-unarmed-time-range-header"
          />
          <v-row :class="getContentOverlayClass(isUtrBlockEnabled)">
            <UnarmedTimeRangeConfiguration
              :configuration="configuration"
              :clientConfig="clientConfig"
              :siteConfig="siteConfig"
              :onSwitchChange="onClickIsUnarmedTimeRangeEnabled"
              :onChange="onChangeUnarmedTimeRange"
              :level="'camera'"
              :isWriteEnabled="isWriteEnabled"
              :isUnarmedFieldEnabled="isUnarmedFieldEnabled"
            />
          </v-row>
        </v-col>

        <v-col class="pt-12 pb-7">
          <BlockHeader
            :title="'Health Check'"
            :configuration="configuration"
            :onSwitchChange="onHealthCheckSettingChange"
            :type="'camera'"
            :isWriteEnabled="isWriteEnabled"
            data-test-id="camera-config-health-check-header"
          />
          <v-row :class="getContentOverlayClass(isHcBlockEnabled)">
            <HealthCheckConfiguration
              :configuration="configuration"
              :clientConfig="clientConfig"
              :siteConfig="siteConfig"
              :onSwitchChange="onClickIsHealthCheckNotificationEnabled"
              :onLatestHCSwitchChange="
                onClickIsLatestHealthCheckNotificationEnabled
              "
              :onChange="onClickSaveHcDuration"
              :onTypeChange="onHcTypeChange"
              :level="'camera'"
              :isWriteEnabled="isWriteEnabled"
              :cameraId="cameraId"
              :isHcFieldEnabled="isHcFieldEnabled"
            />
          </v-row>
        </v-col>

        <v-col class="pt-12 pb-5">
          <BlockHeader
            :title="'Notifications'"
            :configuration="configuration"
            :type="'camera'"
            :isWriteEnabled="isWriteEnabled"
            :onSwitchChange="onNotificationSettingChange"
          />

          <v-row class="pr-6 mb-16">
            <v-tabs
              v-model="tab"
              :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              background-color="transparent"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              :class="[
                getContentOverlayClass(isHcBlockEnabled),
                'notification-tabs'
              ]"
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab
                value="Alarm"
                class="notification-tab"
                data-test-id="camera-config-alrm-tab"
                >Alarm</v-tab
              >
              <v-tab
                value="HC"
                class="notification-tab"
                data-test-id="camera-config-hc-tab"
                >Health Check</v-tab
              >
            </v-tabs>

            <v-tabs-items
              v-model="tab"
              class="notification-tab-items"
              :style="{
                backgroundColor:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkPrimaryColor
                    : getColors.lightPrimaryColor
              }"
            >
              <v-tab-item
                v-for="tabKey in ['Alarm', 'HC']"
                :key="tabKey"
                background-color="transparent"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkPrimaryColor
                }"
                class="notification-tab-item"
              >
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <EmailNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :siteConfig="siteConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsEmailNotificationEnabledHc
                        : onClickIsEmailNotificationEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveNotificationEmailHc
                        : onClickSaveNotificationEmail
                    "
                    :level="'camera'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <SiaNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :siteConfig="siteConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsSiaEnabledHc
                        : onClickIsSiaEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveSiaConfigHc
                        : onClickSaveSiaConfig
                    "
                    :onChangeAccountNotified="
                      isNotificationTypeHc
                        ? updateIsRelatedSiaAccountNotifiedHc
                        : updateIsRelatedSiaAccountNotified
                    "
                    :onChangeHeartbeatEnabled="
                      isNotificationTypeHc
                        ? updateIsSiaHeartbeatEnabledHc
                        : updateIsSiaHeartbeatEnabled
                    "
                    :onChangeRoutineMessageEnabled="
                      isNotificationTypeHc
                        ? updateIsSiaRoutineMessageEnabledHc
                        : updateIsSiaRoutineMessageEnabled
                    "
                    :level="'camera'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <WebhookNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :siteConfig="siteConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsWebhookEnabledHc
                        : onClickIsWebhookEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveWebhookConfigHc
                        : onClickSaveWebhookConfig
                    "
                    :level="'camera'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                  v-if="tabKey === 'Alarm'"
                >
                  <FtpNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :siteConfig="siteConfig"
                    :onSwitchChange="onClickIsFtpNotificationEnabled"
                    :onChange="onClickSaveFtpConfig"
                    :level="'camera'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <SmtpNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :siteConfig="siteConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsSmtpNotificationEnabledHc
                        : onClickIsSmtpNotificationEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveSmtpConfigHc
                        : onClickSaveSmtpConfig
                    "
                    :level="'camera'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <EvalinkNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :siteConfig="siteConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsEvalinkEnabledHc
                        : onClickIsEvalinkEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveEvalinkConfigHc
                        : onClickSaveEvalinkConfig
                    "
                    :onChangeDeviceOverrideEnabled="
                      isNotificationTypeHc
                        ? onClickIsEvalinkDeviceOverrideEnabledHc
                        : onClickIsEvalinkDeviceOverrideEnabled
                    "
                    :level="'camera'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
    <!-- Snackbar message -->
    <v-snackbar
      v-model="snackbar"
      :class="modifiedSections.length > 0 ? 'snack-bar-margin' : ''"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          btnStyle="outlined"
          class="secondary black--text"
        />
      </template>
    </v-snackbar>

    <!-- End of Snackbar message -->
    <InfoButton :rows="rows" />
    <v-overlay
      v-if="isSavingCameraConfigs || isLoading"
      class="blur-background"
    >
      <div>
        <v-progress-circular
          indeterminate
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor,
            marginLeft: '1rem'
          }"
        ></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import ProgressBar from '../ProgressBar.vue'
import { storage } from '@/provider/firebase'
import Button from '@/components/app/Button.vue'
import { DocumentReference } from '@firebase/firestore-types'
import InfoButton from '@/views/InfoButton.vue'

import { generateUniqueId } from './../../utils/Generator'
import ExcludedZones from '../excluded-zones/ExcludedZones.vue'
import DisplayDrawing from '@/components/excluded-zones/DisplayDrawing.vue'
import WebhookParams from '@/components/events/WebhookParams.vue'
import FtpNotificationConfiguration from './FtpNotificationConfiguration.vue'
import AlarmDefinitionConfiguration from './AlarmDefinitionConfiguration.vue'
import UnarmedTimeRangeConfiguration from './UnarmedTimeRangeConfiguration.vue'
import HumanReviewConfiguration from './HumanReviewConfiguration.vue'
import EmailNotificationConfiguration from './EmailNotificationConfiguration.vue'
import SiaNotificationConfiguration from './SiaNotificationConfiguration.vue'
import WebhookNotificationConfiguration from './WebhookNotificationConfiguration.vue'
import EvalinkNotificationConfiguration from './EvalinkNotificationConfiguration.vue'
import HealthCheckConfiguration from './HealthCheckConfiguration.vue'
import LiveStreamConfiguration from './LiveStreamConfiguration.vue'
import EdgeDeviceConfiguration from './EdgeDeviceConfiguration.vue'
import FtpConfiguration from './FtpConfiguration.vue'
import SmtpConfiguration from './SmtpConfiguration.vue'
import BlockHeader from './BlockHeader.vue'
import NotificationInheritHeader from './NotificationInheritHeader.vue'
import Contact from './Contact.vue'
import SmtpNotificationConfiguration from './SmtpNotificationConfiguration.vue'
import { CameraModifiedConfiguration } from 'configuration'
import { isNodeNameValid } from '../../utils/Validator'
import EagleEyeConfiguration from './EagleEyeConfiguration.vue'
import {
  checkArmedLimit,
  checkRecordingLimit,
  getDateAndTime
} from '@/utils/Utils'
import { DISARMED_TIME_RANGE } from '@/utils/Constants'
import EdgeDeviceRecording from './EdgeDeviceRecording.vue'

const namespaceCamera = { namespace: 'camera' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    ProgressBar,
    Button,
    ExcludedZones,
    DisplayDrawing,
    WebhookParams,
    FtpNotificationConfiguration,
    AlarmDefinitionConfiguration,
    UnarmedTimeRangeConfiguration,
    HumanReviewConfiguration,
    EmailNotificationConfiguration,
    SiaNotificationConfiguration,
    WebhookNotificationConfiguration,
    EvalinkNotificationConfiguration,
    HealthCheckConfiguration,
    LiveStreamConfiguration,
    EdgeDeviceConfiguration,
    FtpConfiguration,
    SmtpConfiguration,
    Contact,
    InfoButton,
    BlockHeader,
    SmtpNotificationConfiguration,
    NotificationInheritHeader,
    EagleEyeConfiguration,
    EdgeDeviceRecording
  }
})
export default class CameraConfiguration extends Vue {
  @Prop({ required: true }) configuration!: any
  @Prop({ required: true }) clientConfig!: any
  @Prop({ required: true }) siteConfig!: any
  @Prop({ required: true }) userOrOrgRef!: DocumentReference
  @Prop({ required: true }) userId: string
  @Prop({ required: true }) siteId: string
  @Prop({ required: true }) clientId: string
  @Prop() changeCameraConfigKey: () => void

  @Action('syncCurrentUser', namespaceUser) public syncCurrentUser
  @Action('updateReferenceImage', namespaceCamera)
  public updateReferenceImage: (arg0: {
    cameraId: string
    referenceImage: { url: string; path: string }
  }) => void
  @Action('removeReferenceImage', namespaceCamera)
  public removeReferenceImage: (arg0: { cameraId: string; path: any }) => void
  @Action('updateLiveStreamStatus', namespaceCamera)
  public updateLiveStreamStatus
  @Action('updateCameraConfig', namespaceCamera)
  public updateCameraConfig
  @Action('fetchReferenceImageFromPath', namespaceCamera)
  public fetchReferenceImageFromPath
  @Action('setPreviousCameraReferenceImageIsDeleted', namespaceCamera)
  public setPreviousCameraReferenceImageIsDeleted
  @Action('updateEdgeDeviceStatus', namespaceCamera)
  public updateEdgeDeviceStatus
  @Action('updateBlockToggle', namespaceConfig) public updateBlockToggle

  @Getter('getReferenceImageUrl', namespaceCamera)
  public refImgUrl
  @Getter('getPreDeleteReferenceImage', namespaceCamera)
  public preDeleteRefImg: boolean
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public projectName: string = process.env.VUE_APP_PROJECT_ID
  public snackbar: boolean = false
  public snackbarMessage: string = ''
  public isLoading: boolean = false
  public projectId: string = process.env.VUE_APP_PROJECT_ID
  public isSaving = false

  public subscriptionKeys: string[] = []
  public configurationLevel: string = 'camera'

  public title = ''
  public type = 'camera'
  // public value: boolean = false
  public blockToggles: any[] = []
  public cameraId = ''
  public cameraName = ''
  public cameraCreatedDate: string = ''
  public cameraCreatedTime: string = ''
  public cameraActivatedTime: string = ''
  public cameraActivatedDate: string = ''
  public referenceImageUpdatedDateTime: string = ''
  public cameraDocId = ''
  public cameraLocation: { lat: string; lng: string } = null
  public latitude = ''
  public longitude = ''
  public zone = ''
  public isSaved: boolean = true
  public isUploadingReferenceImage: boolean = false
  public isReferenceLoading: boolean = false
  public isReferenceLoadingError: boolean = false
  public referenceImageUrl: string | undefined = ''
  public isRefImageDeleting: boolean = false
  public cloudReferenceImageUrl: string = ''
  public oldReferenceImagePath = ''
  public uploadValue: number = 0
  public excludedZones: any[] = []
  public referenceImageMetadata:
    | {
        width: number
        height: number
      }
    | {} = { width: 0, height: 0 }
  public isSelectArea: boolean = false
  public showExcludeZones: boolean = false

  public isSmtpIntegrationEnabled: any = null
  public isFtpIntegrationEnabled: any = null

  public isLiveStreamEnabled: any = null
  public liveStreamData: any = null

  public isEdgeDeviceIntegrationEnabled: any = null
  public isEdgeRecordingEnabled: any = null
  public isEdgeDeviceEnabled = false
  public isEdgeDeviceRecordingEnabled = false
  public edgeDeviceData: any = null

  public isWriteEnabled: boolean = false

  public rows: any[] = [
    {
      icon: 'mdi-account-multiple',
      text: 'Learn more about camera configurations',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/configurations/camera-configuration',
      class: 'pr-3 black--text'
    }
  ]

  public modifiedSections: string[] = []

  public contactData: any = undefined

  public isAskCrowdWorkers: any = undefined
  public alarmDefinition: any = undefined

  public isUnarmedTimeRangeEnabled: any = null
  public unarmedTimeRangeData: any = null

  public healthCheckType: any = null
  public healthCheckDuration: any = null
  public isHealthCheckNotificationEnabled: any = null
  public isLatestHealthCheckNotificationEnabled: any = null

  public isEmailNotificationEnabled: any = undefined
  public emailData: any = undefined
  public isEmailNotificationEnabledHc: any = undefined
  public emailDataHc: any = undefined

  public siaData: any = undefined
  public isSiaEnabled: any = undefined
  public isSiaHeartbeatEnabled: any = undefined
  public isSiaRoutineMessageEnabled: any = undefined
  public isRelatedSiaAccountNotified: any = undefined
  public siaDataHc: any = undefined
  public isSiaEnabledHc: any = undefined
  public isSiaHeartbeatEnabledHc: any = undefined
  public isSiaRoutineMessageEnabledHc: any = undefined
  public isRelatedSiaAccountNotifiedHc: any = undefined

  public isWebhookTriggerEnabled: any = undefined
  public webhookData: any = undefined
  public isWebhookTriggerEnabledHc: any = undefined
  public webhookDataHc: any = undefined

  public isFTPNotificationsEnabled: any = undefined
  public ftpData: any = undefined
  public isFTPNotificationsEnabledHc: any = undefined
  public ftpDataHc: any = undefined

  public isSMTPNotificationEnabled: any = undefined
  public smtpData: any = undefined
  public isSMTPNotificationEnabledHc: any = undefined
  public smtpDataHc: any = undefined

  public isEvalinkEnabled: any = undefined
  public isEvalinkDeviceOverrideEnabled: any = undefined
  public evalinkData: any = undefined
  public isEvalinkEnabledHc: any = undefined
  public isEvalinkDeviceOverrideEnabledHc: any = undefined
  public evalinkDataHc: any = undefined

  public cameraOriginalData: any = undefined

  public isSavingCameraConfigs: boolean = false
  public isAlarmFieldEnabled: boolean = null
  public isUnarmedFieldEnabled: boolean = null
  public isHcFieldEnabled: boolean = null
  public isContactFieldEnabled: boolean = null
  public isNotificationFieldEnabled: boolean = null
  public isEdgeRecordingsFieldEnabled: boolean = null
  public isLimitExceeded: boolean = false

  // Tab View
  public tab = null
  public parentNotificationType = 'Alarm'

  get isErBlockEnabled() {
    if (this.isEdgeRecordingsFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.recordings
    }
  }

  get isAsBlockEnabled() {
    if (this.isAlarmFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.alarmSettings
    }
  }

  get isUtrBlockEnabled() {
    if (this.isUnarmedFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.unarmedTimeRange
    }
  }

  get isHcBlockEnabled() {
    if (this.isHcFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.healthCheck
    }
  }

  get isNotificationBlockEnabled() {
    if (this.isNotificationFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.notifications
    }
  }

  get isContactBlockEnabled() {
    if (this.isContactFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.contact
    }
  }

  get isDefaultNode() {
    return this.configuration?.isDefault === true
  }

  get isHardwareDevice() {
    return this.siteConfig?.isHardwareDevice ?? false
  }

  public get needsReExtractFromLiveStream() {
    if (this.configuration?.isReferenceImageLoading) {
      return false
    }
    if (
      this.configuration?.isLiveStreamEnabled === true &&
      !this.configuration?.isEdgeDeviceEnabled
    ) {
      return (
        this.configuration?.isReferenceImageLoadingError ||
        !this.configuration?.referenceImage?.url
      )
    } else if (this.configuration?.isLiveStreamEnabled === false) {
      return (
        this.configuration?.liveStream &&
        !this.configuration?.referenceImage?.url
      )
    } else {
      return false
    }
  }

  public get needsReExtractFromEdgeDevice() {
    if (this.configuration?.isReferenceImageLoading) {
      return false
    }
    if (
      this.configuration?.isEdgeDeviceEnabled === true &&
      !this.configuration?.isLiveStreamEnabled
    ) {
      return (
        this.configuration?.isReferenceImageLoadingError ||
        !this.configuration?.referenceImage?.url
      )
    } else if (this.configuration?.isEdgeDeviceEnabled === false) {
      return (
        this.configuration?.edgeDevice &&
        !this.configuration?.referenceImage?.url
      )
    } else {
      return false
    }
  }

  public async onClickExtractFromLiveStream() {
    if (this.referenceImageUrl) {
      await this.removeImage()
    }
    this.updateReferenceImageLoading()
    this.updateReferenceImageLoadingError()
    this.updateLiveStreamStatus({
      cameraId: this.cameraDocId,
      isLiveStreamEnabled: true
    })
  }

  public getContentOverlayClass(isToggleEnabled: boolean) {
    const baseClass = 'card-component-style'

    if (!isToggleEnabled && this.isUserAdmin) {
      return `${baseClass} disable-overlay-admin`
    } else if (!isToggleEnabled) {
      return `${baseClass} disable-overlay-customer`
    }

    return baseClass
  }

  public beforeUnload(e) {
    if (this.modifiedSections.length > 0) {
      const message =
        'You have unsaved changes. Are you sure you want to leave?'
      e.returnValue = message
      return message
    }
  }

  public created() {
    window.addEventListener('beforeunload', this.beforeUnload)
  }

  public destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload)
  }

  private async mounted() {
    this.isLoading = false
    this.watchConfiguration()
    this.syncForUpdates()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isNotificationTypeHc() {
    return this.parentNotificationType === 'HC'
  }

  @Watch('tab')
  public watchTab() {
    this.parentNotificationType = this.tab === 1 ? 'HC' : 'Alarm'
  }

  @Watch('refImgUrl')
  private updateReferenceImageUrl(url, isDefaultCam) {
    if (url && !isDefaultCam) {
      this.popSnackbarMessage('Processing reference image URL...')
      this.cloudReferenceImageUrl = this.refImgUrl
    } else {
      this.referenceImageUrl = this.refImgUrl
    }
  }

  public validateCameraName() {
    // Remove leading and trailing spaces from the client name
    this.cameraName = this.cameraName?.trim()
    // Validate the client name here
    const isValid = this.cameraName === ''
    return isValid
  }

  @Watch('configuration')
  public async watchConfiguration() {
    if (this.configuration && this.clientConfig) {
      this.isWriteEnabled = this.isUserAdmin
        ? true
        : this.configuration.isWriteEnabled ?? false
      this.subscriptionKeys = this.clientConfig.keys
      this.cameraId = this.configuration.cameraId
      this.cameraName = this.configuration.name
      this.cameraDocId = this.configuration.id

      this.isEdgeDeviceEnabled =
        this.configuration?.isEdgeDeviceEnabled ?? false
      this.isEdgeDeviceRecordingEnabled =
        this.configuration?.isEdgeDeviceRecordingEnabled ?? false

      this.zone = this.configuration.zone ?? ''
      this.edgeDeviceData = this.configuration?.edgeDevice
      this.excludedZones = this.getExcludedZones()
      const dateAndTime = getDateAndTime(this.configuration.createdAt)
      const activateDateAndTime = getDateAndTime(this.configuration.lastPingAt)
      this.cameraCreatedDate = dateAndTime.date
      this.cameraCreatedTime = dateAndTime.time
      this.cameraActivatedDate = activateDateAndTime.date
      this.cameraActivatedTime = activateDateAndTime.time

      if (
        !this.isRefImageDeleting &&
        this.configuration.referenceImage?.path !== this.oldReferenceImagePath
      ) {
        this.fetchReferenceImageFromPath(
          this.configuration.referenceImage?.path
        )
        this.updateReferenceImageUrl(
          this.configuration?.referenceImage?.url,
          this.isDefaultNode
        )

        this.oldReferenceImagePath = this.configuration.referenceImage?.path
      }

      this.referenceImageMetadata = this.configuration?.zones?.metadata ?? {}

      if (this.configuration?.referenceImage?.updatedAt) {
        const date = this.configuration.referenceImage.updatedAt.toDate()
        const formattedDate = date.toLocaleDateString('en-US')
        const formattedTime = date.toLocaleTimeString('en-US')
        this.referenceImageUpdatedDateTime = `on ${formattedDate}, at ${formattedTime}`
      }

      this.getCameraLocation()

      this.cameraOriginalData = {
        cameraName: this.cameraName,
        zone: this.zone,
        latitude: this.latitude,
        longitude: this.longitude
      }
    }
  }

  public hasCameraInformationChanged(): boolean {
    return (
      this.cameraName?.trim() !== this.cameraOriginalData.cameraName ||
      this.zone?.trim() !== this.cameraOriginalData.zone ||
      this.latitude !== this.cameraOriginalData.latitude ||
      this.longitude !== this.cameraOriginalData.longitude
    )
  }

  @Watch('cloudReferenceImageUrl')
  private async watchCloudReferenceImageUrl() {
    try {
      const cachedUrl = await this.getCachedURL(this.cloudReferenceImageUrl)
      if (this.cloudReferenceImageUrl) {
        this.referenceImageUrl = cachedUrl
        this.popSnackbarMessage('Reference Image Updated')
      }
    } catch (error) {
      console.log('Get cached URL Error: ', error)
    }
  }

  // calls when extracting reference image from edge device (maybe asynchronus with future requiremnts)
  public async extractReferenceImageFromEdgeDevice() {
    await this.updateEdgeDeviceStatus({
      cameraId: this.cameraDocId,
      isEdgeDeviceEnabled: true
    })
    this.updateReferenceImageLoading()
    this.updateReferenceImageLoadingError()
  }

  private updateReferenceImageLoading() {
    if (this.configuration?.isReferenceImageLoading === true) {
      this.isReferenceLoading = true
    } else {
      this.isReferenceLoading = false
    }
  }
  private updateReferenceImageLoadingError() {
    if (this.configuration?.isReferenceImageLoadingError === true) {
      this.isReferenceLoadingError = true
    } else {
      this.isReferenceLoadingError = false
    }
  }

  public positiveNumberRule = (value) => {
    const isValid = !!value && value > 0
    return isValid || 'Please enter a positive number'
  }

  public isValidNumber = (text, length: number = 3) => {
    return !!text && !isNaN(text) && text?.length === length
  }

  public threeDigitNumberRule = (value) => {
    const isValid = this.isValidNumber(value)

    return isValid || 'Please enter a 3 digit number'
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  public onClickCopy(text: string) {
    navigator.clipboard.writeText(text)
    this.popSnackbarMessage(`${text} copied.`)
  }

  data() {
    return {
      showExcludeZones: false
    }
  }

  private syncForUpdates() {
    this.syncCurrentUser(this.currentUser.id)
  }

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  private getCameraLocation() {
    this.cameraLocation = this.configuration?.location ?? {
      lat: '',
      lng: ''
    }
    this.latitude = this.cameraLocation.lat
    this.longitude = this.cameraLocation.lng
  }

  public onMarkerPositionChange(value: any) {
    this.longitude = parseFloat(value.lng).toFixed(6)
    this.latitude = parseFloat(value.lat).toFixed(6)
  }

  public async deleteReferenceImage() {
    this.isRefImageDeleting = true
    this.uploadValue = 0
    this.isReferenceLoading = false
    await this.removeReferenceImage({
      cameraId: this.cameraDocId,
      path: this.configuration.referenceImage.path
        ? this.configuration.referenceImage.path
        : ''
    })

    this.isReferenceLoading = false
    this.referenceImageUrl = null
    this.popSnackbarMessage(`Reference image removed.`)
    this.isRefImageDeleting = false
    this.setPreviousCameraReferenceImageIsDeleted({
      isDeleted: true
    })
  }

  public async removeImage() {
    this.isReferenceLoadingError = false
    this.removeReferenceImage({
      cameraId: this.cameraDocId,
      path: this.configuration.referenceImage.path
        ? this.configuration.referenceImage.path
        : ''
    })
    this.referenceImageUrl = null
  }

  public getExcludedZones() {
    try {
      return JSON.parse(this.configuration?.zones?.excludedZones ?? '[]')
    } catch (err) {
      return []
    }
  }

  public async addReferenceImage(url: string, path: string, oldPath: string) {
    if (oldPath !== '') {
      this.removeReferenceImage({ cameraId: this.cameraDocId, path: oldPath })
    }
    this.updateReferenceImage({
      cameraId: this.cameraDocId,
      referenceImage: { url: url, path: path }
    })

    await this.fetchReferenceImageFromPath(path)
    this.updateReferenceImageUrl(url, this.isDefaultNode)
    this.popSnackbarMessage(`Reference image added.`)
  }

  public async onFileChangeReferenceImage(
    e: Blob | MediaSource | Uint8Array | ArrayBuffer
  ) {
    this.referenceImageUrl = URL.createObjectURL(e as Blob)
    // Create a unique name for the image
    const referenceImageId = generateUniqueId()
    const storageRef = storage.ref('cameras/images').child(referenceImageId)

    const uploadSnap = storageRef.put(e as Blob)
    this.isUploadingReferenceImage = true
    uploadSnap.on(
      `state_changed`,
      (snapshot) => {
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      (error) => {
        this.isUploadingReferenceImage = false
      },
      async () => {
        const fileInputRef = this.$refs.referenceFileInput as any
        if (fileInputRef && fileInputRef.$refs) {
          fileInputRef.$refs.input.value = null
        }
        this.uploadValue = 100
        // set isUploadingReferenceImage to false after half a second
        setTimeout(() => {
          this.isUploadingReferenceImage = false
        }, 500)
        uploadSnap.snapshot.ref.getDownloadURL().then((url) => {
          let oldPath = this.configuration?.referenceImage?.path ?? ''
          this.addReferenceImage(url, uploadSnap.snapshot.ref.fullPath, oldPath)
        })
      }
    )
  }

  public onClickNewReference() {
    const fileInputRef = this.$refs.referenceFileInput as any
    if (fileInputRef && fileInputRef.$refs) {
      fileInputRef.$refs.input.click()
    }
  }

  public onClickSelectArea() {
    this.isSelectArea = !this.isSelectArea
    this.showExcludeZones = true
  }

  public onClickCloseModal() {
    this.isSelectArea = !this.isSelectArea
    this.showExcludeZones = false
  }

  private async getCachedURL(src: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'anonymous'
      if (src) {
        img.src = src
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height)
          resolve(canvas.toDataURL())
        }
        img.onerror = (error) => {
          resolve(null)
        }
      }
    })
  }

  public routeToLiveCamera() {
    if (this.cameraId) {
      this.$router.push({
        name: 'Live',
        params: {
          userId: this.userId,
          clientId: this.clientId,
          siteId: this.siteId,
          cameraId: this.cameraId
        }
      })
    }
  }

  public updateBlockTogglesArray(block: string, value: boolean) {
    const index = this.blockToggles.findIndex((item) => item.block === block)
    if (index > -1) {
      this.blockToggles[index].value = value
    } else {
      this.blockToggles.push({
        block: this.title,
        type: this.type,
        docId: this.configuration.id,
        value
      })
    }
  }

  public async toggleBlockSwitch(): Promise<void> {
    for (const block of this.blockToggles) {
      await this.updateBlockToggle(block)
    }
  }

  public resetSaveState() {
    this.modifiedSections = []

    this.contactData = undefined

    this.isAskCrowdWorkers = undefined
    this.alarmDefinition = undefined

    this.isUnarmedTimeRangeEnabled = null
    this.unarmedTimeRangeData = null

    this.healthCheckType = null
    this.healthCheckDuration = null
    this.isHealthCheckNotificationEnabled = null
    this.isLatestHealthCheckNotificationEnabled = null

    this.isEmailNotificationEnabled = undefined
    this.emailData = undefined
    this.isEmailNotificationEnabledHc = undefined
    this.emailDataHc = undefined

    this.siaData = undefined
    this.isSiaEnabled = undefined
    this.isSiaHeartbeatEnabled = undefined
    this.isSiaRoutineMessageEnabled = undefined
    this.isRelatedSiaAccountNotified = undefined
    this.siaDataHc = undefined
    this.isSiaEnabledHc = undefined
    this.isSiaHeartbeatEnabledHc = undefined
    this.isSiaRoutineMessageEnabledHc = undefined
    this.isRelatedSiaAccountNotifiedHc = undefined

    this.isWebhookTriggerEnabled = undefined
    this.webhookData = undefined
    this.isWebhookTriggerEnabledHc = undefined
    this.webhookDataHc = undefined

    this.isFTPNotificationsEnabled = undefined
    this.ftpData = undefined
    this.isFTPNotificationsEnabledHc = undefined
    this.ftpDataHc = undefined

    this.isSMTPNotificationEnabled = undefined
    this.smtpData = undefined
    this.isSMTPNotificationEnabledHc = undefined
    this.smtpDataHc = undefined

    this.isEvalinkEnabled = undefined
    this.isEvalinkDeviceOverrideEnabled = undefined
    this.evalinkData = undefined
    this.isEvalinkEnabledHc = undefined
    this.isEvalinkDeviceOverrideEnabledHc = undefined
    this.evalinkDataHc = undefined
    this.changeCounts = {}
  }

  public async onDiscardSaveConfigs() {
    try {
      this.changeCameraConfigKey()
      this.resetSaveState()
      this.popSnackbarMessage('Camera configs discarded!')
    } catch (error) {
      this.popSnackbarMessage('Error discarding camera configs!')
    }
  }

  public async onSaveConfigs() {
    this.isSavingCameraConfigs = true

    const cameraInformation = this.processCameraInformation()
    if (this.hasCameraInformationChanged() && !cameraInformation) {
      this.isSavingCameraConfigs = false
      return
    }
    const modifiedCameraConfiguration: CameraModifiedConfiguration = {
      cameraInformation: cameraInformation,
      contact: {
        contactPerson: this.contactData?.contactName,
        contactNumber: {
          countryCode: this.contactData?.countryCode,
          countryCallingCode: this.contactData?.countryCallingCode,
          phoneNumber: this.contactData?.phoneNumber
        },
        contactMessage: this.contactData?.message,
        receiveCallNotification: this.contactData?.isCallEnabled,
        receiveSmsNotification: this.contactData?.isSmsEnabled
      },
      liveStream: {
        isOn: this.isLiveStreamEnabled?.isLiveStreamEnabled,
        liveStreamData: this.liveStreamData
      },
      edgeDevice: {
        isOn: this.isEdgeDeviceIntegrationEnabled?.isEdgeDeviceEnabled,
        isEdgeDeviceRecordingEnabled:
          this.isEdgeRecordingEnabled?.isEdgeDeviceRecordingEnabled,
        edgeDeviceData: this.edgeDeviceData
      },
      isFtpEnabled: this.isFtpIntegrationEnabled?.isFtpEnabled,
      isSmtpEnabled: this.isSmtpIntegrationEnabled?.isSmtpEnabled,
      alarmSettings: {
        humanReview: this.isAskCrowdWorkers?.isAskCrowdWorkers,
        alarmDefinition: this.alarmDefinition?.alarmDefinition
      },
      unarmedTimeRange: {
        alwaysUnarmed: this.unarmedTimeRangeData?.isAlwaysUnarmed,
        timeZone: this.unarmedTimeRangeData?.selectedTimeZone,
        applicationDays: this.unarmedTimeRangeData?.applicableDays,
        startTime: this.unarmedTimeRangeData?.startTime,
        endTime: this.unarmedTimeRangeData?.endTime
      },
      healthCheck: {
        type: this.healthCheckType?.hcType,
        notifications:
          this.isHealthCheckNotificationEnabled
            ?.isHealthCheckNotificationEnabled,
        latestHCNotifications:
          this.isLatestHealthCheckNotificationEnabled
            ?.isLatestHealthCheckNotificationEnabled,
        frequency: this.healthCheckDuration?.durationInHours,
        unit: null
      },
      notifications: {
        alarm: {
          email: {
            isOn: this.isEmailNotificationEnabled?.isEmailNotificationEnabled,
            receivingEmails: this.emailData?.notificationEmails
          },
          siaDc09: {
            isOn: this.isSiaEnabled?.isSiaEnabled,
            notifySubFolderAccountNumber:
              this.isRelatedSiaAccountNotified?.isRelatedSiaAccountNotified,
            sendSiaHeartbeatMessage:
              this.isSiaHeartbeatEnabled?.isSiaHeartbeatEnabled,
            sendSiaRoutineMessage:
              this.isSiaRoutineMessageEnabled?.isSiaRoutineMessageEnabled,
            ipAddress: this.siaData?.siaIp,
            port: this.siaData?.siaPort
          },
          webhook: {
            isOn: this.isWebhookTriggerEnabled?.isWebhookTriggerEnabled,
            webhookArray: this.webhookData || []
          },
          ftp: {
            isOn: this.isFTPNotificationsEnabled?.isFTPNotificationsEnabled,
            host: this.ftpData?.host,
            port: this.ftpData?.port,
            username: this.ftpData?.user,
            password: this.ftpData?.password,
            filePath: this.ftpData?.filePath,
            isUploadImageSequence: this.ftpData?.isUploadImageSequence,
            isFtpPathEnabled: this.ftpData?.isFtpPathEnabled
          },
          smtp: {
            isOn: this.isSMTPNotificationEnabled?.isSMTPNotificationEnabled,
            host: this.smtpData?.host,
            port: this.smtpData?.port,
            username: this.smtpData?.user,
            password: this.smtpData?.password,
            receiverEmailAddress: this.smtpData?.receiverAddress
          },
          evalink: {
            isOn: this.isEvalinkEnabled?.isEvalinkEnabled,
            deviceOverride:
              this.isEvalinkDeviceOverrideEnabled
                ?.isEvalinkDeviceOverrideEnabled,
            authorizationKey: this.evalinkData?.Authorization,
            companyId: this.evalinkData?.companyId,
            deviceId: this.evalinkData?.deviceId,
            partition: this.evalinkData?.partition,
            partitionName: this.evalinkData?.partitionName,
            alarmZoneName: this.evalinkData?.alarmZoneName
          }
        },
        healthCheck: {
          email: {
            isOn: this.isEmailNotificationEnabledHc?.isEmailNotificationEnabled,
            receivingEmails: this.emailDataHc?.notificationEmails
          },
          siaDc09: {
            isOn: this.isSiaEnabledHc?.isSiaEnabled,
            notifySubFolderAccountNumber:
              this.isRelatedSiaAccountNotifiedHc?.isRelatedSiaAccountNotified,
            sendSiaHeartbeatMessage:
              this.isSiaHeartbeatEnabledHc?.isSiaHeartbeatEnabled,
            sendSiaRoutineMessage:
              this.isSiaRoutineMessageEnabledHc?.isSiaRoutineMessageEnabled,
            ipAddress: this.siaDataHc?.siaIp,
            port: this.siaDataHc?.siaPort
          },
          webhook: {
            isOn: this.isWebhookTriggerEnabledHc?.isWebhookTriggerEnabled,
            webhookArray: this.webhookDataHc || []
          },
          ftp: {
            isOn: this.isFTPNotificationsEnabledHc?.isFTPNotificationsEnabled,
            host: this.ftpDataHc?.host,
            port: this.ftpDataHc?.port,
            username: this.ftpDataHc?.user,
            password: this.ftpDataHc?.password,
            filePath: this.ftpDataHc?.filePath
          },
          smtp: {
            isOn: this.isSMTPNotificationEnabledHc?.isSMTPNotificationEnabled,
            host: this.smtpDataHc?.host,
            port: this.smtpDataHc?.port,
            username: this.smtpDataHc?.user,
            password: this.smtpDataHc?.password,
            receiverEmailAddress: this.smtpDataHc?.receiverAddress
          },
          evalink: {
            isOn: this.isEvalinkEnabledHc?.isEvalinkEnabled,
            deviceOverride:
              this.isEvalinkDeviceOverrideEnabledHc
                ?.isEvalinkDeviceOverrideEnabled,
            authorizationKey: this.evalinkDataHc?.Authorization,
            companyId: this.evalinkDataHc?.companyId,
            deviceId: this.evalinkDataHc?.deviceId,
            partition: this.evalinkDataHc?.partition,
            partitionName: this.evalinkDataHc?.partitionName,
            alarmZoneName: this.evalinkDataHc?.alarmZoneName
          }
        }
      }
    }

    try {
      this.isLoading = true
      await this.saveCameraDetails(modifiedCameraConfiguration)
      await this.toggleBlockSwitch()
      this.resetSaveState()
      this.isLoading = false
      this.isSavingCameraConfigs = false
      this.popSnackbarMessage('Camera configs successfully saved!')
    } catch (error) {
      this.resetSaveState()
      this.isSavingCameraConfigs = false
      this.popSnackbarMessage('Error saving camera configs!')
    }
  }

  // ****************************** INTEGRATIONS ******************************

  // --------------------------- Live Stream integration update ---------------------------
  public async onToggleLiveStreamStatus(value: boolean, isChanged: boolean) {
    this.isLiveStreamEnabled = {
      isLiveStreamEnabled: value,
      isChanged
    }
    this.addToModified('Live Stream Integration', isChanged)
  }

  public async onSaveLiveStream(liveStreamData: any) {
    this.liveStreamData = liveStreamData
    this.addToModified(
      'Live Stream Integration',
      this.liveStreamData?.isChanged
    )
  }

  // --------------------------- Edge Device integration update ---------------------------
  public async onToggleEdgeDeviceStatus(value: boolean, isChanged: boolean) {
    this.isLimitExceeded = false
    const isLimitExceeded = await checkArmedLimit(
      this.configuration.site,
      value
    )
    if (value && isLimitExceeded) {
      this.isLimitExceeded = true
      this.popSnackbarMessage(
        'Turned on Armed camera limit reached. Please turn off a camera and try again.'
      )
      return
    } else {
      this.title = 'Edge'
      this.isEdgeDeviceIntegrationEnabled = {
        isEdgeDeviceEnabled: value,
        isChanged
      }
      this.updateBlockTogglesArray(this.title, value)
      this.addToModifiedForToggles('promiseQube Integration Toggle', isChanged)
    }
  }

  public async onSaveEdgeDevice(edgeDeviceData: any) {
    this.edgeDeviceData = edgeDeviceData
    this.addToModified(
      'promiseQube Integration',
      this.edgeDeviceData?.isChanged
    )
  }

  // --------------------------- Edge Device Recording update ---------------------------
  public async onEdgeRecordingSettingsChange(
    value: boolean,
    isChanged: boolean
  ) {
    this.title = 'Recordings'
    this.updateBlockTogglesArray(this.title, value)
    this.isEdgeRecordingsFieldEnabled = value
    this.addToModified('Recordings', isChanged)
  }

  public async onToggleEdgeRecordingStatus(value: boolean, isChanged: boolean) {
    this.isLimitExceeded = false
    const isLimitExceeded = await checkRecordingLimit(
      this.configuration.site,
      value
    )
    if (value && isLimitExceeded) {
      this.isLimitExceeded = true
      this.popSnackbarMessage(
        'Recording limit reached. Please turn off another camera recording and try again.'
      )
      return
    } else {
      this.isEdgeRecordingEnabled = {
        isEdgeDeviceRecordingEnabled: value,
        isChanged
      }
      this.addToModifiedForToggles('Recordings', isChanged)
    }
  }

  // --------------------------- FTP integration update ---------------------------
  public async onToggleFtpStatus(value: boolean, isChanged: boolean) {
    this.isFtpIntegrationEnabled = { isFtpEnabled: value, isChanged }
    this.addToModified('FTP Integration', isChanged)
  }

  // --------------------------- SMTP integration update ---------------------------
  public async onToggleSmtpStatus(value: boolean, isChanged: boolean) {
    this.isSmtpIntegrationEnabled = { isSmtpEnabled: value, isChanged }
    this.addToModified('SMTP Integration', isChanged)
  }

  // ****************************** CAMERA INFORMATION ******************************

  @Watch('cameraName')
  @Watch('zone')
  @Watch('latitude')
  @Watch('longitude')
  public async onSiteDataChanged() {
    const isValid = this.validateCameraName()
    if (!isValid) {
      this.addToModified(
        'Camera Information',
        this.hasCameraInformationChanged()
      )
    } else {
      this.addToModified('Camera Information', false)
    }
  }

  public processCameraInformation(): {
    cameraId: string
    cameraName: string
    location: {
      lat: string
      lng: string
    }
    siaZone: string
  } | null {
    if (this.hasCameraInformationChanged()) {
      this.isSaved = false

      if (!this.cameraName) {
        this.popSnackbarMessage('Camera name is required')
        this.isSaved = true
        return null
      }

      if (!isNodeNameValid(this.cameraName)) {
        this.popSnackbarMessage(
          'Camera name should not contain any disallowed special characters!'
        )
        this.isSaved = true
        return null
      }

      const cameraData = {
        cameraId: this.cameraDocId,
        cameraName: this.cameraName?.trim(),
        location: {
          lat: this.latitude,
          lng: this.longitude
        },
        siaZone: this.isValidNumber(this.zone?.trim(), 3)
          ? this.zone?.trim()
          : ''
      }
      return cameraData
    }

    return null
  }

  public async saveCameraDetails(
    modifiedConfiguration: CameraModifiedConfiguration
  ) {
    try {
      const result = await this.updateCameraConfig({
        docId: this.configuration?.id,
        updatedCameraData: modifiedConfiguration,
        updatedFields: this.modifiedSections
      })
      //todo:this can be removed.this is always undefined
      if (result?.status === 'success') {
        this.popSnackbarMessage(result.message)
      } else if (result?.status === 'error') {
        this.popSnackbarMessage(result.message)
      }
    } catch (error) {
      this.popSnackbarMessage('Failed to update client configuration')
    } finally {
      this.isSavingCameraConfigs = false
    }
  }

  // ****************************** CONTACT ******************************
  public async onContactSettingsChange(value: boolean, isChanged: boolean) {
    this.title = 'Contact'
    this.updateBlockTogglesArray(this.title, value)
    this.isContactFieldEnabled = value
    this.addToModified('Contact Settings', isChanged)
  }

  public onContactChange(contactData: any) {
    this.contactData = contactData
    this.addToModified('Contact', this.contactData?.isChanged)
  }

  // *************************** ALARM SETTINGS ***************************

  public async onAlarmSettingsChange(value: boolean, isChanged: boolean) {
    this.title = 'Alarm Settings'
    this.updateBlockTogglesArray(this.title, value)
    this.isAlarmFieldEnabled = value
    this.addToModified('Alarm Settings', isChanged)
  }

  // ---------------------- Human review config update ---------------------
  public async onIsAskCrowdWorkersChange(value: boolean, isChanged: boolean) {
    this.isAskCrowdWorkers = { isAskCrowdWorkers: value, isChanged }
    this.addToModified('Human Review', isChanged)
  }

  // ---------------------- Alarm definition config update ---------------------
  public async onAlarmDefinitionChange(value: string, isChanged: boolean) {
    this.alarmDefinition = { alarmDefinition: value, isChanged }
    this.addToModified('Alarm Definition', isChanged)
  }

  // *************************** UNARMED TIME RANGE ***************************

  public async onUnarmedTimeRangeSettingsChange(
    value: boolean,
    isChanged: boolean
  ) {
    this.title = 'Unarmed Time Range'
    this.updateBlockTogglesArray(this.title, value)
    this.isUnarmedFieldEnabled = value
    this.addToModified('Disarmed TimeRange Settings', isChanged)
  }

  public async onClickIsUnarmedTimeRangeEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isUnarmedTimeRangeEnabled = {
      isUnarmedTimeRangeEnabled: value,
      isChanged
    }
    this.addToModified(DISARMED_TIME_RANGE, isChanged)
  }

  public async onChangeUnarmedTimeRange(unarmedTimeRange: object) {
    this.unarmedTimeRangeData = unarmedTimeRange
    this.addToModified(
      DISARMED_TIME_RANGE,
      this.unarmedTimeRangeData?.isChanged
    )
  }

  // *************************** HEALTH CHECK ***************************

  public async onHealthCheckSettingChange(value: boolean, isChanged: boolean) {
    this.title = 'Health Check'
    this.updateBlockTogglesArray(this.title, value)
    this.isHcFieldEnabled = value
    this.addToModified('HealthCheck Settings', isChanged)
  }

  public async onClickIsHealthCheckNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isHealthCheckNotificationEnabled = {
      isHealthCheckNotificationEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Health Check', isChanged)
  }
  public async onClickIsLatestHealthCheckNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isLatestHealthCheckNotificationEnabled = {
      isLatestHealthCheckNotificationEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Health Check', isChanged)
  }

  public async onClickSaveHcDuration(hcData: any) {
    this.healthCheckDuration = hcData
    this.addToModified('Health Check', this.healthCheckDuration?.isChanged)
  }

  public async onHcTypeChange(hcType: string, isChanged: boolean) {
    this.healthCheckType = { hcType, isChanged }
    this.addToModifiedForToggles('Health Check', isChanged)
  }

  // *************************** NOTIFICATIONS ***************************

  public async onNotificationSettingChange(value: boolean, isChanged: boolean) {
    this.title = 'Notifications'
    this.updateBlockTogglesArray(this.title, value)
    this.isNotificationFieldEnabled = value
    this.addToModified('Notification Settings', isChanged)
  }

  // --------------------------- Email config update ---------------------------
  public async onClickIsEmailNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEmailNotificationEnabled = {
      isEmailNotificationEnabled: value,
      isChanged
    }
    this.addToModified('Email Notifications Toggle', isChanged)
  }

  public async onClickSaveNotificationEmail(emailData: any) {
    this.emailData = emailData
    this.addToModified('Email Notifications', this.emailData?.isChanged)
  }

  // --------------------------- Email config update - Health Check ---------------------------
  public async onClickIsEmailNotificationEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEmailNotificationEnabledHc = {
      isEmailNotificationEnabled: value,
      isChanged
    }
    this.addToModified('Email Notifications Toggle (HC)', isChanged)
  }

  public async onClickSaveNotificationEmailHc(emailData: any) {
    this.emailDataHc = emailData
    this.addToModified('Email Notifications (HC)', this.emailDataHc?.isChanged)
  }

  // --------------------------- Sia config update ---------------------------
  public async onClickIsSiaEnabled(value: boolean, isChanged: boolean) {
    this.isSiaEnabled = { isSiaEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsSiaHeartbeatEnabled(value: boolean, isChanged: boolean) {
    this.isSiaHeartbeatEnabled = { isSiaHeartbeatEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsRelatedSiaAccountNotified(
    value: boolean,
    isChanged: boolean
  ) {
    this.isRelatedSiaAccountNotified = {
      isRelatedSiaAccountNotified: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsSiaRoutineMessageEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSiaRoutineMessageEnabled = {
      isSiaRoutineMessageEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async onClickSaveSiaConfig(siaData: any) {
    this.siaData = siaData
    this.addToModified('Sia Notifications', this.siaData?.isChanged)
  }

  // --------------------------- Sia config update - Health Check ---------------------------
  public async onClickIsSiaEnabledHc(value: boolean, isChanged: boolean) {
    this.isSiaEnabledHc = { isSiaEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle (HC)', isChanged)
  }

  public async updateIsSiaHeartbeatEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSiaHeartbeatEnabledHc = { isSiaHeartbeatEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle (HC)', isChanged)
  }

  public async updateIsRelatedSiaAccountNotifiedHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isRelatedSiaAccountNotifiedHc = {
      isRelatedSiaAccountNotified: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle (HC)', isChanged)
  }

  public async updateIsSiaRoutineMessageEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSiaRoutineMessageEnabledHc = {
      isSiaRoutineMessageEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle (HC)', isChanged)
  }

  public async onClickSaveSiaConfigHc(siaData: any) {
    this.siaDataHc = siaData
    this.addToModified('Sia Notifications (HC)', this.siaDataHc?.isChanged)
  }

  // --------------------------- Webhook config update ---------------------------
  public async onClickIsWebhookEnabled(value: boolean, isChanged: boolean) {
    this.isWebhookTriggerEnabled = { isWebhookTriggerEnabled: value, isChanged }
    this.addToModified('Webhook Notifications', isChanged)
  }

  public async onClickSaveWebhookConfig(webhookData: any) {
    this.webhookData = webhookData
    this.addToModified('Webhook Notifications', this.webhookData?.isChanged)
  }

  // --------------------------- Webhook config update - Health Check ---------------------------
  public async onClickIsWebhookEnabledHc(value: boolean, isChanged: boolean) {
    this.isWebhookTriggerEnabledHc = {
      isWebhookTriggerEnabled: value,
      isChanged
    }
    this.addToModified('Webhook Notifications (HC)', isChanged)
  }

  public async onClickSaveWebhookConfigHc(webhookData: any) {
    this.webhookDataHc = webhookData
    this.addToModified(
      'Webhook Notifications (HC)',
      this.webhookDataHc?.isChanged
    )
  }

  // --------------------------- FTP config update ---------------------------
  public async onClickIsFtpNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isFTPNotificationsEnabled = {
      isFTPNotificationsEnabled: value,
      isChanged
    }
    this.addToModified('FTP Notifications', isChanged)
  }

  public async onClickSaveFtpConfig(ftpData: any) {
    this.ftpData = ftpData
    this.addToModified('FTP Notifications', this.ftpData?.isChanged)
  }

  // --------------------------- SMTP config update ---------------------------
  public async onClickIsSmtpNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSMTPNotificationEnabled = {
      isSMTPNotificationEnabled: value,
      isChanged
    }
    this.addToModified('SMTP Notifications', isChanged)
  }

  public async onClickSaveSmtpConfig(smtpNotificationData: any) {
    this.smtpData = smtpNotificationData
    this.addToModified('SMTP Notifications', this.smtpData?.isChanged)
  }

  // --------------------------- SMTP config update - Health Check ---------------------------
  public async onClickIsSmtpNotificationEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSMTPNotificationEnabledHc = {
      isSMTPNotificationEnabled: value,
      isChanged
    }
    this.addToModified('SMTP Notifications (HC)', isChanged)
  }

  public async onClickSaveSmtpConfigHc(smtpNotificationData: any) {
    this.smtpDataHc = smtpNotificationData
    this.addToModified('SMTP Notifications (HC)', this.smtpDataHc?.isChanged)
  }

  // --------------------------- Evalink config update ---------------------------
  public async onClickIsEvalinkEnabled(value: boolean, isChanged: boolean) {
    this.isEvalinkEnabled = {
      isEvalinkEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Evalink Notifications Toggle', isChanged)
  }

  public async onClickIsEvalinkDeviceOverrideEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEvalinkDeviceOverrideEnabled = {
      isEvalinkDeviceOverrideEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Evalink Notifications Toggle', isChanged)
  }

  public async onClickSaveEvalinkConfig(evalinkData: any) {
    this.evalinkData = evalinkData
    this.addToModified('Evalink Notifications', this.evalinkData?.isChanged)
  }

  // --------------------------- Evalink config update - Health Check ---------------------------
  public async onClickIsEvalinkEnabledHc(value: boolean, isChanged: boolean) {
    this.isEvalinkEnabledHc = {
      isEvalinkEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Evalink Notifications Toggle (HC)', isChanged)
  }

  public async onClickIsEvalinkDeviceOverrideEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEvalinkDeviceOverrideEnabledHc = {
      isEvalinkDeviceOverrideEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Evalink Notifications Toggle (HC)', isChanged)
  }

  public async onClickSaveEvalinkConfigHc(evalinkData: any) {
    this.evalinkDataHc = evalinkData
    this.addToModified(
      'Evalink Notifications (HC)',
      this.evalinkDataHc?.isChanged
    )
  }
  public addToModified(name: string, isChanged: boolean) {
    this.modifiedSections = this.modifiedSections.filter(
      (item) => item !== name
    )
    if (isChanged) {
      this.modifiedSections.push(name)
    }
  }
  public changeCounts: { [key: string]: number } = {}

  public addToModifiedForToggles(name: string, isChanged: boolean) {
    // Increment or decrement the change count for the section
    this.changeCounts[name] =
      (this.changeCounts[name] || 0) + (isChanged ? 1 : -1)
    // Update modifiedSections based on change count
    if (this.changeCounts[name] > 0) {
      if (!this.modifiedSections.includes(name)) {
        this.modifiedSections.push(name)
      }
    } else {
      this.modifiedSections = this.modifiedSections.filter(
        (item) => item !== name
      )
    }
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
}

.admin-main-container {
  display: flex;
  flex-direction: column;
  background-color: #333333;
}

.loading-container {
  min-height: calc(100vh - 60px);
}

.loader-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.camera-container {
  text-align: left;
  display: flex;
  align-items: center;
  align-content: center;
  height: 50px;
}

.snack-bar-margin {
  margin-bottom: 120px;
}

input[type='checkbox'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  border: 1px solid #ffd42a;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;
  -webkit-transition: box-shadow 200ms;
  -moz-transition: box-shadow 200ms;
  border-radius: 3px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
/* input checked border color */
input[type='checkbox']:checked {
  border-color: #ffd42a;
  background-color: #ffd42a;
}
/* checkbox checked */
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 8px;
  margin-top: 1px;
}
.pointer {
  cursor: pointer;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}
.progress-bar {
  width: 100%;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  font-size: 10px;
  transform: rotate(360deg);
}
.save-btn,
.close-btn,
.image-btn {
  text-transform: unset !important;
}

.component {
  width: 100%;
  height: 100%;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltip-copy-text {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltip-copy-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltip-copy-text {
  visibility: visible;
  opacity: 1;
}

.custome-width.v-btn:not(.v-btn--round).v-size--default {
  min-width: 0px;
}
.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.yellow-boarder {
  width: 100%;
  box-shadow: none !important;
  border: solid #ffd42a 2.5px;
  background-color: transparent;
}
.email-row {
  display: flex;
  align-items: center;
}
.email-row {
  display: flex;
  align-items: center;
}
.left-align-btn {
  text-align: left !important;
}
#custom-tab-items {
  background-color: transparent !important;
}
.card-component-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: solid #dadada 1px;
  border-right: solid #dadada 1px;
}

.disable-overlay-customer {
  background-color: #f2f2f2 !important;
}

.disable-overlay-admin {
  background-color: rgba(147, 141, 118, 0.3) !important;
}

.sticky-bar {
  position: fixed;
  bottom: 36px;
  right: 0;
  left: 257px;
  background-color: #f5f5f5;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  z-index: 10;
}

.sticky-button-group {
  display: flex;
  gap: 1em;
}

.blur-background {
  backdrop-filter: blur(3px);
}

.notification-tabs {
  width: 100%;
  padding: 0;
  margin: 0;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.notification-tab {
  flex: 1;
  max-width: inherit;
  text-transform: initial;
  width: 100%;
}

.notification-tab-items {
  width: 100%;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
</style>
