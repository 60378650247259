var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex"},[_c('Button',{staticClass:"logout-btn",class:`${_vm.getBtnStyle()}`,attrs:{"btnText":"Logout","btnStyle":"outlined","data-test-id":"logout-btn"},on:{"onButtonClick":function($event){return _vm.logout()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{staticClass:"logout-btn-icon",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_vm._v("mdi-logout-variant ")])]},proxy:true}])}),_c('Button',{staticClass:"settings-btn",class:_vm.getBtnStyle(),attrs:{"btnText":"Settings","btnStyle":"outlined"},on:{"onButtonClick":function($event){return _vm.goToAccSettings()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        }),attrs:{"size":"20"}},[_vm._v("mdi-account")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }