<template>
  <v-row
    class="d-flex justify-space-between align-items-center justify-content-center"
    v-if="getTotalAlarmQuotaPerMonth.isQuotaEnabled"
  >
    <v-col class="d-flex justify-space-between flex-wrap">
      <span class="summary-title col-12">Monthly Quota Summary</span>
      <v-row class="d-flex justify-center col-xl-12">
        <div class="incident-summary">
          <v-col
            :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
              isUserAdmin ? 'col-lg-12' : 'col-lg-3'
            } col-xl-2`"
          >
            <p class="text-md-body-1 text-body-2">Allocated Quota</p>
            <p class="bold-text text-md-h5 text-xl-h4 text-h6">
              {{ getTotalAlarmQuotaPerMonth.alarmQuota }}
            </p>
          </v-col>
        </div>

        <div class="incident-summary">
          <v-col
            :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
              isUserAdmin ? 'col-lg-12' : 'col-lg-3'
            } col-xl-2`"
          >
            <p class="text-md-body-1 text-body-2">Used Quota</p>
            <p class="bold-text text-md-h5 text-xl-h4 text-h6">
              {{ getTotalAlarmCountPerMonth }}
            </p>
          </v-col>
        </div>

        <div class="incident-summary">
          <v-col
            :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
              isUserAdmin ? 'col-lg-12' : 'col-lg-3'
            } col-xl-2`"
          >
            <p class="text-md-body-1 text-body-2">Used Quota Percentage</p>
            <p class="bold-text text-md-h5 text-xl-h4 text-h6">
              {{
                (
                  (getTotalAlarmCountPerMonth /
                    getTotalAlarmQuotaPerMonth.alarmQuota) *
                  100
                ).toFixed(2)
              }}%
            </p>
          </v-col>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { debounce } from 'lodash'

const namespaceConfig = { namespace: 'config' }
const namespaceStatistics = { namespace: 'statistics' }
const namespaceUser = { namespace: 'user' }

@Component
export default class QuotaSummaryBlock extends Vue {
  @Prop() public userId: string
  @Prop() public clientId: string

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getUsedAlarmQuotaPerMonth', namespaceStatistics)
  public getTotalAlarmCountPerMonth: number
  @Getter('getTotalAlarmQuotaPerMonth', namespaceStatistics)
  public getTotalAlarmQuotaPerMonth: {
    isQuotaEnabled: boolean
    alarmQuota: number
  }

  @Action('setAllocatedQuota', namespaceStatistics) setAllocatedQuota
  @Action('fetchQuotaData', namespaceStatistics) fetchQuotaData

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.fetchChartData()
  }

  public fetchChartData = debounce(async () => {
    await this.setAllocatedQuota(this.clientId)
    await this.fetchQuotaData({ clientId: this.clientId })
  }, 300)
}
</script>

<style>
.bold-text {
  font-size: 2.5rem;
  font-weight: bolder;
}

.summary-title {
  align-items: center;
  font-weight: bold;
}
.usage-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 140px;
}
</style>
