import { store } from '@/store'
import {
  cameraCollection,
  clientCollection,
  siteCollection,
  eagleEyeAccountCollection,
  userCollection,
  userProvider,
  functions
} from '@/provider/firebase'
import { firestoreAction } from 'vuexfire'
import firebase from 'firebase/compat/app'
import { HttpsCallableResult } from '@firebase/functions-types'
import { DocumentReference } from '@firebase/firestore-types'
import router from '@/router'
import { getDefaultState as getConfigDefaultState } from '../config/state'

const actions = {
  bindAllUser: firestoreAction(
    async ({ state, bindFirestoreRef, commit }: any) => {
      commit('setIsLoadingUsers', true)

      await bindFirestoreRef('allUser', userCollection, {
        reset: true
      })

      commit('setIsLoadingUsers', false)
    }
  ),
  unbindAllUser: firestoreAction(({ unbindFirestoreRef }) => {
    return unbindFirestoreRef('allUser')
  }),
  updateIsAskCrowdWorkersStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isAskCrowdWorkers: any
    }
  ): Promise<void> {
    return userProvider.changeIsAskCrowdWorkersStatus(
      payload.currentUserId,
      payload.isAskCrowdWorkers
    )
  },
  updateIsNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isNotificationEnabled: any
    }
  ): Promise<void> {
    return userProvider.changeIsNotificationEnabledStatus(
      payload.currentUserId,
      payload.isNotificationEnabled
    )
  },
  updateUnarmedTimeRange(
    { state }: any,
    payload: {
      currentUserId: string
      unarmedTimeRange: any
    }
  ): Promise<void> {
    return userProvider.changeUnarmedTimeRange(
      payload.currentUserId,
      payload.unarmedTimeRange
    )
  },
  updateContact(
    { state }: any,
    payload: {
      currentUserId: string
      contact: any
    }
  ): Promise<void> {
    return userProvider.changeContact(payload.currentUserId, payload.contact)
  },
  async saveCompanyName({ commit, getters }, { userId, companyName }) {
    try {
      await userProvider.changeCompanyName(userId, companyName)
      const isCurrentUser = userId === getters.currentUser.id
      commit('setCompanyName', { userId, companyName, isCurrentUser })
      if (isCurrentUser) {
        // Optionally refresh user data if needed
        const updatedUserData = await userProvider.fetchUserById(userId)
        commit('setCurrentUser', updatedUserData)
      }
    } catch (error) {
      console.error('Failed to save company name:', error)
      throw new Error('Failed to save company name.')
    }
  },
  sendEmail(
    { state }: any,
    payload: {
      useremail: string
      subject: string
      html: string
      attachments: string
    }
  ): Promise<DocumentReference> {
    return userProvider.sendEmail(
      payload.useremail,
      payload.subject,
      payload.html,
      payload.attachments
    )
  },
  async updateClientForEagleEye(
    _: unknown,
    {
      userId,
      clientId
    }: {
      userId: string
      clientId: string
    }
  ): Promise<void> {
    await eagleEyeAccountCollection.doc(userId).set(
      {
        userId,
        clientId
      },
      { merge: true }
    )
  },
  async autoClearClientUserDoc(_: unknown, userId: string): Promise<void> {
    await eagleEyeAccountCollection.doc(userId).delete()
  },
  async syncConnectedEENAccounts({ commit, dispatch }: any, clientId: string) {
    if (clientId) {
      const clientSnapshot = await clientCollection
        .where('clientId', '==', clientId)
        .get()
      if (clientSnapshot.empty) {
        //  TODO Recheck throwing error
        return
      }
      const clientDocId = clientSnapshot.docs[0].ref.id
      const accountList = []
      await eagleEyeAccountCollection
        .where('reference.client', '==', clientDocId)
        .get()
        .then(async (documentSnapshot) => {
          documentSnapshot.docs.map((doc) => {
            const data = doc.data()
            const updatedAt = data.updatedAt // Timestamp
            const expires = new Date(
              updatedAt.toDate().setMonth(updatedAt.toDate().getMonth() + 3)
            )
            accountList.push({
              client: data?.reference?.client,
              expires,
              vms_account: data.vms_account,
              isTokenValid: data?.auth?.isTokenValid
            })
          })
        })
      commit('setEagleEyeAccounts', accountList)
    }
  },
  syncCurrentUser({}: any, userId: string): void {
    userProvider.subscribeForUserData(userId)
  },
  async createUser(
    { commit }: any,
    payload: {
      display_name: string
      first_name: string
      last_name: string
      email: string
      role: string
      userId?: string
      clientId?: string
    }
  ): Promise<boolean> {
    commit('setLoading', true, { root: true })
    store.dispatch('clearError', { root: true })

    try {
      await userProvider.createUser(payload)
      store.commit('setLoading', false, { root: true })
      return await Promise.resolve(true)
    } catch (error) {
      store.commit('setError', error, { root: true })
      store.commit('setLoading', false, { root: true })
    }
  },
  reset(): void {
    store.commit('user/setCurrentUser', {})
  },
  async loadUserToVuex(
    {}: any,
    payload: { currentUser: { email: any } }
  ): Promise<void> {
    try {
      const user = await userProvider.load(payload.currentUser.email)
      store.commit('user/setCurrentUser', user)
    } catch (error) {
      console.error('error while loading user', error)
    }
  },
  login({ commit }: any, payload: { email: string; password: string }): any {
    store.commit('setLoading', true, { root: true })
    store.dispatch('clearError', { root: true })
    let userData = null
    return userProvider
      .login(payload)
      .then(async (data: any) => {
        userData = data
        return store.dispatch('user/read', data.uid)
      })
      .then(async () => {
        // Todo : Fix the issue with the intended route : https://gitlab.com/aoye/web/aoye_front_end/-/issues/1395
        // const intendedRoute = JSON.parse(
        //   sessionStorage.getItem('intended_route') as string
        // )
        // if (intendedRoute) {
        //   router.push(intendedRoute)
        //   sessionStorage.removeItem('intended_route')
        // } else {
        //   router.push({ name: 'Home' })
        // }

        store.commit('user/setCurrentUser', userData)

        // Apply theme
        if (userData?.role === 'Administrator') {
          const isDarkModeEnabled = userData?.isDarkModeEnabled ?? false
          // vuetify.theme.dark = isDarkModeEnabled
          store.commit('config/setIsDarkModeEnabled', isDarkModeEnabled)
        } else {
          // vuetify.theme.dark = false
          store.commit('config/setIsDarkModeEnabled', false)
        }

        if (userData?.isTermsAndConditionsAccepted) {
          if (userData?.role === 'Worker') {
            router.push({ name: 'WorkerHome' })
          } else {
            // if there is query parameter called 'code' then redirect to the /input/?code=<code>
            const code = router.currentRoute.query?.code
            if (code) {
              router.push({ name: 'EagleEyeConnect', query: { code } })
            } else {
              router.push({ name: 'Alarms' })
            }
          }
        } else {
          router.push({ name: 'TermsAndConditions' })
        }

        store.commit('setLoading', false, { root: true })
        store.dispatch('clearError', { root: true })
        Promise.resolve(true)
        return userData
      })
  },
  async approveUser({ commit }, userId: string) {
    try {
      await userCollection.doc(userId).update({ isApproved: true })
      return true
    } catch (error) {
      throw new Error('Failed to approve user: ')
    }
  },

  logout(): void {
    userProvider.unsubscribeForUserData()
    //@ts-ignore
    window.userSync = false
    store
      .dispatch('clearAll', true, { root: true })
      .then(() => {
        store.dispatch('config/unSubscribeFromGlobalTimeFilter')
        store.dispatch('worker/unBindAllWorkerStatus')
        store.dispatch('user/unbindAllUser')
        store.dispatch('worker/unBindRegisteredWorkers')
        store.dispatch('config/unbindAllNodes')
      })
      .then(() => {
        userProvider
          .logout()
          .then(() => {
            router.push('/login')
            return true
          })
          .catch((error) => {
            console.error(error)
          })
        return true
      })
      .catch((error) => {
        console.error(error)
      })
  },
  forceLogoutUser(
    { state }: any,
    payload: {
      userIds: string[]
    }
  ): Promise<boolean> {
    return functions
      .httpsCallable('forceLogoutUser')({
        userIds: payload.userIds
      })
      .then((response) => {
        return response?.data
      })
      .catch(() => false)
  },
  async searchUserByEmail(
    { state }: any,
    payload: { email: string }
  ): Promise<any[]> {
    try {
      const email = payload.email.toLowerCase()

      const userQuerySnapshot = await userCollection
        .where('email', '==', email)
        .get()

      // Extract user data from query snapshot
      const users = userQuerySnapshot.docs.map((doc) => {
        const userData = doc.data()
        return {
          id: doc.id,
          email: userData.email,
          firstName: userData.first_name,
          lastName: userData.last_name,
          role: userData.role
        }
      })
      return users
    } catch (error) {
      console.error('Error in searchUserByEmail action:', error)
      throw error
    }
  },

  async restorePassword({ state }: any, emailAddress: string): Promise<any> {
    return await userProvider.restorePassword(emailAddress)
  },
  read({ commit }: any, docId: any): Promise<boolean> {
    commit('setLoading', true, { root: true })
    store.dispatch('clearError', { root: true })
    userProvider.subscribeForUserData(docId)
    store.commit('setLoading', false, { root: true })
    return Promise.resolve(true)
  },
  async deleteUserById({ dispatch }: any, payload: any): Promise<unknown> {
    if (payload?.userFirestoreId) {
      const userRef = userCollection.doc(payload.userFirestoreId)
      const documentRef = await userRef.get()
      const userData = documentRef.data()
      const deletedResult = await dispatch('deleteUser', [userData])
      return Promise.resolve(deletedResult)
    }
    return Promise.reject('Could not find userFirestoreId')
  },
  async signInWorker({ commit }): Promise<unknown> {
    const id = await userProvider.signInWorker()
    store.commit('user/setCurrentUser', { id })
    return id
  },
  async setIsTermsAndRegulationsAccepted(
    { commit },
    data: {
      userId: string
      isTermsAndConditionsAccepted: boolean
      userRole: string
    }
  ): Promise<void> {
    await userCollection
      .doc(data.userId)
      .update({
        isTermsAndConditionsAccepted: data.isTermsAndConditionsAccepted
      })
      .then(() => {
        if (data.isTermsAndConditionsAccepted) {
          store.commit('user/setIsInitialUser', true)

          if (data.userRole === 'Worker') {
            router.push('/worker-home', () => {})
          } else if (data.userRole === 'Administrator') {
            router.push('/alarms', () => {})
          } else {
            router.push('/sandbox', () => {})
          }
        }
      })
  },
  async updateIsDarkModeEnabled(
    { commit },
    data: {
      userId: string
      isDarkModeEnabled: boolean
      userRole: string
    }
  ): Promise<void> {
    const { userId, isDarkModeEnabled, userRole } = data
    if (userRole === 'Administrator') {
      try {
        await userCollection.doc(userId).update({
          isDarkModeEnabled: isDarkModeEnabled
        })
      } catch (error) {
        console.error('Error updating dark mode config: ', error)
      }
    }
  },
  async updateUserRefreshEnabled(
    { state }: any,
    payload: {
      currentUserId: string
      isAlarmRefreshEnabled: boolean
    }
  ): Promise<void> {
    const { currentUserId, isAlarmRefreshEnabled } = payload
    store.commit('config/setIsAlarmRefreshEnabled', isAlarmRefreshEnabled)

    return await userProvider.changeUserRefreshEnabledStatus(
      currentUserId,
      isAlarmRefreshEnabled
    )
  },
  async updateRefreshSettings(
    { state }: any,
    payload: {
      currentUserId: string
      autoRefreshSettings: {
        autoRefreshPeriod: number
        unit: string
        value: number
      }
    }
  ): Promise<void> {
    const { currentUserId, autoRefreshSettings } = payload

    const { autoRefreshSettings: defaultAutoRefreshSettings } =
      getConfigDefaultState()

    const autoRefreshData = autoRefreshSettings ?? defaultAutoRefreshSettings

    store.commit('config/setAutoRefreshSettings', autoRefreshData)

    return await userProvider.changeUserRefreshSettings(
      currentUserId,
      autoRefreshData
    )
  },
  updateIsWebhookTriggerEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isWebhookTriggerEnabled: any
    }
  ): Promise<void> {
    return userProvider.changeIsWebhookTriggerEnabledStatus(
      payload.currentUserId,
      payload.isWebhookTriggerEnabled
    )
  },
  updateWebhookTriggerConfig(
    { state }: any,
    payload: {
      currentUserId: string
      webhookTriggerData: any
    }
  ): Promise<void> {
    return userProvider.changeWebhookTriggerConfig(
      payload.currentUserId,
      payload.webhookTriggerData
    )
  },
  updateColors: ({ commit, state }: any) => {
    let colors = state.colors
    commit('setColors', colors)
  },
  updateEvalinkConfig(
    { state }: any,
    payload: {
      currentUserId: string
      evalinkData: any
    }
  ): Promise<void> {
    return userProvider.addEvalinkConfig(
      payload.currentUserId,
      payload.evalinkData
    )
  },
  updateIsEvalinkNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isEvalinkEnabled: any
    }
  ): Promise<void> {
    return userProvider.changeIsEvalinkNotificationEnabledStatus(
      payload.currentUserId,
      payload.isEvalinkEnabled
    )
  },
  updateIsEvalinkDeviceOverrideEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isEvalinkDeviceOverrideEnabled: any
    }
  ): Promise<void> {
    return userProvider.changeIsEvalinkDeviceOverrideEnabledStatus(
      payload.currentUserId,
      payload.isEvalinkDeviceOverrideEnabled
    )
  },
  updateSiaConfig(
    { state }: any,
    payload: { currentUserId: string; siaData: any }
  ): Promise<void> {
    return userProvider.addSIAConfig(payload.currentUserId, payload.siaData)
  },
  updateIsSiaNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isSiaEnabled: any
    }
  ): Promise<void> {
    return userProvider.changeIsSIANotificationEnabledStatus(
      payload.currentUserId,
      payload.isSiaEnabled
    )
  },
  updateIsSiaHeartbeatEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isSiaHeartbeatEnabled: boolean
    }
  ): Promise<void> {
    return userProvider.changeIsSIAHeartbeatEnabledStatus(
      payload.currentUserId,
      payload.isSiaHeartbeatEnabled
    )
  },
  updateIsRelatedSiaAccountNotifiedStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isRelatedSiaAccountNotified: boolean
    }
  ): Promise<void> {
    return userProvider.changeIsRelatedSIAAccountNotified(
      payload.currentUserId,
      payload.isRelatedSiaAccountNotified
    )
  },
  updateIsSiaRoutineMessageEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isSiaRoutineMessageEnabled: boolean
    }
  ): Promise<void> {
    return userProvider.changeIsSIARoutineMessageEnabledStatus(
      payload.currentUserId,
      payload.isSiaRoutineMessageEnabled
    )
  },
  updateIsEmailNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isEmailNotificationEnabled: any
    }
  ): Promise<void> {
    return userProvider.changeIsEmailNotificationEnabledStatus(
      payload.currentUserId,
      payload.isEmailNotificationEnabled
    )
  },
  updateNotificationEmails(
    { state }: any,
    payload: {
      currentUserId: string
      notificationEmails: { email: string }[]
    }
  ): Promise<void> {
    return userProvider.changeNotificationEmails(
      payload.currentUserId,
      payload.notificationEmails
    )
  },
  updateIsFTPNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isFTPEnabled: any
    }
  ): Promise<void> {
    return userProvider.changeIsFTPNotificationEnabledStatus(
      payload.currentUserId,
      payload.isFTPEnabled
    )
  },
  updateFTPConfig(
    { state }: any,
    payload: { currentUserId: string; ftpData: any }
  ): Promise<void> {
    return userProvider.addFTPConfig(payload.currentUserId, payload.ftpData)
  },
  updateAlarmDefinitionConfig: firestoreAction(
    (
      { state }: any,
      payload: {
        currentUserId: string
        alarmDefinitionConfig: string
      }
    ) => {
      return userProvider.updateAlarmDefinitionConfig(
        payload.currentUserId,
        payload.alarmDefinitionConfig
      )
    }
  ),
  updateHikCentralNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isHikCentralEnabled: boolean
    }
  ): Promise<void> {
    return userProvider.updateHikCentralNotificationEnabledStatus(
      payload.currentUserId,
      payload.isHikCentralEnabled
    )
  },
  updateHikCentralConfig(
    { state }: any,
    payload: {
      currentUserId: string
      hikCentralData: any
    }
  ): Promise<void> {
    return userProvider.updateHikCentralConfig(
      payload.currentUserId,
      payload.hikCentralData
    )
  },
  updateHikCentralAcknowledgementEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isAcknowledgementEnabled: boolean
    }
  ): Promise<void> {
    return userProvider.updateHikCentralAcknowledgementEnabledStatus(
      payload.currentUserId,
      payload.isAcknowledgementEnabled
    )
  },
  updateGenetecEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isGenetecEnabled: boolean
    }
  ): Promise<void> {
    return userProvider.updateGenetecEnabledStatus(
      payload.currentUserId,
      payload.isGenetecEnabled
    )
  },
  updateGenetecConfig(
    { state }: any,
    payload: {
      currentUserId: string
      genetecData: any
    }
  ): Promise<void> {
    return userProvider.updateGenetecConfig(
      payload.currentUserId,
      payload.genetecData
    )
  },
  updateGenetecAcknowledgementEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isAcknowledgementEnabled: boolean
    }
  ): Promise<void> {
    return userProvider.updateGenetecAcknowledgementEnabledStatus(
      payload.currentUserId,
      payload.isAcknowledgementEnabled
    )
  },
  updateIsHealthCheckNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isHealthCheckNotificationEnabled: boolean
    }
  ): Promise<void> {
    return userProvider.changeIsHealthCheckEnabledStatus(
      payload.currentUserId,
      payload.isHealthCheckNotificationEnabled
    )
  },
  updateIsLatestHealthCheckNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isLatestHealthCheckNotificationEnabled: boolean
    }
  ): Promise<void> {
    return userProvider.changeIsLatestHealthCheckEnabledStatus(
      payload.currentUserId,
      payload.isLatestHealthCheckNotificationEnabled
    )
  },
  updateHcDuration(
    { state }: any,
    payload: {
      currentUserId: string
      healthCheckDuration: number
    }
  ): Promise<void> {
    return userProvider.changeHcDuration(
      payload.currentUserId,
      payload.healthCheckDuration
    )
  },
  async getUserByDocId(
    { state }: any,
    docId: string
  ): Promise<firebase.firestore.DocumentData | null> {
    const user = await userCollection.doc(docId).get()
    if (user.exists) {
      return user.data()
    }
    return null
  },
  updateHcType(
    { state }: any,
    payload: {
      currentUserId: string
      healthCheckType: string
    }
  ): Promise<void> {
    return userProvider.changeHcType(
      payload.currentUserId,
      payload.healthCheckType
    )
  },
  getUserKeys({ state }: any, payload: string) {
    return userProvider.getUserKeys(payload)
  },
  bindGlobalCameras: async (
    { commit },
    payload: {
      userId: string
    }
  ) => {
    try {
      commit('setGlobalLiveIsLoading', true)
      commit('setGlobalCamera', [])

      const clientsQuery = clientCollection
        .where('users', 'array-contains', payload.userId)
        .get()

      const clientsSnapshot = await clientsQuery
      const clientPromises = []

      clientsSnapshot.forEach((client) => {
        const clientData = client.data()
        const sitesQuery = siteCollection
          .where('client', '==', client.ref)
          .where('users', 'array-contains', payload.userId)
          .get()

        const clientPromise = sitesQuery.then(async (sitesSnapshot) => {
          const sitePromises = []

          sitesSnapshot.forEach(async (site) => {
            const siteData = site.data()
            const camerasQuery = cameraCollection
              .where('site', '==', site.ref)
              .where('users', 'array-contains', payload.userId)
              .get()

            const cameraPromise = camerasQuery.then((camerasSnapshot) => {
              const cameraItems = camerasSnapshot.docs.map((camera) => {
                const cameraData = camera.data()

                return {
                  key: camera.id,
                  id: camera.id,
                  itemId: cameraData.cameraId,
                  siteId: siteData.siteId,
                  clientId: clientData.clientId,
                  cameraId: cameraData.cameraId,
                  name: cameraData.name,
                  type: 'camera',
                  userFirestoreId: payload.userId,
                  clientFirestoreId: client.id,
                  siteFirestoreId: site.id,
                  cameraFirestoreId: camera.id,
                  liveStream: cameraData.liveStream,
                  siteName: siteData.name,
                  clientName: clientData.name,
                  userName: payload.userId,
                  isSiteHardwareDevice: siteData.isHardwareDevice,
                  isAuthenticated:
                    cameraData.isAuthenticated !== null
                      ? cameraData.isAuthenticated
                      : true,
                  isEdgeDeviceEnabled:
                    cameraData.isEdgeDeviceEnabled !== null
                      ? cameraData.isEdgeDeviceEnabled
                      : false,
                  location: cameraData.location,
                  isLiveStreamEnabled: cameraData.isLiveStreamEnabled,
                  edgeLiveStreamLimit: siteData?.edgeLimits?.liveStreamingLimit,
                  isEdgeCameraUnarmed: cameraData?.isEdgeCameraUnarmed ?? false
                }
              })

              return cameraItems
            })

            sitePromises.push(cameraPromise)
          })

          return Promise.all(sitePromises).then((siteCameraItems) => {
            return siteCameraItems.flat()
          })
        })

        clientPromises.push(clientPromise)
      })

      const allCameraItems = await Promise.all(clientPromises).then(
        (clientCameraItems) => {
          return clientCameraItems.flat()
        }
      )

      commit('setGlobalCamera', allCameraItems)
      commit('setGlobalLiveIsLoading', false)
    } catch (error) {
      console.error('Error binding global cameras:', error)
    }
  },
  bindAllGlobalCameras: async ({ commit }) => {
    try {
      commit('setGlobalLiveIsLoading', true)
      commit('setGlobalCamera', [])

      const allCameraItems = []

      const userDocs = await userCollection.get()

      const fetchPromises = userDocs.docs.map(async (doc) => {
        const docRef = doc.ref
        const userData = doc.data()
        const clientsQuery = clientCollection
          .where('users', 'array-contains', doc.id)
          .get()
        const clientsSnapshot = await clientsQuery

        const clientPromises = clientsSnapshot.docs.map(async (client) => {
          const clientData = client.data()
          const sitesQuery = siteCollection
            .where('client', '==', client.ref)
            .where('users', 'array-contains', doc.id)
            .get()
          const sitesSnapshot = await sitesQuery

          const sitePromises = sitesSnapshot.docs.map(async (site) => {
            const siteData = site.data()
            const camerasQuery = cameraCollection
              .where('site', '==', site.ref)
              .where('users', 'array-contains', doc.id)
              .get()

            const camerasSnapshot = await camerasQuery

            const cameraPromises = camerasSnapshot.docs.map(async (camera) => {
              const cameraData = camera.data()

              const cameraItem = {
                key: camera.id,
                id: camera.id,
                itemId: cameraData.cameraId,
                siteId: siteData.siteId,
                clientId: clientData.clientId,
                cameraId: cameraData.cameraId,
                name: cameraData.name,
                type: 'camera',
                userFirestoreId: docRef.id,
                clientFirestoreId: client.id,
                siteFirestoreId: site.id,
                cameraFirestoreId: camera.id,
                liveStream: cameraData.liveStream,
                siteName: siteData.name,
                clientName: clientData.name,
                userName: docRef.id,
                location: cameraData.location,
                isLiveStreamEnabled: cameraData.isLiveStreamEnabled,
                isSiteHardwareDevice: siteData.isHardwareDevice,
                isAuthenticated:
                  cameraData.isAuthenticated !== null
                    ? cameraData.isAuthenticated
                    : true,
                isEdgeDeviceEnabled:
                  cameraData.isEdgeDeviceEnabled !== null
                    ? cameraData.isEdgeDeviceEnabled
                    : false,
                userDisplayName: userData.display_name,
                edgeLiveStreamLimit: siteData?.edgeLimits?.liveStreamingLimit,
                isEdgeCameraUnarmed: cameraData?.isEdgeCameraUnarmed ?? false
              }

              allCameraItems.push(cameraItem)
            })

            await Promise.all(cameraPromises)
          })

          await Promise.all(sitePromises)
        })

        await Promise.all(clientPromises)
      })

      await Promise.all(fetchPromises)

      commit('setGlobalCamera', allCameraItems)
      commit('setGlobalLiveIsLoading', false)
    } catch (error) {
      console.error('Error binding global cameras:', error)
    }
  },
  updateIsSMTPNotificationEnabledStatus(
    { state }: any,
    payload: {
      currentUserId: string
      isSMTPNotificationEnabled: any
    }
  ): Promise<void> {
    return userProvider.changeIsSMTPNotificationEnabledStatus(
      payload.currentUserId,
      payload.isSMTPNotificationEnabled
    )
  },
  updateSMTPConfig(
    { state }: any,
    payload: {
      currentUserId: string
      smtpNotificationData: any
    }
  ): Promise<void> {
    return userProvider.addSMTPConfig(
      payload.currentUserId,
      payload.smtpNotificationData
    )
  },
  async changeUserPermission(
    { commit }: any,
    payload: {
      userId: string
      clientId?: string
      siteId?: string
      cameraId?: string
    }
  ): Promise<boolean> {
    const changeUserPermission = functions.httpsCallable('changeUserPermission')
    return changeUserPermission(payload)
      .then(() => true)
      .catch(() => false)
  },
  async deleteUser(
    { commit }: any,
    payload: {
      userIds: string
      clientId?: string
      siteId?: string
      cameraId?: string
    }
  ): Promise<any> {
    commit('setLoading', true, { root: true })
    const deleteUsers = functions.httpsCallable('deleteUsers')
    return deleteUsers(payload)
      .then((res) => {
        commit('setLoading', false, { root: true })
        return res.data
      })
      .catch((err) => {
        commit('setLoading', false, { root: true })
        return { status: 'error', message: err.message }
      })
  }
}

export default {
  ...actions
}
