export const getDefaultState = () => {
  return {
    nodes: []
  }
}

const state = getDefaultState()

export default {
  ...state
}
