// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

import Dialog from './components/material/Dialog.vue'

// Plugins
import './plugins'

// Application imports
import App from './App.vue'
import router from '@/router'
import Rx from 'rxjs/Rx'
import VueRx from 'vue-rx'
import { store } from '@/store'
import { firestorePlugin } from 'vuefire'
import VueResource from 'vue-resource'
import VueClipboard from 'vue-clipboard2'
import AlertComponent from '@/components/app/Alert.vue'
import BulkActionBar from '@/components/app/BulkActionBar.vue'
import VueObserveVisibility from 'vue-observe-visibility'
import vuetify from './plugins/vuetify'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LLayerGroup
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import 'firebase/compat/messaging'
import * as Firebase from 'firebase/compat/app'

import { auth } from '@/provider/firebase'
import firebase from 'firebase/compat/app'

let app

require('dotenv').config()

Vue.use(VueObserveVisibility)
Vue.component('hagl-bulk-action-bar', BulkActionBar)
Vue.component('app-alert', AlertComponent)
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-popup', LPopup)
Vue.component('l-tooltip', LTooltip)
Vue.component('l-layer-group', LLayerGroup)
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.use(firestorePlugin)
Vue.use(VueResource)
Vue.component('dialogPopUp', Dialog)
Vue.use(VueRx, Rx)
Vue.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: 'promiseq-web-app',
    serverUrl:
      'https://65c63f5a27a84c0596cfe1c9702d7288.apm.europe-west1.gcp.cloud.es.io/',
    environment: process.env.VUE_APP_PROJECT_ID
  }
})

// if ('serviceWorker' in navigator) {
//   //Register SW to browser
//   navigator.serviceWorker.register('/sw.js').then((registration) => {})
// }

auth.onAuthStateChanged(async (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App)
    }).$mount('#app')
  }

  // Check for immediate logout flag
  if (user) {
    const userId = user.uid
    const forceLogoutRef = firebase.database().ref(`/deleted/${userId}`)

    forceLogoutRef.on('value', (snapshot) => {
      const shouldLogout = snapshot.val()

      if (shouldLogout) {
        // Perform logout
        auth.signOut().then(() => {
          window.location.reload()
        })
      }
    })
  }
})
