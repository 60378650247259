var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},on:{"click:outside":function($event){return _vm.closeDialog()}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[(_vm.rows.length > 1)?_c('v-container',{style:({
          backgroundColor:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.darkPrimaryColor
              : _vm.getColors.lightPrimaryColor
        })},[_c('v-card-title',{staticClass:"justify-center font-weight-bold",staticStyle:{"font-size":"18px"},style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlackColor
          })},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),(!_vm.selectedLink)?_c('v-card-text',[_c('v-container',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkBlackColor
            })},_vm._l((_vm.rows),function(row,index){return _c('v-row',{key:index,staticClass:"text-start dialogItem",staticStyle:{"font-size":"16px","padding":"10px 0"},on:{"click":function($event){return _vm.openLink(row)}}},[_c('v-col',[_c('v-icon',{class:row.class,attrs:{"medium":""}},[_vm._v(_vm._s(row.icon))]),_c('span',[_vm._v(_vm._s(row.text))])],1)],1)}),1)],1):_vm._e(),(_vm.selectedLink)?_c('v-card-text',[_c('iframe',{attrs:{"src":_vm.selectedLink,"frameborder":"0","width":"100%","height":"600"}})]):_vm._e()],1):_vm._e(),(_vm.rows.length === 1)?_c('v-container',{style:({
          backgroundColor:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.darkPrimaryColor
              : _vm.getColors.lightPrimaryColor
        }),attrs:{"backgroundColor:string":"red"}},[_c('v-card-title',{staticClass:"justify-center font-weight-bold",staticStyle:{"font-size":"18px"},style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlackColor,
            padding: '8px'
          })},[_vm._v(" "+_vm._s(_vm.selectedText)+" ")]),_c('v-card-text',{staticStyle:{"padding":"0"}},[_c('iframe',{attrs:{"src":_vm.selectedLink,"frameborder":"0","width":"100%","height":"560"}})])],1):_vm._e()],1)],1),_c('v-icon',{staticClass:"info-icon",attrs:{"color":"white","dense":""},nativeOn:{"click":function($event){return _vm.dialogViewer.apply(null, arguments)}}},[_vm._v(" mdi-help ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }