import { render, staticRenderFns } from "./DragAndDrop.vue?vue&type=template&id=65281daf&scoped=true"
import script from "./DragAndDrop.vue?vue&type=script&lang=ts"
export * from "./DragAndDrop.vue?vue&type=script&lang=ts"
import style0 from "./DragAndDrop.vue?vue&type=style&index=0&id=65281daf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65281daf",
  null
  
)

export default component.exports