export const SIGNALING_SERVER: string = 'wss://webrtc.promiseq.com'
export const ICE_SERVERS: RTCIceServer[] = [
  { urls: 'stun:stun.l.google.com:19302' },
  {
    urls: ['turn:3.76.17.192:5349'],
    username: 'thiesh',
    credential: 'Monitor123'
  }
]
export const WS_HELLO: string = 'HELLO'
export const WS_SESSION_OK: string = 'SESSION_OK'
export const WS_OFFER_REQUEST: string = 'OFFER_REQUEST'
export const DEFAULT_QUOTA: number = 1000
export const DEFAULT_QUOTA_ALERT_PERCENTAGE: number = 70

export const DISARMED_TIME_RANGE = 'Disarmed Time Range'

export enum LiveStreamQualityType {
  LOW = 'low',
  HIGH = 'high'
}

// Close event code list : https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
export enum WebSocketCloseEventCode {
  CLOSED_FOR_RESTART = 4040, // Custom code for auto-restart
  ABNORMAL_CLOSURE = 1006,
  NORMAL_CLOSURE = 1000,
  INTERNAL_ERROR = 1011
}
export const MAX_INITIAL_SIGNALING_SERVER_RESTART_ATTEMPTS = 5
export const MAX_ONGOING_SIGNALING_SERVER_RESTART_ATTEMPTS = 30

export enum ChartDataStatus {
  LOADING = 'Loading',
  READY = 'Ready',
  ERROR = 'Error'
}
