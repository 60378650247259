/**
 * Determines if a new version is available based on the old and new version strings.
 * @param oldVersion - The current version string (e.g., "rt_1.2.3").
 * @param newVersion - The new version string to compare (e.g., "rt_1.2.4").
 * @returns `true` if the new version is greater and valid, `false` if the old version is up-to-date or invalid inputs.
 */
export const isNewVersionAvailable = (
  oldVersion: string | null,
  newVersion: string | null
): boolean => {
  // Validate input versions
  if (!isValidVersion(oldVersion) || !isValidVersion(newVersion)) {
    console.error('Invalid version format.')
    return false
  }

  // Extract version numbers and compare them
  const oldVersionNumbers = extractVersionNumbers(oldVersion)
  const newVersionNumbers = extractVersionNumbers(newVersion)

  for (
    let i = 0;
    i < Math.max(oldVersionNumbers.length, newVersionNumbers.length);
    i++
  ) {
    const oldNum = oldVersionNumbers[i] ?? 0
    const newNum = newVersionNumbers[i] ?? 0

    if (newNum > oldNum) return true
    if (newNum < oldNum) return false
  }

  return false // Versions are equal or no new version available
}

/**
 * Validates if the given version string is in a correct format (e.g., "rt_1.2.3").
 * @param version - The version string to validate.
 * @returns `true` if the version is valid, `false` otherwise.
 */
const isValidVersion = (version: string | null): boolean => {
  return (
    typeof version === 'string' && /^rt[a-z0-9]*-\d+\.\d+\.\d+$/.test(version)
  )
}

/**
 * Extracts the numeric parts of a version string.
 * @param version - The version string to extract numbers from (e.g., "rtabc-1.2.3", "rt123-10.20.30").
 * @returns An array of numbers representing the version (e.g., [1, 2, 3]).
 */
const extractVersionNumbers = (version: string): number[] =>
  version
    .split('-')[1] // Split on the hyphen and take the numeric part
    .split('.') // Split the version numbers by dot
    .map(Number) // Convert each part to a number
