export interface ICameraState {
  cameraConfig: any
  liveCamera: any
  cameraList: any
  referenceImageUrl: string
  hcDueCameras: any[]
  heitelImage: any
  liveStreamError: any
  cameraLocationList: any[]
  cameraUsers: any[]
  cameraDocId: string
  healthCheckType: string
  preDeleteReferenceImage: boolean
  lastIntegrityCheck: Date
}

export const getDefaultState = (): ICameraState => {
  return {
    cameraConfig: null,
    liveCamera: null,
    cameraList: new Set(),
    referenceImageUrl: null,
    hcDueCameras: [],
    heitelImage: null,
    liveStreamError: null,
    cameraLocationList: [],
    cameraUsers: [],
    cameraDocId: '',
    healthCheckType: 'Incident',
    preDeleteReferenceImage: false,
    lastIntegrityCheck: new Date()
  }
}

const state = getDefaultState()

export default {
  ...state
}
