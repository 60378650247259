<template>
  <div class="card-style">
    <div class="form-item">
      <div>
        <p
          class="font-weight-bold text-left"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          IP
        </p>
      </div>
      <div
        class="text-field"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
          border:
            isUserAdmin && isDarkModeToggleEnabled
              ? '1px solid #c4c4c4'
              : 'none',
          padding: '10px',
          borderRadius: '4px',
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
      >
        {{ ipAddress }}
      </div>
    </div>
    <div class="form-item">
      <div>
        <p
          class="font-weight-bold text-left"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          MAC Address
        </p>
      </div>
      <div
        class="text-field"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
          border:
            isUserAdmin && isDarkModeToggleEnabled
              ? '1px solid #c4c4c4'
              : 'none',
          padding: '10px',
          borderRadius: '4px',
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
      >
        {{ macAddress }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component
export default class SiteEdgeDeviceConfiguration extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Prop()
  configuration: any
  @Prop() isWriteEnabled!: boolean

  public ipAddress: string = ''
  public macAddress: string = ''

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }
  public mounted() {
    this.watchConfiguration()
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      this.ipAddress = this.configuration?.ip ?? 'Not available'
      this.macAddress = this.configuration?.macAddress ?? 'Not available'
    }
  }
}
</script>

<style scoped>
.card-style {
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  padding: 19px;
}

.form-item {
  margin-bottom: 20px;
}

.text-field {
  text-align: left;
  font-size: 15px;
  margin-top: -10px;
}

.recording-toggle {
  border-top: 3px solid #e5e5e5;
}
.recording-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.recording-switch {
  float: left;
  padding-left: 15px;
}
</style>
