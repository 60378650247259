import firebase from 'firebase/compat/app'

import 'firebase/compat/storage'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/database'
import 'firebase/compat/auth'
import user from './user'
import todos from './todos'
import config from './config'
import navigation from './navigation'
import camera from './camera'
import statistics from './statistics'
import client from './client'
import site from './site'
import worker from './worker'
import liveHealthCheck from './liveHealthCheck'

const configuration = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
}

const app = firebase.initializeApp(configuration)
const analytics = firebase.analytics()
const storage = app.storage()
const firestore = app.firestore()
const rtdb = app.database()
const auth = app.auth()
firestore.settings({
  merge: true,
  experimentalForceLongPolling: process.env.VUE_APP_EMULATE_FIRESTORE
    ? true
    : false
})

if (process.env.VUE_APP_EMULATE_FIRESTORE) {
  firebase.firestore().useEmulator('localhost', 8082)
}

if (process.env.VUE_APP_EMULATE_AUTH) {
  firebase.auth().useEmulator('http://localhost:9099')
}

if (process.env.VUE_APP_EMULATE_DATABASE) {
  firebase.database().useEmulator('localhost', 9000)
}

if (process.env.VUE_APP_EMULATE_STORAGE) {
  firebase.storage().useEmulator('localhost', 9199)
}

export const statusCollection = firestore.collection('status')

export const todosCollection = firestore.collection('todos')

export const userCollection = firestore.collection('user')

export const configCollection = firestore.collection('config')

export const cameraCollection = firestore.collection('camera')

export const siteCollection = firestore.collection('site')

export const clientCollection = firestore.collection('client')

export const organizationCollection = firestore.collection('organization')

export const cameraHealthCheck = firestore.collection('cameraHealthCheck')

export const workerQueueCollection = firestore.collection('workerQueue')

export const liveHealthCheckCollection = firestore.collection('liveHealthCheck')

export const eagleEyeAccountCollection = firestore.collection('eagleEyeAccount')

export const mailCollection = firestore.collection('mail')

export const Timestamp = firebase.firestore.Timestamp

// For local emulation using local firestore uncomment next 2 lines and comment last line
//export firebase.functions().useEmulator('localhost', 5000)
//export const functions = firebase.app().functions()
const functions = firebase.app().functions('europe-west3')
if (process.env.VUE_APP_EMULATE_FUNCTIONS === 'true') {
  functions.useEmulator('localhost', 5000)
}

const onPremiseApp = firebase.initializeApp(configuration, 'on-premise')

export {
  firestore,
  rtdb,
  auth,
  functions,
  storage,
  config as configProvider,
  camera as cameraProvider,
  statistics as statisticsProvider,
  user as userProvider,
  todos as todosProvider,
  navigation as navigationProvider,
  client as clientProvider,
  site as siteProvider,
  worker as workerProvider,
  liveHealthCheck as liveHealthCheckProvider
}
