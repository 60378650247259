import { render, staticRenderFns } from "./LiveViewGlobal.vue?vue&type=template&id=4c1b71ac&scoped=true"
import script from "./LiveViewGlobal.vue?vue&type=script&lang=ts"
export * from "./LiveViewGlobal.vue?vue&type=script&lang=ts"
import style0 from "./LiveViewGlobal.vue?vue&type=style&index=0&id=4c1b71ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1b71ac",
  null
  
)

export default component.exports