<template>
  <v-row justify="center" class="ma-0">
    <v-tooltip
      right
      :open-on-click="false"
      :open-on-focus="false"
      nudge-right="12"
      color="primary"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-on="on"
          v-bind="attrs"
          class="mr-1"
          @click.stop="dialog = true"
          data-test-id="additional-info-modal-button"
        >
          <v-icon color="secondary" dark> mdi-information </v-icon>
        </v-btn>
      </template>

      <span class="mx-2 white--text">More information</span>
    </v-tooltip>

    <v-dialog
      :content-class="
        isUserAdmin && isDarkModeToggleEnabled
          ? 'additional-info--modal-dark'
          : 'additional-info--modal-light'
      "
      v-model="dialog"
      scrollable
      max-width="650px"
    >
      <v-container>
        <v-row no-gutters class="d-flex flex-column">
          <div class="d-flex flex-row justify-start align-center pa-2 mt-2">
            <v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              mdi-information-outline
            </v-icon>
            <p
              class="ml-2 my-0 text-h6 font-weight-black"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              Incident information
            </p>
          </div>
          <Information
            displayName="Incident Id"
            :item="incident.id"
            :allowCopy="true"
          />
          <Information displayName="Endpoint" :item="incident.alarmtype" />

          <Information
            displayName="Incident definition"
            :item="incident.alarmdefinition"
          />

          <Information
            v-if="incident.inputtype && incident.inputtype.raw"
            displayName="Input Type"
            :item="incident.inputtype"
          />
          <Information
            displayName="Total time"
            :item="calculateDuration(incident.createdat, incident.completedat)"
          />
        </v-row>
        <v-row no-gutters class="d-flex flex-column">
          <div class="d-flex flex-row justify-start align-center pa-2 mt-2">
            <v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              mdi-brain
            </v-icon>
            <p
              class="ml-2 my-0 text-h6 font-weight-black"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              AI
            </p>
          </div>
          <Information
            displayName="Person assessment"
            :item="incident.airesult__personassessment"
          />
          <Information
            displayName="Vehicle movement"
            :item="incident.airesult__vehiclemovement"
          />
          <Information
            displayName="Response time"
            :item="
              calculateDuration(
                incident?.airesult__createdat ?? incident.createdat,
                incident.airesult__updatedat
              )
            "
          />
        </v-row>
        <v-row
          v-if="incident.worker__workerid"
          no-gutters
          class="d-flex flex-column"
        >
          <div class="d-flex flex-row justify-start align-center pa-2 mt-2">
            <v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              mdi-account-hard-hat-outline
            </v-icon>
            <div v-if="incident.missed">
              <span
                class="ml-2 my-0 text-h6 font-weight-black"
                v-if="incident.missed.raw === 'true'"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkPrimaryColor
                }"
                >Missed</span
              >
            </div>
            <p
              class="ml-2 my-0 text-h6 font-weight-black"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              Worker
            </p>
          </div>

          <Information
            displayName="Person assessment"
            :item="incident.worker__personassessment"
          />
          <Information
            displayName="Vehicle Movement"
            :item="incident.worker__vehiclemovement"
          />
          <Information
            displayName="Worker Id"
            :item="incident.worker__workerid"
            :allowCopy="true"
          />
          <Information
            displayName="Worker name"
            :item="incident.worker__workerid"
            :allowCopy="true"
            :isUserDetailsRequired="true"
          />
          <Information
            displayName="Response time"
            :item="
              calculateDuration(
                incident.airesult__updatedat,
                incident.worker__updatedat
              )
            "
          />
          <Information
            v-if="incident.missed && incident.missed.raw === 'true'"
            displayName="Missed "
            :item="incident.missed"
          />
          <Information
            v-if="incident.worker__isdisplayed"
            displayName="Displayed "
            :item="{
              raw:
                incident.worker__isdisplayed &&
                incident.worker__isdisplayed.raw === 'true'
                  ? true
                  : false
            }"
          />
          <Information
            v-if="incident.worker__assignedat"
            displayName="Assigned At"
            :item="formatDateTime(incident.worker__assignedat)"
          />
          <Information
            v-if="incident.worker__displayedat"
            displayName="Displayed At"
            :item="formatDateTime(incident.worker__displayedat)"
          />
        </v-row>
        <v-row no-gutters class="d-flex flex-column">
          <div class="d-flex flex-row justify-start align-center pa-2 mt-2">
            <v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              mdi-account-outline
            </v-icon>
            <p
              class="ml-2 my-0 text-h6 font-weight-black"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              Client
            </p>
          </div>
          <Information
            displayName="Subscription key"
            :item="incident.subscriptionkey"
            :allowCopy="true"
          />
          <Information
            displayName="Camera Id"
            :item="incident.cameraid"
            :allowCopy="true"
          />
          <Information
            displayName="Id"
            :item="incident.user__id"
            :allowCopy="true"
          />
          <Information
            displayName="User"
            :item="incident.user__id"
            :isUserDetailsRequired="true"
          />
        </v-row>
        <v-row no-gutters class="d-flex flex-row justify-end align-center mt-8">
          <Button btnText="Close" @onButtonClick="dialog = false" />
        </v-row>
        <!-- Space Filler -->
        <v-row><div class="mb-4"></div></v-row>
      </v-container>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import Information from '../material/Information.vue'
import Button from '../app/Button.vue'
import moment from 'moment'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

type item = {
  raw: string
}

@Component({
  components: { Information, Button }
})
export default class AdditionalInformationModal extends Vue {
  @Prop() public incident: any
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser)
  public getColors!: any
  public dialog = false

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public calculateDuration(start: item, end: item): item | null {
    if (start && end) {
      let duration = moment.duration(moment(end.raw).diff(moment(start.raw)))
      let seconds = duration.asSeconds()
      return { raw: String(seconds) + ' sec' }
    }
    return null
  }

  public formatDateTime(dateTime: item) {
    if (dateTime && dateTime.raw) {
      return { raw: moment(dateTime.raw).format('DD/MM/YYYY HH:mm:ss') }
    }
    return null
  }
}
</script>

<style scoped>
.font-weight-black {
  color: black;
}
</style>
