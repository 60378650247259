var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600px","text-xs-right":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"add-button elevation-0 text-subtitle-2",staticStyle:{"background-color":"#ffd42a"},attrs:{"large":"","color":" black--text","data-ci-new-user-add-user-button":true,"disabled":!_vm.isWriteEnabled,"data-test-id":"add-new-user-button"}},on),[_c('v-icon',{staticStyle:{"padding-right":"15px"},attrs:{"data-test-id":"add-new-user-label"}},[_vm._v("mdi-account-plus")]),_vm._v(" Add New "+_vm._s(_vm.isUserCustomer ? 'Collaborator' : 'Customer')+" ")],1)]}}]),model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('v-spacer'),_c('v-card',{staticStyle:{"padding":"10px"},style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.darkPrimaryColor
            : _vm.getColors.lightPrimaryColor,
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor
      })},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.addUser.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-toolbar',{staticClass:"secondary elevation-0",attrs:{"dark":""}},[_c('v-toolbar-title',{staticStyle:{"color":"black"},attrs:{"data-test-id":"create-new-user-label"}},[_vm._v(" Add "+_vm._s(_vm.isUserCustomer ? 'Collaborator' : 'Customer')+" ")])],1),(_vm.error)?_c('v-layout',[_c('v-flex',[_c('app-alert',{attrs:{"text":_vm.error.message},on:{"dismissed":_vm.onDismissed}})],1)],1):_vm._e(),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":"","pa-0":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"rules":_vm.emailRule,"label":"Email*","required":"","prepend-icon":"mdi-email","data-ci-new-user-email":true,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"data-test-id":"create-user-email-input"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('Button',{staticClass:"text-subtitle-2 close-btn",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : 'black'
            }),attrs:{"text":"","btnStyle":"outlined","disabled":_vm.isLoading,"data-ci-new-user-close-button":true,"btnText":"Close","data-test-id":"create-user-cancel-button"},on:{"onButtonClick":_vm.cancelNewUser}}),_c('Button',{staticClass:"text-subtitle-2",attrs:{"type":"submit","color":"black--text elevation-0","disabled":!_vm.isValid,"loading":_vm.isLoading,"data-ci-new-user-save-button":true,"btnText":"Add","data-test-id":"create-user-save-button"}})],1)],1)],1),(_vm.showAddUserDialog)?_c('v-card',[_c('AddNewUserDialog',{attrs:{"initialEmail":_vm.userEmail,"handleCreateUser":_vm.handleCreateUser},on:{"close":function($event){_vm.showAddUserDialog = false},"save":function($event){;(_vm.isDialog = false), _vm.createForm.reset(), (_vm.showAddUserDialog = false)}}})],1):_vm._e(),(_vm.showExistUserDialog)?_c('v-card',[_c('AddExistUserDialog',{attrs:{"userDetails":_vm.coloboratorDetails,"coloboratorDetails":_vm.coloboratorDetails,"handleCreateUser":_vm.handleCreateUser},on:{"close":function($event){_vm.showExistUserDialog = false},"save":function($event){;(_vm.isDialog = false),
            _vm.createForm.reset(),
            (_vm.showExistUserDialog = false)}}})],1):_vm._e()],1),_c('v-snackbar',{attrs:{"data-test-id":"add-new-user-snackbar-message"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text",attrs:{"text":"","btnText":"Close","data-test-id":"snackbar-close-button"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }