import { getDefaultState, ISiteState } from './state'
const mutations = {
  setSiteConfig(state: ISiteState, payload: any): void {
    state.siteConfig = payload
  },
  setSiteUsers(state: ISiteState, payload: any[]): void {
    state.siteUsers = payload
  },
  setSiteCameras(state: ISiteState, payload: any): void {
    state.siteCameras = payload
  },
  resetState(state: ISiteState): void {
    Object.assign(state, getDefaultState())
  }
}

export default {
  ...mutations
}
